import { FC, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDeleteUsersMutation, useGetInactiveUserQuery } from 'store';

import StaticCard from 'components/UI/Cards/StaticCard';
import Loader from 'components/UI/Loader';
import Heading, { Tag } from 'components/UI/Heading';
import EmptyState from 'components/UI/EmptyState';
import { ButtonContainer, Column, Item, ItemText, List, Row, SelectItem } from './styles';
import IconButton from 'components/UI/IconButton';
import Icon, { IconType } from 'components/UI/Icon';
import ConfirmActionPopup from 'components/popups/ConfirmActionPopup';
import HeadingWithAdd from 'components/UI/HeadingWithAdd';
import Checkbox from 'components/UI/Checkbox';
import { GiftClaimStatus } from 'models';
import { parseDate } from 'utils/date';
import { useSendGiftRequestToInactiveUsersMutation } from 'store/userService/userGiftService';


const MaintenanceInactiveUserList: FC = () => {

  const [isQuizShown, setIsQuizShown] = useState<boolean>(false);
  const intl = useIntl();
  const { data, isLoading, refetch } = useGetInactiveUserQuery();
  const [deleteUsers] = useDeleteUsersMutation();
  const [giftUser] = useSendGiftRequestToInactiveUsersMutation();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showGiftPopup, setShowGiftPopup] = useState(false);
  const [userToDelete, setUserToDelete] = useState<string | string[] | null>(null);
  const [userToGift, setUserToGift] = useState<string | string[] | null>(null);

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const toggleSelectUser = (userId: string | string[]) => {
    if (Array.isArray(userId)) {
      const newlySelectedUser = [...selectedUsers];
      for (const id of userId) {
        if (newlySelectedUser.includes(id)) {
          newlySelectedUser.splice(newlySelectedUser.indexOf(id), 1);
        } else {
          newlySelectedUser.push(id);
        }
      }
      setSelectedUsers(newlySelectedUser);
    } else {
      if (selectedUsers.includes(userId)) {
        setSelectedUsers(
          selectedUsers.filter((id) => id !== userId)
        );
      } else {
        setSelectedUsers([...selectedUsers, userId]);
      }
    }
  };

  const skandiaUsers = useMemo(() => {
    if (!data) {
      return 0;
    }
    return data.filter(user => user.skandiaUser).length;
  }, [data]);

  const userCount = useMemo(() => {
    if (!data) {
      return 0;
    }
    return data.length;
  }, [data]);

  const users = useMemo(() => {
    // Loading
    if (isLoading) {
      return <Loader color="blue" padding />;
    }

    if (!data || data.length === 0) {
      return (
        <EmptyState iconType={IconType.Users} padding>
          <FormattedMessage
            id='pageMaintenanceInactiveUsersEmptyState'
            defaultMessage='No inactive users found'
            description='Empty state for inactive users'
          />
        </EmptyState>
      );
    }

    return (
      <List>
        {data.map((user, i) => {
          return (
            <Item
              key={`${user.userId}`}
              isLast={data!.length === i + 1}
            >
              <Row>
                <SelectItem
                  key={user.userId}
                  onClick={() => toggleSelectUser(user.userId)}
                >
                  <Checkbox
                    name={user.userId}
                    isChecked={selectedUsers.includes(user.userId)}
                  />
                </SelectItem>
                <Column>
                  <Heading tag={Tag.H4}>
                    {user.email}
                  </Heading>
                  <ItemText>
                    {user.companyAdmin ?
                      intl.formatMessage({
                        id: 'adminUserCompanyAdminLabel',
                        defaultMessage: 'Company Admin',
                        description: 'Admin user details CompanyAdmin label'
                      })
                      :
                      null
                    }
                  </ItemText>
                </Column>
              </Row>
              <ButtonContainer>
                {(user.userGift === null) &&
                  <IconButton onClick={() => {
                    setUserToGift(selectedUsers.length === 0 ? user.userId : selectedUsers);
                    setShowGiftPopup(true);
                  }} padding>
                    {intl.formatMessage({
                      id: 'pageMaintenanceSendGiftButton',
                      defaultMessage: 'send gift',
                      description: 'Button to send gift',
                    })}
                    <Icon color='black' size={24} type={IconType.Gift} />
                  </IconButton>
                }
                {user.userGift !== null && user.userGift.giftClaimStatus === GiftClaimStatus.Requested &&
                  <div>
                    {intl.formatMessage({
                      id: 'pageMaintenanceGiftSent',
                      defaultMessage: 'Gift sent',
                      description: 'Gift sent text',
                    })}
                    {" "}
                    {user.userGift.received && parseDate(user.userGift.received)}
                  </div>
                }
                {user.userGift !== null && user.userGift.giftClaimStatus === GiftClaimStatus.Claimed &&
                  <div>
                    {intl.formatMessage({
                      id: 'pageMaintenanceGiftReceived',
                      defaultMessage: 'Gift received',
                      description: 'Gift received text',
                    })}
                  </div>
                }
                {user && (
                  <IconButton onClick={() => {
                    setUserToDelete(selectedUsers.length === 0 ? user.userId : selectedUsers);
                    setShowConfirmDelete(true);
                  }} padding>
                    <Icon size={24} type={IconType.Remove} />
                  </IconButton>
                )}
              </ButtonContainer>
            </Item>
          );
        })
        }
      </List >
    )
  }, [data, intl, isLoading, selectedUsers]);
  return (
    <>
      <div style={{ padding: 0, margin: 0 }}>
        <HeadingWithAdd
          icon={isQuizShown ? IconType.UpArrowCircle : IconType.DownArrowCircle}
          title={intl.formatMessage({
            id: "pageMaintenanceInactiveUsersTitle",
            defaultMessage: "Inactive users",
            description: "Section title for inactive users"
          }) + "(" + userCount + ")" + (skandiaUsers > 0 ? " Skandia (" + skandiaUsers + ")" : "")}
          addTitle={intl.formatMessage({
            id: 'competitionTopListShowAll',
            defaultMessage: 'Show all',
            description: 'Button to show quiz result',
          })}
          onAdd={() => setIsQuizShown(prev => !prev)}
        />
        {isQuizShown &&
          <StaticCard padding>
            {users}
          </StaticCard>
        }
      </div>
      <ConfirmActionPopup
        isOpen={showGiftPopup}
        onConfirm={async () => {
          if (userToGift) {
            await giftUser(Array.isArray(userToGift) ? userToGift : [userToGift]);
            setShowGiftPopup(false);
            refetch();
          }
        }}
        onAbort={() => setShowGiftPopup(false)}
      >
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="sendGiftUserModalTitle"
            defaultMessage="Gift user"
            description="Heading text in the send gift popup"
          />
        </Heading>
        <FormattedMessage
          id="sendGiftUserModalDescription"
          defaultMessage="Are you sure you want to send a gift to the user?"
          description="Body text in the send gift popup"
        />
      </ConfirmActionPopup>

      <ConfirmActionPopup
        isOpen={showConfirmDelete}
        onConfirm={async () => {
          if (userToDelete) {
            await deleteUsers(Array.isArray(userToDelete) ? userToDelete : [userToDelete]);
            setShowConfirmDelete(false);
            refetch();
          }
        }}
        onAbort={() => setShowConfirmDelete(false)}
      >
          <>
            <Heading tag={Tag.H4}>
              <FormattedMessage
                id="deleteUserModalTitle"
                defaultMessage="Delete user"
                description="Heading text in the delete user popup"
              />
            </Heading>
            <FormattedMessage
              id="deleteUserModalDescription"
              defaultMessage="Are you sure you want to delete your user account? (This action is irreversable and can not be reverted afterwards)"
              description="Body text in the delete user popup"
            />
          </>
        </ConfirmActionPopup>
    </>
  );
};

export default MaintenanceInactiveUserList;
