import {
  getEndpointURL,
  WellrEndpointDefinition,
  WellrEndpointPath,
} from 'utils/api';

/** Mark measure step */
const markMeasureStepPath: WellrEndpointPath =
  '/api/measure/me/step/{measureSlug}/{measureStepId}';
const markMeasureStepMethod = 'post';
type MarkMeasureStep = WellrEndpointDefinition<
  typeof markMeasureStepPath,
  typeof markMeasureStepMethod
>;

export type MarkMeasureStepType = {
  result: MarkMeasureStep['responseBody'];
  queryArgs: MarkMeasureStep['request']['params']['path'] &
  Required<MarkMeasureStep['request']['params']['query']>;
};


/** Mark measure SUB step */
const markMeasureSubStepPath: WellrEndpointPath =
  '/api/measure/me/step/{measureSlug}/{measureStepId}/reference/{referenceId}';
const markMeasureSubStepMethod = 'post';
type MarkMeasureSubStep = WellrEndpointDefinition<
  typeof markMeasureSubStepPath,
  typeof markMeasureSubStepMethod
>;

export type MarkMeasureSubStepType = {
  result: MarkMeasureSubStep['responseBody'];
  queryArgs: MarkMeasureSubStep['request']['params']['path'] &
  Required<MarkMeasureSubStep['request']['params']['query']>;
};


export const endpoints = {
  markMeasureStep: {
    path: (measureSlug: string, measureStepId: string) =>
      getEndpointURL(markMeasureStepPath, { measureSlug, measureStepId }),
    method: markMeasureStepMethod.toUpperCase(),
  },
  markMeasureSubStep: {
    path: (measureSlug: string, measureStepId: string, referenceId: string) =>
      getEndpointURL(markMeasureSubStepPath, { measureSlug, measureStepId, referenceId }),
    method: markMeasureSubStepMethod.toUpperCase(),
  },
};