import Lottie from 'lottie-react';
import styled from 'styled-components';
import { breakpoints } from 'styles';

export const HeroSliderWrap = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 680px;

  @media (max-width: ${breakpoints.l}px) {
    overflow-x: hidden;
    max-width: none;
    margin: 0;
  }
  z-index: 1;
`;

export const SnowingLottie = styled(Lottie)`
  position: absolute;
  width: 110%;
  pointer-events: none;
  top: -200px;
  z-index: 2;

  @media (min-width: ${breakpoints.l}px) {
    top: -1365px;
  }
`;