import { components } from 'generated/api';
import {
  AgeTypesInQuizStatistics,
  CompanyQuizAverageResults,
  ContentBody,
  ContentText,
  Contest,
  ContestQuery,
  GendersInQuizStatistics,
  OrganizationEntities,
  QuizAssessmentLevel,
  QuizReminderCommand,
  RolesInQuizStatistics,
  UserMaintenanceLightDto,
} from 'models';
import {
  DeepRequired,
  ExtractApiRequestBody,
  ExtractApiResponseType,
  getEndpointURL,
  WellrEndpointDefinition,
} from 'utils/api';
import { dateStringToISOString, getTimezoneOffset } from 'utils/date';
import { baseApi } from '../baseApi';
import {
  endpoints,
  GetCompaniesInUmbrellaCompanyType,
  GetAvailablePulseQuizesType,
  GetCompanyCommunicationType,
  GetContestActivityStatsType,
  GetContestHealthChoiceStatsType,
  GetFilteredHealthquizResultsType,
  GetHealthQuizInfoContentType,
  GetHealthQuizInfoType,
  GetQuizResultBaselineType,
  GetStatisticsBaselineType,
  GetUmbrellaCompaniesType,
  GetUmbrellaCompanyCommunicationType,
  GetUsageStatisticsType,
  ListUmbrellaCompaniesType,
  StartPulseQuizType,
  UpdateCompanyCommunicationType,
  UpdateCompanyType,
  UpdateContestBonusStepsType,
  UpdateUmbrellaCompanyCommunicationType,
  GetBaselineHealthScoreType,
  ClearCacheType,
} from './endpointDefinitions';

type AdminOverviewResponse = DeepRequired<
  ExtractApiResponseType<'/api/company/me/overview', 'get'>
>;
type CompanyStructureResponse = DeepRequired<
  ExtractApiResponseType<'/api/company/me/structure', 'get'>
>;
type CompanyDepartmentResponse = DeepRequired<
  ExtractApiResponseType<
    '/api/company/me/department/info/{departmentId}',
    'get'
  >
>;
type createMembershipResponse = DeepRequired<
  ExtractApiResponseType<'/api/membership', 'post'>
>;
type createMembershipValues = ExtractApiRequestBody<
  '/api/membership',
  'post'
> & { userId: string; companyId: string | undefined; moduleAccessRightCollection: string; companyAdmin: boolean; departmentId: string; };

type CreateDepartmentResponse = DeepRequired<
  ExtractApiResponseType<'/api/company/me/department', 'post'>
>;
type CreateDepartmentValues = ExtractApiRequestBody<
  '/api/company/me/department',
  'post'
> & { companyId?: string };
type UpdateDepartmentResponse = DeepRequired<
  ExtractApiResponseType<'/api/company/me/department/{departmentId}', 'patch'>
>;
type UpdateDepartmentValues = ExtractApiRequestBody<
  '/api/company/me/department/{departmentId}',
  'patch'
> & { departmentId: string; companyId?: string };

type DeleteDepartmentResponse = DeepRequired<
  ExtractApiResponseType<'/api/company/me/department/{departmentId}', 'delete'>
>;
type DeleteDepartmentValues = { departmentId: string; companyId?: string };

type UpdateMemberDepartmentResponse = DeepRequired<
  ExtractApiResponseType<'/api/membership/company-admin', 'patch'>
>;
type UpdateMemberDepartmentValues = ExtractApiRequestBody<
  '/api/membership/company-admin',
  'patch'
> & { companyId?: string };

type CompanyUserResponse = DeepRequired<
  ExtractApiResponseType<'/api/company/me/user', 'get'>
>;

type ContestInspect = {
  contestOut: Omit<Contest, 'me'>;
  contestInspectDetails: DeepRequired<
    components['schemas']['ContestInspectDetailsDto']
  >;
};

export type OrganizationEntityResponse = Array<{
  organizationEntityId?: string;
  timestamp?: string;
  organizationEntityType?: OrganizationEntities;
  organizationEntityDescription?: string | null;
  role?: RolesInQuizStatistics;
  gender?: GendersInQuizStatistics;
  age?: AgeTypesInQuizStatistics;
  averageHealthScore?: number | null;
  usersInHealthCategories: Array<{
    level: QuizAssessmentLevel;
    assessments: number;
    percentage: number;
    resultTitle?: ContentText | null;
    color?: { hex: string };
  }>;
  quizesInHealthCategories: Array<{
    level: QuizAssessmentLevel;
    assessments: number;
    percentage: number;
    resultTitle?: ContentText | null;
    color?: { hex: string };
  }>;
}>;

type CompaniesResponse = DeepRequired<
  ExtractApiResponseType<'/api/company/company/all', 'get'>
>;

const addContestUserPath = '/api/contest/user/add';
const addContestUserMethod = 'post';
type AddUserEndpoint = WellrEndpointDefinition<
  typeof addContestUserPath,
  typeof addContestUserMethod
>;

const getAddableContestUsersPath =
  '/api/contest/user/{contestId}/addable-users';
const getAddableContestUsersMethod = 'get';
type GetAddableContestUsersEndpoint = WellrEndpointDefinition<
  typeof getAddableContestUsersPath,
  typeof getAddableContestUsersMethod
>;

const adminApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    resendInvitation: builder.mutation<void, { companyId: string }>({
      query: ({ companyId }) => ({
        url: `/company/resend-invite?companyId=${companyId}`,
        method: 'POST',
      }),
    }),
    getAdminOverview: builder.query<
      AdminOverviewResponse,
      { companyId?: string }
    >({
      query: ({ companyId }) => ({
        url: 'company/me/overview',
        params: { companyId },
      }),
      providesTags: ['Company'],
    }),
    getCompanyDepartment: builder.query<
      CompanyDepartmentResponse,
      { departmentId: string; companyId?: string }
    >({
      query: ({ departmentId, companyId }) => ({
        url: `/company/me/department/info/${departmentId}`,
        params: { companyId },
      }),
      providesTags: ['Company'],
    }),
    createHeadOffice: builder.mutation<
      void,
      { description: string; companyId?: string }
    >({
      query: ({ companyId, description }) => ({
        url: '/company/me/head-office',
        method: 'POST',
        params: { companyId },
        body: { description },
      }),
      invalidatesTags: ['Company'],
    }),
    createMembership: builder.mutation<
      createMembershipResponse,
      createMembershipValues
    >({
      query: ({ companyAdmin, companyId, moduleAccessRightCollection, userId, departmentId }) => ({
        url: '/membership',
        method: 'POST',
        body: { companyAdmin, companyId, moduleAccessRightCollection, userId, departmentId },
      }),
      invalidatesTags: ['Company'],
    }),
    changeMembership: builder.mutation<
      void,
      {
        membershipId: string;
        userId: string;
      }
    >({
      query: ({ membershipId, userId }) => ({
        url: `/change-membership`,
        method: 'POST',
        body: { membershipId, userId },
      }),
      invalidatesTags: ['Company'],
    }),
    updateHeadOffice: builder.mutation<
      void,
      { headOfficeId: string; description: string; companyId?: string }
    >({
      query: ({ headOfficeId, companyId, description }) => ({
        url: `/company/me/head-office/${headOfficeId}`,
        method: 'PATCH',
        params: { companyId },
        body: { description },
      }),
      invalidatesTags: ['Company'],
    }),
    deleteHeadOffice: builder.mutation<
      void,
      { headOfficeId: string; companyId?: string }
    >({
      query: ({ headOfficeId, companyId }) => ({
        url: `/company/me/head-office/${headOfficeId}`,
        method: 'DELETE',
        params: { companyId },
      }),
      invalidatesTags: ['Company'],
    }),
    createOffice: builder.mutation<
      void,
      { description: string; headOfficeId: string | null; companyId?: string }
    >({
      query: ({ companyId, description, headOfficeId }) => ({
        url: '/company/me/office',
        method: 'POST',
        params: { companyId },
        body: { description, headOfficeId },
      }),
      invalidatesTags: ['Company'],
    }),
    updateOffice: builder.mutation<
      void,
      {
        officeId: string;
        description: string;
        headOfficeId: string | null;
        companyId?: string;
      }
    >({
      query: ({ officeId, headOfficeId, companyId, description }) => ({
        url: `/company/me/office/${officeId}`,
        method: 'PATCH',
        params: { companyId },
        body: { description, headOfficeId },
      }),
      invalidatesTags: ['Company'],
    }),
    deleteOffice: builder.mutation<
      void,
      { officeId: string; companyId?: string }
    >({
      query: ({ officeId, companyId }) => ({
        url: `/company/me/office/${officeId}`,
        method: 'DELETE',
        params: { companyId },
      }),
      invalidatesTags: ['Company'],
    }),
    createDepartment: builder.mutation<
      CreateDepartmentResponse,
      CreateDepartmentValues
    >({
      query: ({ companyId, description, officeId }) => ({
        url: '/company/me/department',
        method: 'POST',
        params: { companyId },
        body: { description, officeId },
      }),
      invalidatesTags: ['Company'],
    }),
    updateDepartment: builder.mutation<
      UpdateDepartmentResponse,
      UpdateDepartmentValues
    >({
      query: ({ departmentId, description, officeId, companyId }) => ({
        url: `/company/me/department/${departmentId}`,
        params: { companyId },
        method: 'PATCH',
        body: { description, officeId },
      }),
      invalidatesTags: ['Company'],
    }),
    deleteDepartment: builder.mutation<
      DeleteDepartmentResponse,
      DeleteDepartmentValues
    >({
      query: ({ departmentId, companyId }) => ({
        url: `/company/me/department/${departmentId}`,
        params: { companyId },
        method: 'DELETE',
      }),
      invalidatesTags: ['Company'],
    }),
    updateMemberDepartment: builder.mutation<
      UpdateMemberDepartmentResponse,
      UpdateMemberDepartmentValues
    >({
      query: ({ departmentId, membershipId, companyId }) => ({
        url: `/membership/company-admin`,
        method: 'PATCH',
        body: { departmentId, membershipId },
        params: { companyId },
      }),
      invalidatesTags: (data) => [{ type: 'Membership', id: data?.id ?? -1 }],
    }),
    deleteMember: builder.mutation<
      void,
      {
        userId: string;
        companyId?: string | null;
      }
    >({
      query: ({ userId, companyId }) => ({
        url: `/membership`,
        params: { userId, companyId },
        method: 'DELETE',
      }),
      invalidatesTags: (data) => [
        { type: 'Membership', id: data ?? -1 },
        { type: 'Company' },
      ],
    }),
    anonymizeUser: builder.mutation<
      void,
      {
        userEmail: string;
      }
    >({
      query: ({ userEmail }) => ({
        url: `/user/anonymize/${userEmail}`,
        method: 'DELETE',
      }),
      invalidatesTags: (data) => [
        { type: 'Membership', id: data ?? -1 },
        { type: 'Company' },
      ],
    }),
    getContestQuizResults: builder.query<CompanyQuizAverageResults[], ContestQuery>({
      query: ({ contestId, language }) => ({
        url: `/company/contest/${contestId}/average-quiz-result?language=${language}`,
        method: 'GET',
      })
    }),
    getCompanyStructure: builder.query<
      CompanyStructureResponse,
      { companyId?: string }
    >({
      query: ({ companyId }) => ({
        url: '/company/me/structure',
        params: { companyId },
      }),
      providesTags: ['Company'],
    }),
    getCompanyUser: builder.query<
      CompanyUserResponse,
      { userId: string; companyId?: string }
    >({
      query: ({ userId, companyId }) => ({
        url: `/company/me/user`,
        params: { userId, companyId },
      }),
      providesTags: (data) => [
        { type: 'Membership', id: data?.membership?.id ?? -1 },
      ],
    }),
    inspectContest: builder.query<ContestInspect, { contestId: string }>({
      query: ({ contestId }) => `/contest/inspect/${contestId}`,
      providesTags: ['Contest'],
    }),
    uploadPredefinedTeams: builder.mutation<
      void,
      { contestId: string; file: File }
    >({
      query: ({ contestId, file }) => {
        const formData = new FormData();
        formData.append('predefinedTeams', file);
        return {
          url: `/contest/predefined-teams?contestId=${contestId}`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['Contest'],
    }),
    getCompanyCompetitions: builder.query<
      DeepRequired<
        ExtractApiResponseType<'/api/company/me/competitions', 'get'>
      >,
      { companyId?: string }
    >({
      query: ({ companyId }) => ({
        url: '/company/me/competitions',
        params: { companyId },
      }),
      providesTags: ['Contest'],
    }),
    getCompanyCompetition: builder.query<
      DeepRequired<
        ExtractApiResponseType<'/api/contest/competition/slug/{slug}', 'get'>
      > & { description: ContentBody; companyAdminDescription: ContentBody },
      { language?: string; slug: string }
    >({
      query: ({ slug, language }) =>
        `/contest/competition/slug/${slug}?language=${language}`,
    }),
    getCompanyCompetitionInstance: builder.query<
      DeepRequired<
        ExtractApiResponseType<'/api/contest/company/{contestId}', 'get'>
      > & { description: ContentBody; companyAdminDescription: ContentBody },
      { language?: string; contestId: string }
    >({
      query: ({ contestId, language }) => `/contest/company/${contestId}`,
    }),
    startCompanyCompetition: builder.mutation<
      DeepRequired<
        ExtractApiResponseType<'/api/contest/start/company-admin', 'post'>
      >,
      ExtractApiRequestBody<'/api/contest/start/company-admin', 'post'> & {
        companyId?: string;
      }
    >({
      query: ({ companyId, start, stop, ...body }) => ({
        url: '/contest/start/company-admin',
        params: { companyParameterId: companyId },
        method: 'POST',
        body: {
          ...body,
          minutesOffsetFromUtc: getTimezoneOffset(),
          start: dateStringToISOString(start),
          stop: stop != null ? dateStringToISOString(stop) : undefined,
        },
      }),
      invalidatesTags: ['Contest'],
    }),
    getAvailableCompanyCompetitions: builder.query<
      DeepRequired<
        ExtractApiResponseType<'/api/contest/available-competitions', 'get'>
      >,
      { language?: string }
    >({
      query: ({ language }) =>
        `/contest/available-competitions?language=${language}`,
      providesTags: ['Contest'],
    }),
    getAddableContestUsers: builder.query<
      GetAddableContestUsersEndpoint['responseBody'],
      GetAddableContestUsersEndpoint['request']['params']['path']['contestId']
    >({
      query: (contestId) => ({
        // url: getAddableContestUsersPath.replace('{contestId}', contestId),
        url: getEndpointURL(getAddableContestUsersPath, { contestId }),
        method: getAddableContestUsersMethod.toUpperCase(),
      }),
      providesTags: ['AddableContestUsers'],
    }),
    addContestUser: builder.mutation<
      AddUserEndpoint['responseBody'],
      AddUserEndpoint['request']['body']
    >({
      query: (body) => ({
        url: getEndpointURL(addContestUserPath),
        method: addContestUserMethod.toUpperCase(),
        body: body,
      }),
      invalidatesTags: ['Contest', 'AddableContestUsers'],
    }),
    updateContestUser: builder.mutation<
      void,
      {
        contestUserId: string;
        contestTeamId?: string;
        forceAcceptOrUnaccept?: boolean;
        alias: string | null;
      }
    >({
      query: ({
        contestUserId,
        contestTeamId,
        forceAcceptOrUnaccept,
        alias,
      }) => ({
        url: `/contest/user/admin/update`,
        method: 'PATCH',
        body: { contestUserId, contestTeamId, forceAcceptOrUnaccept, alias },
      }),
      invalidatesTags: ['Contest'],
    }),
    updateContestDate: builder.mutation<
      void,
      {
        id: string;
        start: string;
        stop: string;
      }
    >({
      query: ({
        id,
        start,
        stop,
      }) => ({
        url: `/contest/update`,
        method: 'PATCH',
        body: { id, start, stop },
      }),
      invalidatesTags: ['Contest'],
    }),
    updateContestTeam: builder.mutation<
      void,
      {
        contestTeamId: string;
        name: string;
      }
    >({
      query: ({ contestTeamId, name }) => ({
        url: `/contest/team/admin/update`,
        method: 'PATCH',
        body: { contestTeamId, name },
      }),
      invalidatesTags: ['Contest'],
    }),
    deleteContestTeam: builder.mutation<
      void,
      {
        contestTeamId: string;
      }
    >({
      query: ({ contestTeamId }) => ({
        url: `/contest/team/admin/delete`,
        method: 'DELETE',
        body: { contestTeamId },
      }),
      invalidatesTags: ['Contest'],
    }),
    getOrganizationEntityStatistics: builder.query<
      OrganizationEntityResponse,
      { date?: string; companyId?: string }
    >({
      query: ({ date, companyId }) => ({
        url: `/company/me/organization-entity-statistics/from-date`,
        params: { date, companyId },
      }),
    }),
    getCompanies: builder.query<CompaniesResponse, void>({
      query: () => `/company/company/all`,
      providesTags: ['Company'],
    }),
    toggleCompanyAdmin: builder.mutation<
      void,
      {
        membershipId: string;
        companyAdmin: boolean;
      }
    >({
      query: ({ membershipId, companyAdmin }) => ({
        url: `access/company-admin/${membershipId}`,
        params: { companyAdmin },
        method: 'PATCH',
      }),
      invalidatesTags: ['Membership'],
    }),
    addUser: builder.mutation<
      void | {
        registeredEmailAddresses: string[];
        notValidEmailAddresses: string[];
        alreadyRegisteredEmailAddresses: string[];
      },
      {
        emails: string[];
        departmentId?: string;
        language: string;
        companyId?: string;
      }
    >({
      query: ({ emails, departmentId, language, companyId }) =>
        emails.length === 1
          ? {
            url: `/user`,
            method: 'POST',
            body: { email: emails[0], departmentId, language },
            params: { companyId },
          }
          : {
            url: `/user/multiple`,
            method: 'POST',
            body: { emails, departmentId, language },
            params: { companyId },
          },
    }),
    sendContestInvitations: builder.mutation<
      void,
      {
        contestId: string;
      }
    >({
      query: ({ contestId }) => ({
        url: `contest/${contestId}/invite`,
        method: 'GET',
      }),
    }),
    sendContestReminderInvitationsn: builder.mutation<
      void,
      {
        contestId: string;
      }
    >({
      query: ({ contestId }) => ({
        url: `contest/${contestId}/reminder-invite`,
        method: 'POST',
      }),
    }),
    setFeedback: builder.mutation<void, { contestId: string; }>({
      query: ({ contestId }) => ({
        url: `contest/feedback/${contestId}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Contest'],
    }),
    updateRegistrationCodeExpiration: builder.mutation<
      void,
      {
        id: string;
        expires: string;
      }
    >({
      query: ({ id, expires }) => ({
        url: `user/registration-code`,
        params: { id, expires: dateStringToISOString(expires) },
        method: 'PATCH',
      }),
      invalidatesTags: ['UserRegistrationCode'],
    }),
    deleteRegistrationCode: builder.mutation<
      void,
      {
        id: string;
      }
    >({
      query: ({ id }) => ({
        url: `user/registration-code`,
        params: { id },
        method: 'DELETE',
      }),
      invalidatesTags: ['UserRegistrationCode'],
    }),
    getContestHealthchoiceStats: builder.query<
      GetContestHealthChoiceStatsType['result'],
      GetContestHealthChoiceStatsType['queryArgs']
    >({
      query: ({ contestId }) => ({
        url: endpoints.getContestHealthChoiceStats.path(contestId),
        method: endpoints.getContestHealthChoiceStats.method,
      }),
    }),
    getContestActivityStats: builder.query<
      GetContestActivityStatsType['result'],
      GetContestActivityStatsType['queryArgs']
    >({
      query: ({ contestId, language }) => ({
        url: endpoints.getContestActivityStats.path(contestId),
        params: { language },
        method: endpoints.getContestActivityStats.method,
      }),
    }),
    createCompanyFromTemplate: builder.mutation<void, { file: File, updateExistingCompany: boolean }>({
      query: ({ file, updateExistingCompany }) => {
        const formData = new FormData();
        formData.append('companyStructureExcelFile', file);
        return {
          url: `/company/register-from-template` + (updateExistingCompany ? '?updateExistingCompany=true' : ''),
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['Company'],
    }),
    updateCompany: builder.mutation<
      UpdateCompanyType['result'],
      UpdateCompanyType['queryArgs']
    >({
      query: ({ companyId, ...rest }) => ({
        url: endpoints.updateCompany.path(companyId),
        method: endpoints.updateCompany.method,
        body: rest,
      }),
      invalidatesTags: ['Company'],
    }),
    deleteCompany: builder.mutation<void, { companyId: string }>({
      query: ({ companyId }) => ({
        url: `/company/company/${companyId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Company'],
    }),
    recalculateContest: builder.mutation<void, { contestId: string }>({
      query: ({ contestId }) => ({
        url: `/contest/recalculate/${contestId}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Contest'],
    }),
    getCompaniesInUmbrellaCompany: builder.query<
      GetCompaniesInUmbrellaCompanyType['result'],
      GetCompaniesInUmbrellaCompanyType['queryArgs']
    >({
      query: ({ id } = { id: undefined }) => ({
        url: endpoints.umbrellaCompanies.get.path(id as string),
        method: endpoints.umbrellaCompanies.get.method,
      }),
    }),
    useGetUmbrellaCompanies: builder.query<
      ListUmbrellaCompaniesType['result'],
      ListUmbrellaCompaniesType['queryArgs']
    >({
      query: () => ({
        url: endpoints.umbrellaCompany.list.path,
        method: endpoints.umbrellaCompany.list.method,
      }),
    }),
    useGetUmbrellaCompany: builder.query<
      GetUmbrellaCompaniesType['result'],
      GetUmbrellaCompaniesType['queryArgs']
    >({
      query: ({ umbrellaCompanyId }) => {
        if (umbrellaCompanyId === '') {
          return {
            url: '',
            method: 'GET',
            params: {},
          };
        }
        return {
          url: endpoints.umbrellaCompany.get.path,
          method: endpoints.umbrellaCompany.get.method,
          params: { umbrellaCompanyId },
        };
      },
    }),
    getUmbrellaCompanyCommunicationSlugs: builder.query<
      GetUmbrellaCompanyCommunicationType['result'],
      GetUmbrellaCompanyCommunicationType['queryArgs']
    >({
      query: ({ umbrellaCompanyId }) => ({
        url: endpoints.umbrellaCompany.getCommunication.path(umbrellaCompanyId),
        method: endpoints.umbrellaCompany.getCommunication.method,
      }),
      providesTags: (_result, _error, { umbrellaCompanyId }) => [
        { type: 'UmbrellaCompanyCommunication', id: umbrellaCompanyId },
      ],
    }),
    addUmbrellaCompanyCommunicationSlug: builder.mutation<
      UpdateUmbrellaCompanyCommunicationType['result'],
      UpdateUmbrellaCompanyCommunicationType['queryArgs']
    >({
      query: ({ umbrellaCompanyId, slug }) => ({
        url: endpoints.umbrellaCompany.updateCommunication.path(
          umbrellaCompanyId
        ),
        method: endpoints.umbrellaCompany.updateCommunication.method,
        params: { slug },
      }),
      invalidatesTags: (_result, _error, { umbrellaCompanyId }) => [
        { type: 'UmbrellaCompanyCommunication', id: umbrellaCompanyId },
      ],
    }),
    deleteUmbrellaCompanyCommunicationSlug: builder.mutation<
      UpdateUmbrellaCompanyCommunicationType['result'],
      UpdateUmbrellaCompanyCommunicationType['queryArgs']
    >({
      query: ({ umbrellaCompanyId, slug }) => ({
        url: endpoints.umbrellaCompany.deleteCommunication.path(
          umbrellaCompanyId
        ),
        method: endpoints.umbrellaCompany.deleteCommunication.method,
        params: { slug },
      }),
      invalidatesTags: (_result, _error, { umbrellaCompanyId }) => [
        { type: 'UmbrellaCompanyCommunication', id: umbrellaCompanyId },
      ],
    }),
    getCompanyCommunicationSlugs: builder.query<
      GetCompanyCommunicationType['result'],
      GetCompanyCommunicationType['queryArgs']
    >({
      query: ({ companyId }) => ({
        url: endpoints.company.getCommunication.path(companyId),
        method: endpoints.company.getCommunication.method,
      }),
      providesTags: (_result, _error, { companyId }) => [
        { type: 'CompanyCommunication', id: companyId },
      ],
    }),
    addCompanyCommunicationSlug: builder.mutation<
      UpdateCompanyCommunicationType['result'],
      UpdateCompanyCommunicationType['queryArgs']
    >({
      query: ({ companyId, slug }) => ({
        url: endpoints.company.updateCommunication.path(companyId),
        method: endpoints.company.updateCommunication.method,
        params: { slug },
      }),
      invalidatesTags: (_result, _error, { companyId }) => [
        { type: 'CompanyCommunication', id: companyId },
      ],
    }),
    deleteCompanyCommunicationSlug: builder.mutation<
      UpdateCompanyCommunicationType['result'],
      UpdateCompanyCommunicationType['queryArgs']
    >({
      query: ({ companyId, slug }) => ({
        url: endpoints.company.deleteCommunication.path(companyId),
        method: endpoints.company.deleteCommunication.method,
        params: { slug },
      }),
      invalidatesTags: (_result, _error, { companyId }) => [
        { type: 'CompanyCommunication', id: companyId },
      ],
    }),
    sendHealthQuizReminder: builder.mutation<void, QuizReminderCommand>({
      query: (body) => ({
        url: '/company/me/health-quiz-reminder',
        method: 'POST',
        body,
      }),
    }),
    sendPulseQuizReminder: builder.mutation<void, { companyId: string }>({
      query: ({ companyId }) => ({
        url: getEndpointURL(`/api/pulsequiz/reminder/{companyId}`, {
          companyId: companyId,
        }),
        method: 'POST',
      }),
    }),
    getFilteredHealthquizResults: builder.query<
      GetFilteredHealthquizResultsType['result'],
      GetFilteredHealthquizResultsType['queryArgs']
    >({
      query: (params) => ({
        url: endpoints.getFilteredHealthquizResults.path,
        method: endpoints.getFilteredHealthquizResults.method,
        params,
      }),
    }),
    getStartablePulseQuizes: builder.query<
      GetAvailablePulseQuizesType['result'],
      GetAvailablePulseQuizesType['queryArgs']
    >({
      query: () => ({
        url: endpoints.pulseQuiz.getAvailable.path,
        method: endpoints.pulseQuiz.getAvailable.method,
      }),
    }),
    startPulseQuiz: builder.mutation<
      StartPulseQuizType['result'],
      StartPulseQuizType['queryArgs']
    >({
      query: (body) => ({
        url: endpoints.pulseQuiz.start.path,
        method: endpoints.pulseQuiz.start.method,
        body,
      }),
    }),
    getUsageStats: builder.query<
      GetUsageStatisticsType['result'],
      GetUsageStatisticsType['queryArgs']
    >({
      query: (params) => ({
        url: endpoints.getUsageStats.path,
        method: endpoints.getUsageStats.method,
        params,
      }),
    }),
    updateContestBonusSteps: builder.mutation<
      UpdateContestBonusStepsType['result'],
      UpdateContestBonusStepsType['queryArgs']
    >({
      query: ({ contestId, ...body }) => ({
        url: endpoints.contest.updateBonusSteps.path(contestId),
        method: endpoints.contest.updateBonusSteps.method,
        body,
      }),
      invalidatesTags: ['Contest'],
    }),
    getBaselineStatistics: builder.query<
      GetStatisticsBaselineType['result'],
      GetStatisticsBaselineType['queryArgs']
    >({
      query: () => ({
        url: endpoints.getStatisticsBaseline.path,
        method: endpoints.getStatisticsBaseline.method,
      }),
    }),
    getBaselineHealthScore: builder.query<
      GetBaselineHealthScoreType['result'],
      GetBaselineHealthScoreType['queryArgs']
    >({
      query: () => ({
        url: endpoints.getBaselineHealthScore.path,
        method: endpoints.getBaselineHealthScore.method,
      }),
    }),
    getBaselineQuizResult: builder.query<
      GetQuizResultBaselineType['result'],
      GetQuizResultBaselineType['queryArgs']
    >({
      query: (params) => ({
        url: endpoints.getQuizResultBaseline.path,
        method: endpoints.getQuizResultBaseline.method,
        params,
      }),
    }),
    getHealthQuizInfo: builder.query<
      GetHealthQuizInfoType['result'],
      GetHealthQuizInfoType['queryArgs']
    >({
      query: ({ companyId }) => ({
        url: endpoints.getHealthQuizInfo.path(companyId),
        method: endpoints.getHealthQuizInfo.method,
      }),
    }),
    getHealthQuizInfoContent: builder.query<
      GetHealthQuizInfoContentType['result'],
      GetHealthQuizInfoContentType['queryArgs']
    >({
      query: (params) => ({
        url: endpoints.getHealthQuizInfoContent.path,
        method: endpoints.getHealthQuizInfoContent.method,
        params,
      }),
    }),
    useClearCache: builder.mutation<
      ClearCacheType['result'],
      ClearCacheType['queryArgs']>({
        query: () => ({
          url: endpoints.useClearCache.path,
          method: endpoints.useClearCache.method,
        }),
      }),
    getInactiveUser: builder.query<
      UserMaintenanceLightDto[],
      void>({
        query: () => ({
          url: `/user/inactive`,
          method: 'GET',
        }),
      }),
    deleteUsers: builder.mutation<void, string[]>({
      query: (userIds) => ({
        url: `/user`,
        method: 'DELETE',
        body: userIds,
      }),
      invalidatesTags: ['Company'],
    }),

  }),
});

export const {
  useGetAdminOverviewQuery,
  useGetCompanyStructureQuery,
  useGetCompanyDepartmentQuery,
  useCreateDepartmentMutation,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
  useGetCompanyUserQuery,
  useDeleteMemberMutation,
  useUpdateMemberDepartmentMutation,
  useInspectContestQuery,
  useUploadPredefinedTeamsMutation,
  useGetCompanyCompetitionsQuery,
  useGetAvailableCompanyCompetitionsQuery,
  useGetCompanyCompetitionQuery,
  useGetCompanyCompetitionInstanceQuery,
  useStartCompanyCompetitionMutation,
  useUpdateContestUserMutation,
  useUpdateContestTeamMutation,
  useDeleteContestTeamMutation,
  useLazyGetOrganizationEntityStatisticsQuery,
  useGetCompaniesQuery,
  useToggleCompanyAdminMutation,
  useAddUserMutation,
  useCreateHeadOfficeMutation,
  useCreateOfficeMutation,
  useCreateMembershipMutation,
  useChangeMembershipMutation,
  useUpdateHeadOfficeMutation,
  useDeleteHeadOfficeMutation,
  useUpdateOfficeMutation,
  useDeleteOfficeMutation,
  useAnonymizeUserMutation,
  useSendContestInvitationsMutation,
  useSendContestReminderInvitationsnMutation,
  useUpdateRegistrationCodeExpirationMutation,
  useDeleteRegistrationCodeMutation,
  useGetAddableContestUsersQuery,
  useAddContestUserMutation,
  useGetContestActivityStatsQuery,
  useGetContestHealthchoiceStatsQuery,
  useGetContestQuizResultsQuery,
  useCreateCompanyFromTemplateMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
  useRecalculateContestMutation,
  useSetFeedbackMutation,
  useUseGetUmbrellaCompaniesQuery,
  useUseGetUmbrellaCompanyQuery,
  useGetCompaniesInUmbrellaCompanyQuery,
  useGetUmbrellaCompanyCommunicationSlugsQuery,
  useAddUmbrellaCompanyCommunicationSlugMutation,
  useDeleteUmbrellaCompanyCommunicationSlugMutation,
  useGetCompanyCommunicationSlugsQuery,
  useAddCompanyCommunicationSlugMutation,
  useDeleteCompanyCommunicationSlugMutation,
  useSendHealthQuizReminderMutation,
  useSendPulseQuizReminderMutation,
  useGetFilteredHealthquizResultsQuery,
  useGetStartablePulseQuizesQuery,
  useStartPulseQuizMutation,
  useGetUsageStatsQuery,
  useUpdateContestBonusStepsMutation,
  useUpdateContestDateMutation,
  useGetBaselineStatisticsQuery,
  useGetBaselineHealthScoreQuery,
  useGetBaselineQuizResultQuery,
  useGetHealthQuizInfoQuery,
  useGetHealthQuizInfoContentQuery,
  useResendInvitationMutation,
  useUseClearCacheMutation,
  useGetInactiveUserQuery,
  useDeleteUsersMutation,
} = adminApi;
