import Icon from 'components/UI/Icon';
import useIsMobile from 'hooks/useIsMobile';
import { useBottomMenu } from 'hooks/useMenu';
import { useMenuType } from 'hooks/useMenuType';
import { MenuType } from 'models';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Notification } from './styles';

import { NavBar, MenuLink, Title, NavBarPlaceholder } from './styles';

interface Props {}

const BottomNavigationBar: FC<Props> = () => {
  const items = useBottomMenu();
  const isMobile = useIsMobile();
  const { pathname } = useLocation();
  const { activeTab } = useMenuType();

  if (!isMobile || activeTab !== MenuType.Regular) {
    return null;
  }

  return (
    <>
      <NavBarPlaceholder />
      <NavBar>
        {items.map((item) => {
          const isActive = pathname.includes(item.link ?? '');
          return (
            <MenuLink
              key={item.id}
              to={item.link ?? ''}
              $active={isActive}
              onClick={() => window.scrollTo(0, 0)}
            >
              {item.icon != null && (
                <span style={{ position: 'relative' }}>
                  <Icon type={item.icon} color={isActive ? 'blue' : 'black'} />
                  {item.hasNotification && <Notification />}
                </span>
              )}
              <Title $active={isActive}>{item.text}</Title>
            </MenuLink>
          );
        })}
      </NavBar>
    </>
  );
};

export default BottomNavigationBar;
