import React, { FC, useEffect } from 'react';
import { confetti } from 'tsparticles-confetti';
import {
  ButtonContainer,
  ButtonTopInContainer,
  Dialog,
  DialogBody,
  MilestoneIcon,
  Overlay,
  StyledLottie,
} from './styles';
import Button from 'components/UI/Button';
import { FormattedMessage } from 'react-intl';
import Loader from 'components/UI/Loader';

type Props = {
  isOpen: boolean;
  isLoading?: boolean;
  onConfirm: () => void;
  onAbort: () => void;
  icon?: string;
  lottieIcon?: any;
  confettis?: boolean;
  children: React.ReactNode;
  cancelText?: string;
};

const MilestonePopup: FC<Props> = ({
  isOpen,
  onConfirm,
  onAbort,
  children,
  icon,
  lottieIcon,
  confettis = true,
  isLoading = false,
  cancelText,
}) => {
  // Freeze body
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  // Trigger confetti when isOpen changes
  useEffect(() => {
    if (isOpen && confettis) {
      confetti({
        spread: 360,
        ticks: 50,
        gravity: 0,
        decay: 0.94,
        startVelocity: 30,
        colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8'],
        particleCount: 80,
        scalar: 1.2,
        shapes: ['circle', 'square', 'triangle', 'polygon', 'star'],
      });
    }
  }, [confettis, isOpen]);

  return (
    <>
      <Overlay open={isOpen} onClick={onAbort} />
      <Dialog open={isOpen}>
        {isOpen && (
          <>
            {icon && (
              <MilestoneIcon src={icon} alt="Level icon" />
            )}
            {lottieIcon && (
              <StyledLottie
                loop={true}
                animationData={lottieIcon}
              />
            )}
            <DialogBody>{children}</DialogBody>
            {isLoading ? (
              <div style={{ margin: '10px 0' }}>
                <Loader color="blue" />
              </div>
            ) : (
              <ButtonContainer>
                <ButtonTopInContainer>
                  <Button
                    onClick={onConfirm}
                    background="yellowDark"
                    color="grayText"
                    size='large'
                    disabled={isLoading}
                  >
                    <FormattedMessage
                      id="confirmButton"
                      defaultMessage="Confirm"
                      description="Confirm button text"
                    />
                  </Button>
                </ButtonTopInContainer>
                {cancelText &&
                  <Button
                    onClick={onAbort}
                    background="transparent"
                    color="grayText"
                    disabled={isLoading}
                  >
                    {cancelText}
                  </Button>
                }
              </ButtonContainer>
            )}
          </>
        )}
      </Dialog>
    </>
  );
};

export default MilestonePopup;