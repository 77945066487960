import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Category } from 'styles';
import { ContentText } from 'models';
import { useLocalizedText } from 'hooks/useLocalizedText';

import Heading, { Tag } from 'components/UI/Heading';
import StaticCard from 'components/UI/Cards/StaticCard';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';

import { Grid, Column, TitleGrid } from './styles';
import CheckBadge from 'components/UI/CheckBadge';

type Props = {
  stepNumber: number;
  title: ContentText | null;
  isCompleted: boolean;
  onClick?: () => void;
  locked?: boolean;
};

const MeasureStepCard: FC<Props> = ({
  title,
  stepNumber,
  isCompleted,
  onClick,
  locked
}) => {
  const getText = useLocalizedText();
  return (
    <StaticCard locked={locked}>
      <Grid onClick={onClick}>
        <Column>
          <Category>
            <FormattedMessage
              id="measureStepCardNumber"
              defaultMessage="Step {stepNumber}"
              description="Step number for measure step card"
              values={{
                stepNumber,
              }}
            />
          </Category>
          <TitleGrid>
            {isCompleted && <CheckBadge />}
            <Heading tag={Tag.H3}>{getText(title)}</Heading>
          </TitleGrid>
        </Column>
        <Column>
          <IconButton>
            <Icon type={IconType.Arrow} />
          </IconButton>
        </Column>
      </Grid>
    </StaticCard>
  );
};

export default MeasureStepCard;
