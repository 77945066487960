import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { localizationSelector, useGetWeeklyActivitiesQuery } from 'store';
import { FormattedMessage } from 'react-intl';


import StaticCard from 'components/UI/Cards/StaticCard';
import Loader from 'components/UI/Loader';
import Heading, { Tag } from 'components/UI/Heading';
import { Gap } from 'styles';
import { useAppSelector } from 'hooks/redux';
import { sortArrayByProperty } from 'utils/array';
import { getPastDateByDays, formatDate, getShortDateDay, isAfter, isBefore } from 'utils/date';
import ActivityList from '../ActivityList';
import { ContentContainer, DayCircle, Grid, Separator, WeekdayGrid } from './styles';

type Props = {
  startDate?: string;
  endDate?: string;
  allowedActivities?: string[];
  allowAllActivities?: boolean;
  maxAmountPerDay?: number;
};

const LastActivities: FC<Props> = ({
  startDate,
  endDate,
  allowedActivities,
  allowAllActivities = true,
  maxAmountPerDay,
}) => {
  const { language } = useAppSelector(localizationSelector);
  const RE_FETCH_TIMEOUT_MS = 1000 * 10
  const [lastWeeklyActivitiesFetch, setLastWeeklyActivitiesFetch] = useState(0);

  // Hooks
  const activities = useGetWeeklyActivitiesQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: lastWeeklyActivitiesFetch + RE_FETCH_TIMEOUT_MS < Date.now(),
  });


  useEffect(() => {
    setLastWeeklyActivitiesFetch(Date.now())
  }, [activities.fulfilledTimeStamp])

  useEffect(() => {
    const payload = () => {
      activities.refetch();
    };
    window.addEventListener('canUpdateSteps', payload);
    return () => window.removeEventListener('canUpdateSteps', payload);
  });

  // State
  const [active, setActive] = useState<number>(new Date().getDay());

  // Activities
  const allowedActivitiesList = useMemo(() => {
    if (!allowedActivities) {
      return [];
    }

    if (!activities.data || activities.data.length === 0) {
      return null;
    }
    const startDateDate = new Date(startDate ?? '');
    return activities.data!.filter((item) => {
      if (endDate && isAfter(endDate)) {
        return false;
      }
      if (startDate && isBefore(startDate)) {
        return false;
      }
      if (!allowAllActivities && allowedActivities) {
        return allowedActivities.includes(item.value.activitySlug);
      }
      const activityDate = new Date(item.timestamp);
      return activityDate >= startDateDate;
    });
  }, [allowedActivities, activities.data, startDate, endDate, allowAllActivities]);

  // On set active
  const onSetActive = useCallback((i: number, date: Date) => () => {
    setActive(i);
  }, []);

  // Days
  const days = useMemo(() => {
    return [
      getPastDateByDays(6),
      getPastDateByDays(5),
      getPastDateByDays(4),
      getPastDateByDays(3),
      getPastDateByDays(2),
      getPastDateByDays(1),
      formatDate(),
    ];
  }, []);

  const activeDayActivities = useMemo(
    () =>
      (allowedActivitiesList ?? []).filter(
        (activity) => new Date(activity.timestamp).getDay() === active
      ),
    [active, allowedActivitiesList]
  );

  // Get step count
  const getStepCount = useCallback(
    (day: number) => {
      if (!allowedActivitiesList) {
        return 0;
      }

      return allowedActivitiesList.reduce((sum, { timestamp, value }) => {
        if (new Date(timestamp).getDay() === day) {
          return sum + value.stepsAwarded;
        }
        return sum;
      }, 0);
    },
    [allowedActivitiesList]
  );

  // Get step count
  const getShortDay = useCallback(
    (date: string) => getShortDateDay(new Date(date), language?.languageCode),
    [language]
  );

  // Last activities
  const lastActivities = useMemo(() => {
    if (activeDayActivities.length === 0) {
      return null;
    }
    const sortedActivities = sortArrayByProperty(
      activeDayActivities,
      'timestamp',
      'desc'
    );
    return (
      <>
        <Separator />
        <ActivityList showWalkSteps data={sortedActivities} />
      </>
    );
  }, [activeDayActivities]);

  const weeklyActivities = useMemo(() => {
    if (!allowedActivitiesList || allowedActivitiesList.length === 0) {
      return null;
    }
    return (
      <StaticCard>
        <Grid>
          <WeekdayGrid>
            {days.map((day) => (
              <DayCircle
                key={day}
                isActive={new Date(day).getDay() === active}
                awardedSteps={getStepCount(new Date(day).getDay()) > 0}
                onClick={onSetActive(new Date(day).getDay(), new Date(day))}
                isProgressComplete={getStepCount(new Date(day).getDay()) >= (maxAmountPerDay ?? 0) && (maxAmountPerDay ?? 0) > 0}
              >
                {getShortDay(day)}
              </DayCircle>
            ))}
          </WeekdayGrid>
          {lastActivities}
        </Grid>
      </StaticCard>
    );
  }, [active, allowedActivitiesList, days, getShortDay, getStepCount, lastActivities, maxAmountPerDay, onSetActive]);

  // Loading
  if (activities.isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!activities.data) {
    return null;
  }

  return (
    <>
      <Heading tag={Tag.H4}>
        <FormattedMessage
          id="pageCompetitionLatestActivityTitle"
          defaultMessage="My latest registered activities"
          description="Title for my latest activity section on competition page"
        />
      </Heading>
      <StaticCard>
        {weeklyActivities}
        <Heading tag={Tag.H6}>
          <ContentContainer>
            <FormattedMessage
              id="pageCompetitionLatestActivitySubTitle"
              defaultMessage="You can only count activities from the time you accepted the competition."
              description="SubTitle for my latest activity section on competition page"
            />
          </ContentContainer>
        </Heading>
      </StaticCard>
      <Gap />
    </>
  );
};

export default LastActivities;
