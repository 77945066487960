import Lottie from "lottie-react";
import styled from "styled-components";
import { CategoryContainer, ChartGrid } from "../HealthWheel/styles";
import { breakpoints } from "styles";

export const CategoryCircle = styled.div`
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.blue};
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.8;
`;

export const HeadingTitle = styled.h1`
  font-size: 24px;
  font-weight: 700;
  margin: 16px 0;
`;

export const ContentSectionContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const CustomRichTextContainer = styled.div`
margin: 16px 0px;
padding: 0px;
ul li {
  list-style-type: none;
  }
  ul {
  padding-left: 20px;
  }
`;

export const AnimationContainer = styled.div`
  position: relative;
`;

export const StyledLottie = styled(Lottie)`
  flex: 1;
  display: flex;
  position: relative;
  z-index: 1;
  pointer-events: none;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  max-height: 960px;
  `;

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: space-between;
`;

export const ContentTextContainer = styled.div`
  // padding: 0px 8px 72px 8px;

  // @media (max-width: ${breakpoints.s}px) {
  // padding: 0px 0px 72px 0px;
  // }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 0;
  margin-top: 16px;
`;

export const WheelContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  margin-top: 24px;

  ${ChartGrid} {
    grid-template-columns: repeat(1, 1fr);
  }
    ${CategoryContainer} {
    display: none;
  }

  @media (max-width: ${breakpoints.s}px) {
   padding: 0px;
  }
`;

export const SmokeAnimation = styled(StyledLottie)`
  position: absolute;
  height: 100%;
  left: 0;
  z-index: 2;
`;

export const ButtonText = styled.div`
  text-decoration: underline;
`;