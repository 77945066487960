import styled from 'styled-components';
import { breakpoints } from 'styles';

type CategoryProps = {
  isActive: boolean;
  isDisabled: boolean;
  onClick?: () => void;
};

export const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const BadgeContainer = styled.div`
position: absolute;
top: -4px;
right: -40px;
@media (max-width: ${400}px) {
  right: -20px;
}
`;

export const IconBubble = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  border-radius: 100%;
`;

export const Category = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;

  @media (max-width: ${breakpoints.m}px) {
    justify-content: center;
  }
`;
export const CategoryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  padding-top: 0.25rem;
  flex-wrap: wrap;
  @media (min-width: 450px) {
    gap: 0.5rem;
    justify-content: flex-start;
  }
`;

export const HeadingNumber = styled.span`
  font-size: 1.5rem;
  font-weight: 1000;
  padding-left: 0.2rem;
`;

export const HeadingCategory = styled.span`
  font-size: 1.25rem;
  font-weight: 900;
  padding-left: 0.2rem;
  color: ${({ theme }) => theme.colors.grayText};
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;

   h1 {
    font-size: 2rem;
    margin: 0;
    letter-spacing: -0.11rem;
  }
`;

export const CategoryCircle = styled.div.attrs((props: CategoryProps) => ({
  onClick: props.onClick,
})) <CategoryProps>`
  display: flex;
  width: 2.25rem;
  height: 2.25rem;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 100%;
  cursor: pointer;
  flex-shrink: 0;

    @media (max-width: 375px) {
    width: 2rem;
    height: 2rem;
  }

  ${({ isActive, isDisabled, theme }) => {
    if (isDisabled) {
      return `
        opacity: 0.5;
        pointer-events: none;
      `;
    }
    if (!isActive) {
      return `
        pointer-events: none;
        background-color: ${theme.colors.blueDark};
        border: 1px solid ${theme.colors.blueDark};
      `;
    }
    else {
      return `
        background-color: ${theme.colors.borderLight};
        border: 1px solid ${theme.colors.borderLight};
      `;
    }
  }}
`;

export const ButtonShowGoodTests = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  background-color: ${({ theme }) => theme.colors.backgroundLight};
  font-size: 0.9rem;
  border-radius: 16px;
  width: 100%;
  font-weight: 600;
  padding: 16px;
  margin: 16px 0;
  @media (min-width: ${breakpoints.m}px) {
    &:hover {
      color: ${({ theme }) => theme.colors.orange};
    }
  }
`;