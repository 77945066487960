import styled, { keyframes } from 'styled-components';

export const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 48px;
`;


export const Overlay = styled.div<{ open: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: ${({ theme }) => theme.colors.black};
  opacity: ${({ open }) => (open ? 0.5 : 0)};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  transition: opacity 0.15s ease-in-out;
  z-index: 100;
  `;

const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 255, 0, 0.7);
    }
  70% {
    transform: scale(1);
    box-shadow: 0 0 10px 10px rgba(255, 255, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 255, 0, 0);
  }
`;

export const PulsateCard = styled.div`
  animation: ${pulse} 2s infinite;
  border-radius: 24px;
  z-index: 110;
`;