import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Coin = styled.div<{ size?: number }>`
  display: flex;
  width: ${({ size }) => (size ? `${size}px` : '20px')};
  height: ${({ size }) => (size ? `${size}px` : '20px')};
  background-color: #f3b303;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.white};

  img {
    padding: 4px;
  }
`;

type Props = {
  inverted: boolean;
};

export const Text = styled.span<Props>`
  color: ${({ theme, inverted }) =>
    theme.colors[inverted ? 'white' : 'grayText']};
  font-size: 12px;
  text-transform: uppercase;
  margin-right: 4px;
`;
