import styled from 'styled-components';

export const CategoryHeader = styled.div`
  h1 {
    margin: 0;
  }

  p {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const ButtonGrid = styled.div`
  display: flex;
  justify-content: center;
`;

type NavIcon = {
  right?: boolean;
  left?: boolean;
  last?: boolean;
};

export const NavButton = styled.button<NavIcon>`
  display: flex;
  align-items: center;
  rotate: ${({ left }) => (left ? '180deg' : 'none')};
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: 16px;
  padding: 12px;
  outline: none;
  background: transparent;
  cursor: pointer;
   position: absolute;
  ${({ left }) => left && 'left: 0;'}
  ${({ right }) => right && 'right: 0;'}

  &:disabled {
    opacity: 40%;
    cursor: not-allowed;
  }
`;

export const NavText = styled.span`
  color: ${({ theme }) => theme.colors.black};
  padding: 5px 4px;
`;

export const NavButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;