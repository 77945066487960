import { GiftClaimStatus } from 'models';
import { baseApi } from '../baseApi';

const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    setGiftClaimStatusClaimed: builder.mutation<void, { giftClaimStatus: GiftClaimStatus }>({
      query: ({ giftClaimStatus }) => ({
        url: `/usergifts/me/gift-claim?giftClaimStatus=${giftClaimStatus}`,
        method: 'PATCH',
      }),
    }),
    sendGiftRequestToInactiveUsers: builder.mutation<void, string[]>({
      query: (userIds) => ({
        url: '/usergifts',
        method: 'POST',
        body: userIds,
      }),
    }),
    getAllCount: builder.query<number, void>({
      query: () => ({
        url: '/usergifts/count',
        method: 'GET',
      }),
    }),
    getClaimedCount: builder.query<number, void>({
      query: () => ({
        url: '/usergifts/claimed/count',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useSetGiftClaimStatusClaimedMutation,
  useSendGiftRequestToInactiveUsersMutation,
  useGetAllCountQuery,
  useGetClaimedCountQuery,
} = userApi;
