import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import { MediaDisplay } from 'styles';
import { QuizComplete, QuizQuestion, QuizQuestionActive, QuizType } from 'models';
import {
  useGetQuizQuery,
  useAnswerQuizQuestionMutation,
  localizationSelector,
  useSubmitQuizMutation,
  useCompleteContestQuizMutation,
} from 'store';
import { useQuery } from 'hooks/useQuery';
import { useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { getActiveQuestionById } from 'utils/quiz';
import { addOrRemoveFromArray } from 'utils/array';
import { getCategoryIcon } from 'utils/asset';
import * as routes from 'router/routes';

import IconButton from 'components/UI/IconButton';
import SliderField from 'components/UI/SliderField';
import QuizAlternativeCard from 'components/cards/QuizAlternativeCard';
import BackgroundCover from 'components/UI/BackgroundCover';
import EmptyState from 'components/UI/EmptyState';
import Icon, { IconType } from 'components/UI/Icon';
import Loader from 'components/UI/Loader';

import {
  AlternativeGrid,
  ProgressLine,
  QuizHeader,
  QuizBody,
  QuestionContainer,
  QuestionHeader,
  QuestionText,
  HeaderCenterCol,
  HeaderLeftCol,
  HeaderRightCol,
  NavButtons,
  NavButton,
  NavText,
  Content,
  HealthCategory,
  QuestionSetContainer,
} from './styles';
import TextAreaField from 'components/UI/TextAreaField';

const Quiz: FC = () => {
  // Hooks
  const navigate = useNavigate();
  const getText = useLocalizedText();
  const { slug } = useParams();
  const [questionId, redirect] = useQuery('questionId', 'redirect') ?? '0';
  const { language } = useAppSelector(localizationSelector);
  const quiz = useGetQuizQuery({ slug, language: language?.languageCode });
  const [answerQuestion, answerResult] = useAnswerQuizQuestionMutation();
  const [submitQuiz, submitResult] = useSubmitQuizMutation();
  const [completeContestQuiz] = useCompleteContestQuizMutation();
  const location = useLocation();

  // States
  // const [answerSubmitted, setAnswerSubmitted] = useState(false);
  const [answerSubmitted, setAnswerSubmitted] = useState<string[]>([]);
  const [outsideQuiz, setOutsideQuiz] = useState<QuizComplete>();
  const [textAreaValue, setTextAreaValue] = useState<string>('');
  const [isQuestionSet, setIsQuestionSet] = useState<boolean>(false);
  const [isOnboardingQuiz, setIsOnboardingQuiz] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { from, goalId, contestId, quizId, quizType } = location.state || {};

  useEffect(() => {
    if (quizType) {
      setIsOnboardingQuiz(quizType === QuizType.overview);
    }
  }, [quizType]);

  useEffect(() => {
    if (isQuestionSet &&
      (questionId === undefined || questionId === null)) {
      navigate(`${routes.QUIZ}/${slug}?questionId=0`);
    }
  }, [questionId, navigate, slug]);


  function addAnswer(id: string) {
    setAnswerSubmitted((prevIds) => {
      if (prevIds.indexOf(id) === -1) {
        return prevIds.concat(id);
      }
      return prevIds;
    });
  }

  const { data, isLoading } = useMemo(() => {
    return {
      data: answerResult.data || quiz.data || null,
      isLoading: submitResult.isLoading || quiz.isLoading,
      isError: submitResult.isError || answerResult.isError || quiz.isError,
      error: submitResult.error || answerResult.error || quiz.error,
    };
  }, [submitResult, answerResult, quiz]);

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaValue(e.target.value);
  };

  // Active data
  const activeData = useMemo<QuizQuestionActive | null>(() => {
    if (!quiz.data) {
      return null;
    }
    if (quiz.data.progress.questions !== undefined) {
      return getActiveQuestionById(quiz.data.progress.questions, questionId, false);
    }

    setIsQuestionSet(true);
    return getActiveQuestionById(quiz.data.progress.questionSets, questionId, true, Number(questionId));

  }, [quiz.data, questionId]);

  // being set when from contest quizzes
  useEffect(() => {
    if (location.state) {
      setOutsideQuiz({
        from: from,
        goalId: goalId,
        contestId: contestId,
        quizId: quizId,
        quizType: quizType
      });
    }
  }, [contestId, from, goalId, location.state, quizId, quizType]);

  useEffect(() => {
    if (activeData == null) {
      return;
    }
    if (!isQuestionSet) {
      const question = activeData.question as QuizQuestion;
      addAnswer(question.id);

    }
  }, []);

  const defaultResponse = useMemo(() => {
    if (!activeData) {
      return undefined;
    }

    const question = Array.isArray(activeData.question)
      ? activeData.question[0]
      : activeData.question;

    if (question.type !== 'sliderQuestion') {
      return undefined;
    }

    const { maxPoints } = question;
    return Math.round(maxPoints.value / 2);
  }, [activeData, isQuestionSet, questionId]);


  const defaultResponseQuestion = useCallback((question: QuizQuestion) => {
    if (question.type !== 'sliderQuestion') {
      return undefined;
    }

    const { maxPoints } = question;
    return Math.round(maxPoints.value / 2);
  }, []);


  // Callbacks
  const onSetAnswer = useCallback(async (
    question: QuizQuestion,
    points: number,
    alternativeId?: string,
    onSubmitted?: () => void,
    input?: string,
  ) => {
    const { id, type, multipleAnswersAllowed } = question;
    if (type === 'alternativeQuestion' && alternativeId) {
      await answerQuestion({
        slug,
        answer: {
          questionId: id,
          alternativeIds: multipleAnswersAllowed
            ? addOrRemoveFromArray(question.answers, alternativeId)
            : [alternativeId],
          points,
        },
        language: language?.languageCode,
      }).unwrap();
    }
    if (type === 'sliderQuestion') {
      await answerQuestion({
        slug,
        answer: {
          questionId: id,
          points,
        },
        language: language?.languageCode,
      }).unwrap();
    }
    if (type === 'inputQuestion') {
      await answerQuestion({
        slug,
        answer: {
          questionId: id,
          points,
          input: input
        },
        language: language?.languageCode,
      }).unwrap();
    }
    addAnswer(id);
  }, [answerQuestion, slug, language]
  );

  // Set question
  const onSetQuestion = useCallback(
    (id?: string | null) => {
      if (id) {
        const redirectPath = redirect ? `&redirect=${redirect}` : '';
        navigate(`${routes.QUIZ}/${slug}?questionId=${id}${redirectPath}`);
      }
    },
    [navigate, slug, redirect]
  );

  // Previous question
  const onSetPrev = useCallback(
    () => onSetQuestion(activeData?.prevId),
    [onSetQuestion, activeData]
  );

  const onSkip = useCallback(
    () => {
      if (isOnboardingQuiz) {
        return navigate(routes.ONBOARDINGSTEP3, { state: { from: from } });
      }
    },
    [from, isOnboardingQuiz, navigate]
  );


  // Next question
  const onSetNext = useCallback(async () => {
    if (defaultResponse !== undefined &&
      activeData?.nextId
    ) {

      if (!activeData || !data) {
        return null;
      }

      const { question } = activeData;

      const answerCallback = !activeData?.nextId
        ? () => {
          if (outsideQuiz && !isOnboardingQuiz) {
            completeContestQuiz({
              contestId: outsideQuiz.contestId!,
              goalId: outsideQuiz.goalId,
              contestQuizId: outsideQuiz.quizId,
            });
          }
          submitQuiz({ slug, isQuestionSet });
        }
        : undefined;

      if (isQuestionSet) {
        setIsSubmitting(true);
        const questionSet = activeData?.question as QuizQuestion[];
        try {

          for (const question of questionSet) {
            if (answerSubmitted.includes(question.id)) {
              continue;
            }

            if (textAreaValue.length > 0 && question?.type === 'inputQuestion') {
              await onSetAnswer(
                question,
                defaultResponseQuestion(question) as number,
                undefined,
                answerCallback,
                textAreaValue
              );
              return setTimeout(() => submitQuiz({ slug }), 1000);
            }

            await onSetAnswer(
              question,
              defaultResponseQuestion(question) as number,
              undefined,
              answerCallback
            );
          }
        } finally {
          setIsSubmitting(false);
        }
      }
      else {
        const regularQuestion = question as QuizQuestion;
        if (!answerSubmitted.includes(regularQuestion.id)) {

          if (textAreaValue.length > 0 && regularQuestion?.type === 'inputQuestion') {
            await onSetAnswer(question as QuizQuestion, 0, undefined, undefined, textAreaValue);
            return setTimeout(() => submitQuiz({ slug }), 1000);
          }

          await onSetAnswer(
            activeData?.question as QuizQuestion,
            defaultResponseQuestion(question as QuizQuestion) as number,
            undefined,
            answerCallback
          );
        }
      }

      if (!answerCallback) {
        onSetQuestion(activeData?.nextId);
        return;
      }
    } else if (!activeData?.nextId) {

      if (outsideQuiz && !isOnboardingQuiz) {
        await completeContestQuiz({
          contestId: outsideQuiz.contestId!,
          goalId: outsideQuiz.goalId,
          contestQuizId: outsideQuiz.quizId,
        });
      }

      if (!isQuestionSet) {
        let activedataquestion = activeData?.question as QuizQuestion;
        if (textAreaValue.length > 0 && activedataquestion?.type === 'inputQuestion') {
          await onSetAnswer(activedataquestion, 0, undefined, undefined, textAreaValue);
          return setTimeout(() => submitQuiz({ slug }), 1000);
        }
      } else {
        const questionSet = activeData?.question as QuizQuestion[];
        for (const question of questionSet) {
          if (answerSubmitted.includes(question.id)) {
            continue;
          }

          if (textAreaValue.length > 0 && question?.type === 'inputQuestion') {
            await onSetAnswer(
              question,
              defaultResponseQuestion(question) as number,
              undefined,
              undefined,
              textAreaValue
            );
            return setTimeout(() => submitQuiz({ slug }), 1000);
          }

          await onSetAnswer(
            question,
            defaultResponseQuestion(question) as number,
            undefined,
            undefined
          );
        }
      }

      return submitQuiz({ slug, isQuestionSet });
    }
    onSetQuestion(activeData?.nextId);
  }, [defaultResponse, activeData, onSetQuestion, data, isQuestionSet, outsideQuiz, submitQuiz, slug, completeContestQuiz, answerSubmitted, textAreaValue, onSetAnswer, defaultResponseQuestion]);

  // Close quiz
  const onClose = useCallback(
    () => navigate(outsideQuiz ? outsideQuiz.from : redirect || routes.TESTS),
    [outsideQuiz, navigate, redirect]
  );

  // Active question
  const renderActiveQuestion = useMemo(() => {
    if (!activeData || !data) {
      return null;
    }

    const { count, question } = activeData;
    const renderQuestion = (question: QuizQuestion) => {
      const { minPoints, maxPoints } = question;
      return (
        <div key={question.id}>
          {!isQuestionSet && (
            <QuestionHeader>
              <FormattedMessage
                id="pageQuizQuestionCount"
                defaultMessage="Question {count}/{total}"
                description="Question count for quiz"
                values={{
                  count,
                  total: data.progress.noQuestions,
                }}
              />
            </QuestionHeader>
          )}
          <QuestionText>{getText(question.text)}</QuestionText>
          {question.type === 'alternativeQuestion' && (
            <AlternativeGrid>
              {question.alternatives.map((alt) => (
                <QuizAlternativeCard
                  key={alt.id}
                  text={getText(alt.text)}
                  isActive={question.answers.includes(alt.id)}
                  hasMultipleAnswers={question.multipleAnswersAllowed}
                  onClick={() => onSetAnswer(question, alt.points, alt.id)}
                />
              ))}
            </AlternativeGrid>
          )}
          {question.type === 'sliderQuestion' && (
            <SliderField
              minValue={minPoints.value}
              maxValue={maxPoints.value}
              minText={getText(minPoints.description)}
              maxText={getText(maxPoints.description)}
              onChange={(points) => onSetAnswer(question, points)}
              defaultValue={defaultResponseQuestion(question)}
            />
          )}
          {question.type === 'inputQuestion' ? (
            <TextAreaField
              onChange={handleTextAreaChange}
            />
          ) : null}
        </div>
      );
    };

    if (isQuestionSet && activeData) {
      return (
        <div>
          <QuestionHeader>
            <FormattedMessage
              id="pageQuizQuestionsCount"
              defaultMessage="Questions {count}/{total}"
              description="Question count for quiz"
              values={{
                count,
                total: quiz.data?.progress.noQuestions,
              }}
            />
          </QuestionHeader>
          <QuestionSetContainer>
            {Array.isArray(activeData.question) ? activeData.question.map((question: QuizQuestion,) =>
              renderQuestion(question)
            ) : null}
          </QuestionSetContainer>
        </div>
      )
    }

    return (
      <div>
        {renderQuestion(question as QuizQuestion)}
      </div>
    );
  }, [activeData, data, isQuestionSet, getText, defaultResponseQuestion, onSetAnswer, quiz.data?.progress.noQuestions]);


  // Health category
  const healthCategory = useMemo(() => {
    if (!data) {
      return null;
    }
    const { healthCategory } = data.quizDefinition;
    if (!healthCategory) {
      return null;
    }
    const { title, icon } = healthCategory;
    const img = getCategoryIcon(icon, true);
    return (
      <Fragment>
        {img && <img src={img.src} alt={img.alt} color="white" />}
        {getText(title)}
      </Fragment>
    );
  }, [getText, data]);

  // Progress bar
  const progressBar = useMemo(() => {
    if (!data || !activeData) {
      return null;
    }
    const { index } = activeData;
    const noQuestions = quiz.data?.progress?.noQuestions;
    return Array.from(Array(noQuestions).keys()).map((i) => (
      <ProgressLine key={i} isActive={i === index} />
    ));
  }, [data, activeData, quiz.data?.progress.noQuestions]);

  // Content
  const content = useMemo(() => {
    // Loading
    if (isLoading || isSubmitting) {
      return <Loader padding />;
    }

    // No data
    if (!data) {
      return (
        <EmptyState iconType={IconType.Health} padding inverted>
          <FormattedMessage
            id="pageQuizEmptyState"
            defaultMessage="Quiz not found"
            description="Empty state for quiz"
          />
        </EmptyState>
      );
    }

    return (
      <AnimatePresence mode="wait">
        <QuestionContainer
          key={questionId}
          transition={{ duration: 0.2 }}
          initial={{ y: 32, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -32, opacity: 0 }}
        >
          {renderActiveQuestion}
        </QuestionContainer>
      </AnimatePresence>
    );
  }, [data, isLoading, questionId, renderActiveQuestion]);

  // Left nav
  const leftNav = useMemo(() => {
    if (!activeData?.prevId && !isOnboardingQuiz) {
      return null;
    }

    return isOnboardingQuiz && !activeData?.prevId ? (
      <NavButton onClick={onSkip}>
        <NavText>
          <FormattedMessage
            id="skipButton"
          />
        </NavText>
      </NavButton>
    ) : (
      <NavButton left onClick={onSetPrev}>
        <Icon type={IconType.Arrow} color="white" />
      </NavButton>
    );
  }, [onSetPrev, activeData, isOnboardingQuiz, onSkip]);

  // Right nav
  const rightNav = useMemo(() => {
    if (isLoading || !activeData) {
      return null;
    }
    return (
      <NavButton onClick={onSetNext}>
        {activeData.nextId ? (
          <Icon type={IconType.Arrow} color="white" />
        ) : (
          <NavText>
            <FormattedMessage
              id="pageQuizFinish"
              defaultMessage="Finish"
              description="Finish button for quiz"
            />
          </NavText>
        )}
      </NavButton>
    );
  }, [activeData, isLoading, onSetNext]);

  // const rightNav = useMemo(() => {
  //   if (isLoading || !activeData || answerResult.isLoading) {
  //     return null;
  //   }
  //   let questionData = activeData;

  //   if (
  //     answerResult.data &&
  //     answerResult.originalArgs?.answer?.questionId === activeDataQuestion.id
  //   ) {
  //     questionData = getActiveQuestionById(
  //       answerResult.data.progress.questions,
  //       questionId
  //     );
  //   }

  //   if (
  //     activeDataQuestion.type !== 'inputQuestion' &&
  //     activeDataQuestion.type !== 'sliderQuestion' &&
  //     !activeDataQuestion.answered
  //   ) {
  //     return null;
  //   }
  //   return (
  //     <NavButton
  //       onClick={onSetNext}
  //       disabled={!questionData.question && defaultResponse === undefined}
  //     >
  //       {questionData.nextId ? (
  //         <Icon type={IconType.Arrow} color="white" />
  //       ) : (
  //         <NavText>
  //           <FormattedMessage
  //             id="pageQuizShowResults"
  //             defaultMessage="Show results"
  //             description="Show results button for quiz"
  //           />
  //         </NavText>
  //       )}
  //     </NavButton>
  //   );
  // }, [
  //   isLoading,
  //   activeData,
  //   answerResult.isLoading,
  //   answerResult.data,
  //   answerResult.originalArgs?.answer?.questionId,
  //   onSetNext,
  //   defaultResponse,
  //   questionId,
  // ]);

  // Redirect to results
  if (submitResult.isSuccess) {
    if (outsideQuiz && isOnboardingQuiz) {
      return <Navigate to={routes.ONBOARDINGSTEP3} />;
    }

    return (
      <Navigate
        to={`${routes.QUIZ}/${slug}/results`}
        state={
          outsideQuiz && { ContestQuizComplete: outsideQuiz }

        }
      />
    );
  }

  return (
    <BackgroundCover padding>

      {/* // <BackgroundCover textColor='black' color='white' illustrationColor={`blueLight`}> */}
      <QuizHeader>
        <HeaderLeftCol>
          <HealthCategory>{healthCategory}</HealthCategory>
          <MediaDisplay breakpoint="m">
            <IconButton onClick={onClose} padding>
              <Icon type={IconType.Close} color="white" />
            </IconButton>
          </MediaDisplay>
        </HeaderLeftCol>
        <HeaderCenterCol>{progressBar}</HeaderCenterCol>
        <HeaderRightCol>
          <MediaDisplay breakpoint="m" isLarger>
            <IconButton onClick={onClose} padding>
              <Icon type={IconType.Close} color="white" />
            </IconButton>
          </MediaDisplay>
        </HeaderRightCol>
      </QuizHeader>
      <QuizBody>
        <Content>{content}</Content>
        <NavButtons>
          <div>{leftNav}</div>
          {rightNav}
        </NavButtons>
      </QuizBody>
    </BackgroundCover>
  );
};

export default Quiz;
