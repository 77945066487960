import { FC, useCallback, useEffect } from 'react'
import { ButtonContainer, ButtonText, Container, ContentContainer, ContentTextContainer, HeadingTitle, WheelContainer } from './styles'
import { localizationSelector, useContentSearchBySlugQuery } from 'store';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { setActiveOnboardingStep } from 'store/onboardingState/onboardingState';
import RichText from '../RichText';
import Button from 'components/UI/Button';
import HealthWheel from '../HealthWheel';
import * as routes from 'router/routes';


const OnboardingStep3: FC = () => {
  const getText = useLocalizedText();
  const { data } = useContentSearchBySlugQuery({ slug: 'onboarding-step-3' });
  const { language } = useAppSelector(localizationSelector);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { from } = location.state || {};

  useEffect(() => {
    dispatch(setActiveOnboardingStep(3));
  }, [dispatch]);

  const handleNext = useCallback(() => {
    navigate(`/welcome/step4`);
  }, [navigate]);

  const onSkip = useCallback(
    () => {
      return navigate(routes.QUIZ + `/overview-health`, { state: { from: location, quizType: 'overview' } });
    },
    [navigate]
  );

  if (!data?.length || !data[0]?.content) {
    return null;
  }
  const { content, title } = data[0];

  if (!language) {
    return null;
  }

  return (
    <Container>
      <WheelContainer>
        <HealthWheel language={language.languageCode} />
      </WheelContainer>
      <ContentContainer>
        <ContentTextContainer>
          <HeadingTitle>
            {getText(title)}
          </HeadingTitle>
          <RichText>{content}</RichText>
        </ContentTextContainer>

        <ButtonContainer>
          {from &&
            <Button
              size="large"
              color="black"
              background="transparent"
              rounded
              width='100%'
              onClick={() => { onSkip() }}
            >
              <ButtonText>
                <FormattedMessage
                  id={'backToEvaluation'}
                  defaultMessage="Tillbaka till utvärdering"
                  description="Button to next step"
                />
              </ButtonText>
            </Button>
          }
          <Button
            size="large"
            color="white"
            background="blue"
            border="backgroundDark"
            rounded
            width='100%'
            onClick={() => { handleNext() }}
          >
            <FormattedMessage
              id={'toNextStep'}
              defaultMessage="Next step"
              description="Button to next step"
            />
          </Button>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  )
}

export default OnboardingStep3;