import styled from 'styled-components';
import { ThemeColor } from 'styles';

type Props = {
  isLast: boolean;
  color?: ThemeColor;
};

type ImgProps = {
  color?: ThemeColor;
};

function hexToRgbA(hex: string, alpha: number = 1) {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${alpha})`;
  }
  throw new Error('Bad Hex');
}

export const Item = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background: ${({ theme, color }) =>
    color ? ` ${hexToRgbA(theme.colors[color], 0.5)}` : undefined};
      border-radius: ${({ color, isLast }) =>
    color === 'goldBright' ? '16px 16px 0 0' :
      isLast ? '0px 0px 16px 16px' : '0'};
    border-bottom: ${({ theme, isLast }) =>
    !isLast ? `1px solid ${theme.colors.borderLight}` : 'none'};
  h4 {
    margin: 0;
  }
`;

export const ItemHeader = styled.div`
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  & > * {
    overflow: hidden;
  }
`;

export const ItemHeaderCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ItemGrid = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ItemText = styled.span`
  color: ${({ theme }) => theme.colors.grayText};
  font-size: 14px;
  white-space: nowrap;
  overflow: visible;
`;

export const ItemProgress = styled.div`
  width: 100%;
`;

export const ImgContainer = styled.div<ImgProps>`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 99px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.backgroundDark};
  border: 1px solid ${({ theme, color }) => color ? `${theme.colors[color]}` : theme.colors.borderLight};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  flex-shrink: 0;
`;

export const Img = styled.img`
border-radius: 99px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Badge = styled.img`
  width: 24px;
  height: 24px;
`;

export const TotalStepsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

`;
