import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Container } from 'components/Layout/styles';
import { ContentWidth } from 'styles';
import { usePageHeader } from 'hooks/usePageHeader';
import { useUseClearCacheMutation } from 'store';
import Button from 'components/UI/Button';
import toast from 'react-hot-toast';
import MaintenanceInactiveUserList from 'components/fragments/MaintenanceInactiveUserList';
import { StatsCardList } from './style';
import StatisticsCard from 'components/UI/Cards/StatisticsCard/StatisticsCard';
import { IconType } from 'components/UI/Icon';
import { useGetAllCountQuery, useGetClaimedCountQuery } from 'store/userService/userGiftService';

const AdminMaintenance: React.FC = () => {
  const intl = useIntl();
  const [clearCache, clearCacheResult] = useUseClearCacheMutation();
  const { data: giftClaimedCount } = useGetClaimedCountQuery();
  const { data: giftAllCount } = useGetAllCountQuery();

  usePageHeader({
    title: intl.formatMessage({
      id: 'menuUtilities',
      defaultMessage: 'Maintenance',
      description: 'Page title for admin maintenance page',
    }),
  });

  const handleClearCache = async () => {
    try {
      await clearCache().unwrap();
    } catch (error) {
      toast.error(
        `Unexpected error occured.`
      );
    }
  };

  useEffect(() => {
    if (clearCacheResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'toastClearCacheSuccess',
          defaultMessage: 'Cache cleared successfully',
          description: 'Toast message for cache cleared successfully',
        })
      );

    }
  }, [clearCacheResult.isSuccess, intl]);
  return (
    <ContentWidth isSurface>
      <Container>
        <Button onClick={handleClearCache}>
          <FormattedMessage
            id="PageMaintenanceClearCache"
            defaultMessage="Clear Cache"
            description="Clear Cache"
          />
        </Button>
      </Container>
      <br />
      <StatsCardList>
        <StatisticsCard
          title={giftAllCount ?? 0}
          icon={IconType.Gift}
          description={intl.formatMessage({
            id: 'pageAdminMaintenanceSentGiftTitle',
            defaultMessage: 'Sent gifts',
            description:
              'sent gifts card title on admin overview page',
          })}
        />
        <StatisticsCard
          title={giftClaimedCount ?? 0}
          icon={IconType.Gift}
          description={intl.formatMessage({
            id: 'pageAdminMaintenanceReceivedGiftTitle',
            defaultMessage: 'Received gifts',
            description:
              'received gifts card title on admin overview page',
          })}
        />
      </StatsCardList>
      <MaintenanceInactiveUserList />
    </ContentWidth>
  );
};

export default AdminMaintenance;