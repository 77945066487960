import { GendersInQuizStatistics, OrganizationEntities } from 'models/company/company';
import {
  ContentSlug,
  ContentText,
  HealthCategory,
} from 'models/content/content';
import { NotificationMethod } from 'models/notificationSchedule/notificationSchedule';

export type QuizAssessmentLevel =
  | 'good'
  | 'approved'
  | 'changeNeeded'
  | 'riskGroup';

export type QuizAssessmentRef = {
  level: QuizAssessmentLevel;
  title: ContentText | null;
};

export type QuizAssessment = {
  color?: { hex: string };
  resultTitle?: ContentText | null;
  resultText?: ContentText | null;
  assessmentReference?: QuizAssessmentRef;
};

export type QuizQuestionAnswer = {
  questionId: string;
  alternativeIds?: string[];
  points: number;
  input?: string;
  healthCategory?: string;
};

export type QuizQuery = {
  slug?: string;
  language?: string;
  contestId?: string;
  assessmentKey?: string;
  answer?: QuizQuestionAnswer;
  pulseQuizInstanceId?: string;
  noOfResults?: number;
  inclDeletedResults?: boolean;
  isQuestionSet?: boolean;
};
export type PulseQuizQuery = {
  slug?: string;
  language?: string;
  contestId?: string;
  assessmentKey?: string;
  answer?: QuizQuestionAnswer;
  pulseQuizInstanceId?: string;
  noOfResults?: number;
  inclDeletedResults?: boolean;
  isQuestionSetPulseQuiz: boolean;
};

export type QuizCompanyCommand = {
  companyId: string;
};

export type QuizReminderCommand = {
  companyId: string;
  organizationId: string;
  healthQuizSlug: string;
  healthQuizName: string;
  preferredNotificationMethod: NotificationMethod;
  organizationEntityType: OrganizationEntities;
  gender: GendersInQuizStatistics;
};

export type QuizSlugList = {
  slugs: string[];
  language?: string;
};

export type PulseQuizResult = {
  percentage: number;
  assessmentResult: null | QuizAssessment;
};

export type QuizQuestionActive = {
  index: number;
  count: number;
  question: QuizQuestion | QuizQuestion[];
  prevId: string | null;
  nextId: string | null;
};

export type QuizDefinition = {
  id: string;
  title: ContentText | null;
  summary: ContentText | null;
  wellrPoints: number;
  noQuestions: number;
  slug: ContentSlug;
  quizType: QuizType;
  healthCategory: HealthCategory | null;
};

export enum QuizType {
  quiz = 'quiz',
  healthQuiz = 'healthQuiz',
  overview = 'overview',
  review = 'review',
}

export type QuizItem = {
  quizDefinition: QuizDefinition;
  quizResults: QuizResult[];
  quizShouldBeTaken: boolean;
  quizSlug: string;
};

export type QuizQuestionAlternative = {
  id: string;
  points: number;
  text: ContentText;
  followUpQuestion: string[];
};

export type QuizPoints = {
  value: number;
  description: ContentText;
};

export type QuizQuestion = {
  id: string;
  type: string;
  answers: string[];
  answered: boolean;
  multipleAnswersAllowed: boolean;
  text: ContentText;
  points: number;
  healthCategory?: string;
  minPoints: QuizPoints;
  maxPoints: QuizPoints;
  alternatives: QuizQuestionAlternative[];
};

export type QuizQuestionSet = {
  noQuestions: number;
  questions: QuizQuestion[];
};

export type Quiz = {
  id: string;
  slug: string;
  progress: {
    noQuestions: number;
    questions: QuizQuestion[];
    questionSets: QuizQuestionSet[];
  };
  quizDefinition: QuizDefinition;
};

export type QuizProposedMeasure = {
  slug: ContentSlug;
};

export type QuizResult = {
  created: string;
  percentage: number;
  assessment: QuizAssessment;
  proposedMeasures: QuizProposedMeasure[];
};

export type QuizResultData = {
  quizDefinition: QuizDefinition;
  quizResults: QuizResult[];
};

export type PulseQuizItem = {
  id: string;
  quizDefinition: QuizDefinition;
  companyLogoAssetId: string | null;
  description: ContentText;
  startTime: string;
  endTime: string;
};

export type PulseQuiz = {
  id: string;
  pulseQuizId: string;
  companyLogoAssetId: string | null;
  progress: {
    noQuestions: number;
    questions: QuizQuestion[];
    questionSets: QuizQuestionSet[];
  };
  quizDefinition: QuizDefinition;
};