import {
  ContentQuery,
  ContentResponse,
  HealthCategory,
  HealthWheelResponse,
  PulseQuiz,
  PulseQuizItem,
  PulseQuizQuery,
  PulseQuizResult,
  Quiz,
  QuizItem,
  QuizQuery,
  QuizResultData,
  QuizSlugList,
} from 'models';
import { getEndpointURL, WellrEndpointDefinition } from 'utils/api';
import { baseApi } from '../baseApi';

const myPersonalHealthPath = '/api/my-health/personal-health';
const myPersonalHealthMethod = 'get';
type MyPersonalHealthEndpoint = WellrEndpointDefinition<
  typeof myPersonalHealthPath,
  typeof myPersonalHealthMethod
>;

const healthApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMyHealthWheel: builder.query<HealthWheelResponse, ContentQuery>({
      query: ({ language }) => `/my-health/wheel?language=${language}`,
      providesTags: ['Health'],
    }),
    getHealthCategories: builder.query<HealthCategory[], ContentQuery>({
      query: ({ language }) =>
        `/my-health/health-categories?language=${language}`,
      transformResponse: (res: ContentResponse) => res.result,
    }),
    getAvailableQuizes: builder.query<QuizItem[], QuizQuery>({
      query: ({ language, inclDeletedResults = true }) => `/quiz/me?language=${language}&inclDeletedResults=${inclDeletedResults}`,
      providesTags: ['Quiz'],
    }),
    getMyContestQuizes: builder.query<QuizItem[], QuizSlugList>({
      query: ({ slugs, language }) => {
        const params = new URLSearchParams();
        slugs.forEach(slug => params.append('contestQuizSlugs', slug));
        if (language) {
          params.append('language', language);
        }
        return {
          url: `/quiz/contest?${params.toString()}`,
        };
      },
      providesTags: ['Quiz'],
    }),
    createQuiz: builder.mutation<Quiz, QuizQuery>({
      query: ({ slug, contestId, language }) => {
        const params = new URLSearchParams();
        if (contestId) {
          params.append('contestId', contestId);
        }
        if (language) {
          params.append('language', language);
        }
        return {
          url: `/quiz/me/${slug}?${params.toString()}`,
          method: 'POST',
        };
      },
    }),
    deleteHealthQuizResults: builder.mutation<void, { companyId: string | null }>({
      query: ({ companyId }) => ({
        url: `/quiz/company/health-quiz-results${companyId ? `?companyId=${companyId}` : ''}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Quiz'],
    }),
    getQuiz: builder.query<Quiz, QuizQuery>({
      query: ({ slug, language }) => `/quiz/me/${slug}?language=${language}`,
      providesTags: ['Quiz'],
    }),
    getQuizResults: builder.query<QuizResultData, QuizQuery>({
      query: ({ slug, language, noOfResults = 3 }) =>
        `/quiz/me/results/${slug}/numberOfResults?language=${language}&numberOfResults=${noOfResults}`,
    }),
    answerQuizQuestion: builder.mutation<Quiz, QuizQuery>({
      query: ({ slug, answer, language }) => ({
        url: `/quiz/me/answer/${slug}?language=${language}`,
        method: 'POST',
        body: answer,
      }),
      invalidatesTags: ['Quiz'],
      // async onQueryStarted(
      //   { answer, slug, language },
      //   { dispatch, queryFulfilled }
      // ) {
      //   if (!answer || !answer?.questionId || answer.questionId.length === 0) {
      //     return;
      //   }
      //   // Optimistic update the cache to avoid laggy checkbox/radio in UI
      //   const patchResult = dispatch(
      //     healthApi.util.updateQueryData(
      //       'getQuiz',
      //       { slug, language },
      //       (data) => {
      //         const question = data.progress.questions.find(
      //           (q) => q.id === answer.questionId
      //         );
      //         if (question != null) {
      //           question.answered = true;
      //           if (answer.alternativeIds != null) {
      //             question.answers = answer.alternativeIds;
      //           }
      //         }
      //       }
      //     )
      //   );
      //   try {
      //     await queryFulfilled;
      //   } catch (error) {
      //     // Revert optimistic update if the mutation fails
      //     patchResult.undo();
      //   }
      // },
    }),
    submitQuiz: builder.mutation<void, QuizQuery>({
      query: ({ slug, isQuestionSet = false }) => ({
        url: `/quiz/me/submit/${slug}?isQuestionSet=${isQuestionSet}`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: [
        { type: 'Health' },
        { type: 'Measure', id: 'PROPOSED_LIST' }, // a submitted quiz may lead to a proposed health plan
        { type: 'Notifications' },
      ],
    }),
    getAvailablePulseQuizes: builder.query<PulseQuizItem[], QuizQuery>({
      query: ({ language }) => `/pulsequiz/me?language=${language}`,
      providesTags: ['PulseQuiz'],
    }),
    getPulseQuiz: builder.query<PulseQuiz, QuizQuery>({
      query: ({ pulseQuizInstanceId, language }) =>
        `/pulsequiz/me/${pulseQuizInstanceId}?language=${language}`,
      providesTags: ['PulseQuiz'],
    }),
    answerPulseQuizQuestion: builder.mutation<PulseQuiz, QuizQuery>({
      query: ({ pulseQuizInstanceId, answer, language }) => ({
        url: `/pulsequiz/me/answer/${pulseQuizInstanceId}?language=${language}`,
        method: 'POST',
        body: answer,
      }),
      invalidatesTags: ['PulseQuiz'],
    }),
    submitPulseQuiz: builder.mutation<PulseQuizResult, PulseQuizQuery>({
      query: ({ pulseQuizInstanceId, language, isQuestionSetPulseQuiz }) => ({
        url: `/pulsequiz/me/submit/${pulseQuizInstanceId}?language=${language}&isQuestionSetPulseQuiz=${isQuestionSetPulseQuiz}`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: ['Notifications'],
    }),
    getMyPersonalHealth: builder.query<
      MyPersonalHealthEndpoint['responseBody'],
      MyPersonalHealthEndpoint['request']['params']['query']
    >({
      // Accept the parameter here and include it in the request URL
      query: ({ minutesOffsetFromUtc } = { minutesOffsetFromUtc: 0 }) => ({
        url: getEndpointURL(myPersonalHealthPath),
        params: { minutesOffsetFromUtc },  // Add this line to pass the parameter in the query string
      }),
      providesTags: ['Activity'],
    }),
  }),
});

export const {
  useGetMyHealthWheelQuery,
  useGetHealthCategoriesQuery,
  useGetAvailableQuizesQuery,
  useGetMyContestQuizesQuery,
  useCreateQuizMutation,
  useGetQuizQuery,
  useGetQuizResultsQuery,
  useAnswerQuizQuestionMutation,
  useSubmitQuizMutation,
  useGetAvailablePulseQuizesQuery,
  useGetPulseQuizQuery,
  useAnswerPulseQuizQuestionMutation,
  useSubmitPulseQuizMutation,
  useGetMyPersonalHealthQuery,
  useDeleteHealthQuizResultsMutation,
} = healthApi;
