import { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LanguageCode, ReferenceType } from 'models';
import { getRefLink } from 'utils/link';
import { TimedChallenge } from 'models/timedChallengeResult/timedChallengeResult';
import { useCreateMyTimedChallengeResultMutation, useGetIsMyDailyChallengeCompletedQuery, useGetMyTimedChallengeProgressQuery, useUpdateMyTimedChallengeResultProgressMutation } from 'store/timedChallengeService/timedChallengeResultService';

import TimedChallengeCard from 'components/UI/Cards/TimedChallengeCard';
import { useLocalStorage } from 'hooks/useLocalStorage';

type Props = {
  challenge: TimedChallenge;
  progress?: number;
  membershipId: string;
  userId: string;
  language?: LanguageCode;
};

const ChallengeCard: FC<Props> = ({
  challenge,
  progress,
  membershipId,
  userId,
  language
}) => {

  const {
    id,
    title,
    description,
    wellrPoints,
    challengeType,
    reference,
    amountToComplete,
    image
  } = challenge;

  // Hooks
  const navigate = useNavigate();
  const [createMyResult] = useCreateMyTimedChallengeResultMutation();
  const [updateMyChallengeProgress] = useUpdateMyTimedChallengeResultProgressMutation();
  const [, setUnlocked] = useLocalStorage<boolean>('unlocked', false);
  const { data: myChallengeProgress, refetch: refetchProgress } = useGetMyTimedChallengeProgressQuery({ timedChallengeId: id }, {
    refetchOnMountOrArgChange: true,
  });

  // Function to handle result creation
  const handleCreateMyResult = useCallback(async () => {
    if (progress && progress >= 100 && !myChallengeProgress?.completed) {
      await createMyResult({
        wellrPoints: wellrPoints,
        timedChallengeId: id,
        userId: userId,
        membershipId: membershipId,
      });

      await setUnlocked(false);
    }
  }, [progress, myChallengeProgress?.completed, createMyResult, wellrPoints, id, userId, membershipId, setUnlocked]);

  // Actions
  const onClickContent = useCallback(
    (type: ReferenceType, slug: string) => async () => {
      if (type && slug) {
        if (!myChallengeProgress?.completed) {
          await setUnlocked(true);
        }
        navigate(getRefLink(type, slug));
      }
    },
    [myChallengeProgress?.completed, navigate, setUnlocked]
  );

  const onClickComplete = useCallback(
    async () => {
      if (!myChallengeProgress?.completed) {
        await createMyResult({
          wellrPoints: wellrPoints,
          timedChallengeId: id,
          userId: userId,
          membershipId: membershipId,
        });
      }
      await setUnlocked(false);
      await refetchProgress();
    },
    [myChallengeProgress?.completed, setUnlocked, refetchProgress, createMyResult, wellrPoints, id, userId, membershipId]
  );

  const onClickProgress = useCallback(
    async () => {
      if (!myChallengeProgress?.completed) {
        await updateMyChallengeProgress({ timedChallengeId: id });
      }

      await setUnlocked(false);
      await refetchProgress();
    },
    [id, myChallengeProgress?.completed, refetchProgress, setUnlocked, updateMyChallengeProgress]
  );

  // Effect to handle result creation on progress change
  useEffect(() => {
    handleCreateMyResult();
  }, [handleCreateMyResult]);

  const calculatePushGoalProgress = (current: number | undefined, total: number): number | undefined => {
    return current !== undefined ? (current / total) * 100 : undefined;
  };

  const cardImage = image ?? reference?.[0]?.image;
  const cardTitle = title?.[language ?? 'en'];
  const cardDescription = description?.[language ?? 'en'];
  const cardReference = reference?.[0];
  const goalCompleted = myChallengeProgress?.completed ?? false;
  const myProgress = myChallengeProgress?.pressCount;
  const pushGoalProgress = myProgress ? calculatePushGoalProgress(myProgress, amountToComplete) : progress;


  return (
    <>
      {myChallengeProgress?.completed !== undefined && (
        <TimedChallengeCard
          image={cardImage}
          title={cardTitle}
          wellrPoints={wellrPoints}
          challengeType={challengeType}
          description={cardDescription}
          reference={cardReference}
          progress={pushGoalProgress}
          amountToComplete={amountToComplete}
          goalCompleted={goalCompleted}
          onClickContent={onClickContent}
          onClickComplete={onClickComplete}
          onClickProgress={onClickProgress}
        />
      )}
    </>
  );
};

export default ChallengeCard;