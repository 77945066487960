import StaticCard from 'components/UI/Cards/StaticCard';
import Heading, { Tag } from 'components/UI/Heading';
import ProgressBar from 'components/UI/ProgressBar';
import SliderField from 'components/UI/SliderField';
import React, { Fragment } from 'react';
import {
  AnswerAlternative,
  AnswerAlternatives,
  ProgressHeader,
} from './styles';
import DragSlider from 'components/UI/DragSlider';
import ReviewCard from 'components/UI/Cards/ReviewCard';
import { FormattedMessage } from 'react-intl';

interface AnswerAlternativeStatistics {
  _key: string | null;
  percentageOfAnswers: number | null | undefined;
  title: string;
  compareValue: number | null | undefined;
  baselineCompareValue: number | null | undefined;
}

type QuestionData = {
  averagePoints: number | null | undefined;
  maxPoints: number | null | undefined;
  minPoints: number | null | undefined;
  maxPointsDescription: string;
  minPointsDescription: string;
};

type Question = QuestionData & {
  title: string;
  compare: QuestionData;
  baselineCompare: QuestionData;
  unhideByAnswerAlternative: {
    questionKey: string | null;
    alternativeKey: string | null;
  } | null;
  _key: string | null;
  _type: string | null | undefined;
  answers?: string[];
  answerAlternativeStatistics: AnswerAlternativeStatistics[];
};

interface QuestionProps {
  questions: Question[];
  level?: number; // level of indentation
  parentQuestion?: Question['unhideByAnswerAlternative'];
  showBaseline: boolean;
  contestQuiz?: boolean;
}

const QuizQuestionStatistics: React.FC<QuestionProps> = ({
  questions,
  level = 0,
  parentQuestion,
  showBaseline,
  contestQuiz,
}) => {
  let rootQuestions: Question[] = questions.filter(
    (q) => q.answerAlternativeStatistics.length > 0 && !contestQuiz
  );
  let followUpQuestions: Question[] = [];
  if (level === 0) {
    rootQuestions = rootQuestions.filter(
      (q) => q.unhideByAnswerAlternative == null
    );
    followUpQuestions = questions.filter(
      (q) => q.unhideByAnswerAlternative != null
    );
  } else if (parentQuestion != null) {
    rootQuestions = rootQuestions.filter(
      (q) =>
        q.unhideByAnswerAlternative?.questionKey ===
        parentQuestion?.questionKey &&
        q.unhideByAnswerAlternative?.alternativeKey ===
        parentQuestion?.alternativeKey
    );
    followUpQuestions = questions;
  } else {
    throw new Error('parentQuestion must be set if level > 0');
  }
  if (rootQuestions.length === 0 && level === 0) {
    rootQuestions = questions;
  }

  return (
    <div
      style={{
        paddingLeft: `${level * 20}px`,
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      {rootQuestions.map((question) => (
        <div
          key={question._key}
          style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
        >
          <StaticCard>
            <div style={{ padding: level === 0 ? '16px' : '16px 0' }}>
              <Heading tag={Tag.H5}>{question.title}</Heading>
              {question._type === 'sliderQuestion' &&
                <FormattedMessage
                  id="quizQuestionStatistics"
                  defaultMessage="{percentage}% of 100%"
                  description="Text for the result of the user"
                  values={{
                    percentage: question.averagePoints && question.maxPoints && (100 - ((question.averagePoints / question.maxPoints) * 100)).toFixed(1)
                  }} />
              }
              {question._type === 'inputQuestion' && question.answers && (
                <Fragment>
                  <DragSlider id="inspiration" slidesPerView={2}>
                    {question.answers.map((answer, index) => (
                      <ReviewCard
                        key={index}
                        textColor='black'
                        background="backgroundDark">
                        {answer}
                      </ReviewCard>
                    ))}
                  </DragSlider>
                </Fragment>
              )}
              {question.averagePoints != null ? (
                <div style={{ marginTop: 20, marginBottom: 10 }}>
                  <SliderField
                    minValue={question.minPoints ?? undefined}
                    maxValue={question.maxPoints ?? undefined}
                    minText={question.minPointsDescription}
                    maxText={question.maxPointsDescription}
                    defaultValue={question.averagePoints}
                    color="black"
                    disabled
                  />
                  {question.compare.averagePoints && (
                    <SliderField
                      minValue={question.compare.minPoints ?? undefined}
                      maxValue={question.compare.maxPoints ?? undefined}
                      minText={question.compare.minPointsDescription}
                      maxText={question.compare.maxPointsDescription}
                      defaultValue={question.compare.averagePoints}
                      color="purple"
                      disabled
                    />
                  )}
                  {showBaseline && question.baselineCompare.averagePoints && (
                    <SliderField
                      minValue={question.baselineCompare.minPoints ?? undefined}
                      maxValue={question.baselineCompare.maxPoints ?? undefined}
                      minText={question.baselineCompare.minPointsDescription}
                      maxText={question.baselineCompare.maxPointsDescription}
                      defaultValue={question.baselineCompare.averagePoints}
                      color="blue"
                      disabled
                    />
                  )}
                </div>
              ) : (
                <AnswerAlternatives>
                  {question.answerAlternativeStatistics.map((alternative) => (
                    <AnswerAlternative key={alternative._key}>
                      <ProgressHeader>
                        <span>{alternative.title}</span>
                        <span>{alternative.percentageOfAnswers ?? 0}%</span>
                      </ProgressHeader>
                      <ProgressBar
                        color={'black'}
                        progress={alternative.percentageOfAnswers ?? 0}
                      />
                      {typeof alternative.compareValue === 'number' && (
                        <div style={{ marginTop: '5px' }}>
                          <ProgressHeader>
                            <span />
                            <span>{alternative.compareValue ?? 0}%</span>
                          </ProgressHeader>
                          <ProgressBar
                            color={'purple'}
                            progress={alternative.compareValue ?? 0}
                          />
                        </div>
                      )}
                      {showBaseline &&
                        typeof alternative.baselineCompareValue ===
                        'number' && (
                          <div style={{ marginTop: '5px' }}>
                            <ProgressHeader>
                              <span />
                              <span>
                                {alternative.baselineCompareValue ?? 0}%
                              </span>
                            </ProgressHeader>
                            <ProgressBar
                              color={'blue'}
                              progress={alternative.baselineCompareValue ?? 0}
                            />
                          </div>
                        )}
                      {/* Render sub-questions indented */}
                      {(alternative.percentageOfAnswers ?? 0) > 0 && (
                        <QuizQuestionStatistics
                          questions={followUpQuestions}
                          level={level + 1}
                          parentQuestion={{
                            questionKey: question._key,
                            alternativeKey: alternative._key,
                          }}
                          showBaseline={showBaseline}
                        />
                      )}
                    </AnswerAlternative>
                  ))}
                </AnswerAlternatives>
              )}
            </div>
          </StaticCard>
        </div>
      ))}
    </div>
  );
};

export default QuizQuestionStatistics;
