import Lottie from 'lottie-react';
import styled from 'styled-components';
import { breakpoints } from 'styles';

export const MapSettingsContainer = styled.div`
  margin-top: 11px;
  margin-right: 10px;
  background-color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  position: relative;
`;

export const StyledLottie = styled(Lottie)`
  position: absolute;
  width: 110%;
  pointer-events: none;
  bottom: -550px;

  @media (max-width: ${breakpoints.s}px) {
    bottom: -250px;
  }
`;