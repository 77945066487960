import styled from 'styled-components';

export const TeamList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BorderTop = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px;
`;