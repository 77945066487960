import { Outlet, } from 'react-router-dom';
import { ContentWidth } from 'styles';
import BackgroundCover from 'components/UI/BackgroundCover';
import { useSelector } from 'react-redux';
import { selectActiveStep } from 'store/onboardingState/onboardingState';

const Welcome: React.FC = () => {
  const activeStep = useSelector(selectActiveStep);

  return (
    <BackgroundCover textColor='black' color='white' illustrationColor={activeStep === 4 ? `white` : `blueLight`}>
      <ContentWidth noMargins>
          <Outlet />
      </ContentWidth>
     </BackgroundCover>
  );
};

export default Welcome