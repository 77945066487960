import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ContestMe, ContestTeamUser } from 'models';
import { sortArrayByProperty } from 'utils/array';

import StaticCard from 'components/UI/Cards/StaticCard';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';
import TopListProgressItem from 'components/fragments/TopListProgressItem';

import { TeamList, Actions, BorderTop } from './styles';
import Button from 'components/UI/Button';

type Props = {
  me: ContestMe;
  users?: ContestTeamUser[];
  individualResults?: boolean;
  total: number;
};

const CompetitionTopList: FC<Props> = ({
  me,
  users,
  individualResults,
  total,
}) => {
  const [displayMode, setDisplayMode] = useState<'top5' | 'top10' | 'all'>('top5');

  // Check if users exist
  if (!users?.length) {
    return (
      <EmptyState iconType={IconType.Users} padding>
        <FormattedMessage
          id="competitionTopListEmptyState"
          defaultMessage="No users found"
          description="Empty state for competition top list"
        />
      </EmptyState>
    );
  }

  const sortedUsers = sortArrayByProperty(users, 'total', 'desc');
  let usersToShow;
  let buttonText;

  if (sortedUsers.length <= 10) {
    usersToShow = displayMode === 'top5' ? sortedUsers.slice(0, 5) : sortedUsers;
    buttonText = displayMode === 'top5' ? (
      <FormattedMessage
        id="competitionTopListShowMore"
        defaultMessage="Show More"
        description="Button to show more users"
      />
    ) : (
      <FormattedMessage
        id="competitionTopListShowLess"
        defaultMessage="Show Less"
        description="Button to show less users"
      />
    );
  } else {
    switch (displayMode) {
      case 'top5':
        usersToShow = sortedUsers.slice(0, 5);
        buttonText = (
          <FormattedMessage
            id="competitionTopListShowMore"
            defaultMessage="Show More"
            description="Button to show more users"
          />
        );
        break;
      case 'top10':
        usersToShow = sortedUsers.slice(0, 10);
        buttonText = (
          <FormattedMessage
            id="competitionTopListShowAll"
            defaultMessage="Show All"
            description="Button to show all users"
          />
        );
        break;
      case 'all':
        usersToShow = sortedUsers;
        buttonText = (
          <FormattedMessage
            id="competitionTopListShowLess"
            defaultMessage="Show Less"
            description="Button to show less users"
          />
        );
        break;
      default:
        usersToShow = sortedUsers.slice(0, 5);
        buttonText = (
          <FormattedMessage
            id="competitionTopListShowMore"
            defaultMessage="Show More"
            description="Button to show more users"
          />
        );
    }
  }

  const handleButtonClick = () => {
    if (sortedUsers.length <= 10) {
      setDisplayMode(displayMode === 'top5' ? 'all' : 'top5');
    } else {
      if (displayMode === 'top5') {
        setDisplayMode('top10');
      } else if (displayMode === 'top10') {
        setDisplayMode('all');
      } else {
        setDisplayMode('top5');
      }
    }
  };

  return (
    <StaticCard>
      <TeamList>
        {usersToShow.map((user, i) => {
          let totalSteps: number;
          let progress: number | null;
          const showResults = individualResults || user.id === me.id;

          if (showResults) {
            const userTotal = user.id === me.id ? me.total : user.total;
            const cut = userTotal / total;
            progress = !isNaN(cut) && isFinite(cut) ? cut * 100 : 0;
            totalSteps = userTotal;
          } else {
            progress = null;
            totalSteps = 0;
          }

          return (
            <TopListProgressItem
              key={user.id}
              email={user.email}
              alias={user.alias}
              profilePictureAssetId={user.profilePictureAssetId}
              showResults={showResults}
              totalSteps={totalSteps}
              progress={progress}
              isLast={usersToShow.length === i + 1}
            />
          );
        })}
      </TeamList>
      {sortedUsers.length > 5 && (
        <>
          <BorderTop />
          <Actions>
            <Button color="blue" background="transparent" onClick={handleButtonClick}>
              {buttonText}
            </Button>
          </Actions>
        </>
      )}
    </StaticCard>
  );
};

export default CompetitionTopList;