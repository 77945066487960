import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { TabMenuItem, User } from 'models';
import { ContentWidth, Gap } from 'styles';
import { useAppDispatch } from 'hooks/redux';
import {
  RootState,
  setLayoutHeader,
  useGetMyHealthWheelQuery,
  useGetUserLevelsQuery,
} from 'store';
import * as routes from 'router/routes';

import TabMenu from 'components/UI/TabMenu';
import HeroSmall from 'components/UI/Heros/HeroSmall';
import { HealthIcon, HeroCol, HeroGrid, Img, OutletWidth, PrinterIcon, ProfileHeader, ProfileHeaderData, ProfileHeaderDataGrid, ProfileImage, Svg } from './styles';
import Heading, { Tag } from 'components/UI/Heading';

import coinBadge from 'assets/vectors/wellr-badge.svg';
import heartBadge from 'assets/vectors/heart-badge.svg';
import HeadingWithInfo from 'components/UI/HeadingWithInfo';
import LevelsModal from 'components/modals/LevelsModal';

import useLevelIcon from 'hooks/useLevelIcon';
import { useActiveLanguage } from 'hooks/useActiveLanguage';
import useNotifications from 'hooks/useNotifications';
import MilestonePopup from 'components/popups/MilestonePopup';
import Icon, { IconType } from 'components/UI/Icon';
import DataItem from 'components/UI/DataItem';
import { calculateBMI } from 'utils/health';
import { calculateAge } from 'utils/date';
import EmptyState from 'components/UI/EmptyState';
import Loader from 'components/UI/Loader';
import { useGetAssetImage } from 'hooks/useGetAssetImage';
import printer from 'assets/vectors/printer.svg';
import { useLocalizedText } from 'hooks/useLocalizedText';
import useIsMobile from 'hooks/useIsMobile';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/userState/userState';

const HealthTabLayout: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const pdfRef = useRef<HTMLDivElement>(null);
  const getText = useLocalizedText();
  const isMobile = useIsMobile();

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'myHealthTitle',
          defaultMessage: 'My Health ',
          description: 'Title for my health page',
        }),
        inverted: true,
      })
    );
  }, [dispatch, intl]);

  const { languageCode } = useActiveLanguage();

  const user = useSelector((state: RootState) => selectUser(state));
  const { healthQuiz, pulseQuiz, activeMeasure, proposedMeasure } =
    useNotifications();

  // Tab menu
  const tabMenu: TabMenuItem[] = useMemo(() => {
    return [
      {
        id: 1,
        text: intl.formatMessage({
          id: 'tabHealthOverview',
          defaultMessage: 'Overview',
          description: 'Tab item for health overview',
        }),
        link: routes.MY_HEALTH,
        hasNotification: false,
      },
      {
        id: 2,
        text: intl.formatMessage({
          id: 'tabHealthTests',
          defaultMessage: 'Tests',
          description: 'Tab item for health tests',
        }),
        link: routes.TESTS,
        hasNotification: healthQuiz || pulseQuiz,
      },
      {
        id: 3,
        text: intl.formatMessage({
          id: 'tabHealthMeasures',
          defaultMessage: 'Plans',
          description: 'Tab item for health measures',
        }),
        link: routes.MEASURES,
        hasNotification: activeMeasure || proposedMeasure,
      },
    ];
  }, [activeMeasure, healthQuiz, intl, proposedMeasure, pulseQuiz]);

  // State
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [showMilestonePopup, setShowMilestonePopup] = useState(false);


  // On close
  const onClose = useCallback(() => setModalOpen(false), []);
  const onOpen = useCallback(() => setModalOpen(true), []);

  const userLevels = useGetUserLevelsQuery({
    language: languageCode,
  });

  const wheel = useGetMyHealthWheelQuery({ language: languageCode });


  const levelIcon = useLevelIcon(user?.level ?? null, 96);

  const healthLevel = user?.level ?? '-';
  const wellrPoints = user?.points ?? '-';
  const healthIndex = wheel.data?.latest.averagePercentage;
  const level = user?.level ?? null;


  useEffect(() => {
    const storedLevel = localStorage.getItem('lastLevelForMilestonePopup');
    if (user && user?.levelPrivileges.hasLeveledUp !== undefined
      && (storedLevel === null || parseInt(storedLevel) !== user.level)) {
      setShowMilestonePopup(user.levelPrivileges.hasLeveledUp);
    }
  }, []);

  const { firstName, lastName, dateOfBirth, weightKg, lengthCm } = user ?? {};
  const [profileImage] = useGetAssetImage(user?.profilePictureAssetId);

  // Loading
  if (user === null) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!user) {
    return (
      <EmptyState iconType={IconType.Food} padding>
        <FormattedMessage
          id="pageProfileEmptyState"
          defaultMessage="Profile not found"
          description="Empty state for profile page"
        />
      </EmptyState>
    );
  }

  const closeMilestonePopup = () => {
    setShowMilestonePopup(false);
    if (level !== null) {
      localStorage.setItem('lastLevelForMilestonePopup', level.toString());
    }
  };

  const downloadPdf = () => {
    if (pdfRef.current) {
      html2canvas(pdfRef.current).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        // Calculate the aspect ratio of the image
        const imgAspectRatio = canvas.width / canvas.height;

        // Calculate the width of the image while maintaining aspect ratio
        const imgWidth = pdfHeight * imgAspectRatio;
        const imgHeight = pdfHeight;

        // Center the image horizontally
        const imgX = (pdfWidth - imgWidth) / 2;

        pdf.addImage(imgData, 'PNG', imgX, 0, imgWidth, imgHeight);
        pdf.save('healthreport.pdf');
      });
    }
  }

  return (
    <div ref={pdfRef}>
      {!location.pathname.includes('my-health/report') ? (
        <div>
          <HeroSmall background="blue" illustrationColor="blueDark">
            <HeroGrid>
              <HeroCol>
                {levelIcon && (
                  <HealthIcon src={levelIcon.src} alt={levelIcon.alt} />
                )}
                <Heading tag={Tag.H2}>{healthLevel}</Heading>
                <HeadingWithInfo
                  tag={Tag.H6}
                  onClickInfo={onOpen}
                  center
                  color="white"
                >
                  {getText(userLevels?.data?.find((level) => level?.levelNumber === (user as User)?.level)?.title)}
                </HeadingWithInfo>
              </HeroCol>
              <HeroCol>
                <HealthIcon src={coinBadge} alt="points badge" />
                <Heading tag={Tag.H2}>{wellrPoints}</Heading>
                <Heading tag={Tag.H6}>
                  <FormattedMessage
                    id="pageMyHealthWellrPointsTitle"
                    defaultMessage="Wellr points"
                    description="My health page wellr points title"
                  />
                </Heading>
              </HeroCol>
              <HeroCol>
                <HealthIcon src={heartBadge} alt="points badge" />
                <Heading tag={Tag.H2}>
                  {healthIndex != null ? `${healthIndex} %` : '-'}
                </Heading>
                <Heading tag={Tag.H6}>
                  <FormattedMessage
                    id="pageMyHealthHealthIndexTitle"
                    defaultMessage="Health index"
                    description="My health page health index title"
                  />
                </Heading>
              </HeroCol>
            </HeroGrid>
          </HeroSmall>
          <ContentWidth isSurface noMargins>
            <TabMenu menuId="my-health" menu={tabMenu} />
            <Gap />
            <Outlet />
          </ContentWidth>
          <LevelsModal
            isOpen={modalOpen}
            onClose={onClose}
            levels={userLevels?.data}
          />
          <MilestonePopup
            onConfirm={closeMilestonePopup}
            isOpen={showMilestonePopup}
            onAbort={() => {}}
            icon={levelIcon?.src}
          >
            <>
              <Heading tag={Tag.H3}>
                <FormattedMessage
                  id="leveledUpPopUpHeading"
                  defaultMessage="Congratulations!"
                  description="Heading text in the milestone popup"
                />
              </Heading>
              <FormattedMessage
                id="leveledUpPopUpBody"
                defaultMessage="You have reached a new level. {NewLine} Keep up the good work!"
                description="Body text in the milestone popup"
                values={{ NewLine: <br /> }}
              />
            </>
          </MilestonePopup>
        </div>
      ) : (
        <>
          <HeroSmall>
            <ProfileHeader>
              <ProfileImage>
                {profileImage ?
                  (<Img src={profileImage} alt={firstName} />) : (
                    <Icon type={IconType.User} color="grayText" size={32} />
                  )}
              </ProfileImage>
              <ProfileHeaderData>
                <Heading>
                  {firstName} {lastName}
                </Heading>
                <ProfileHeaderDataGrid>
                  <DataItem
                    description={intl.formatMessage({
                      id: 'pageProfileAgeDescription',
                      defaultMessage: 'Age',
                      description: 'Profile page age description',
                    })}
                  >
                    {dateOfBirth ? `${calculateAge(dateOfBirth)}` : null}
                  </DataItem>
                  <DataItem
                    description={intl.formatMessage({
                      id: 'pageProfileLengthDescription',
                      defaultMessage: 'Length',
                      description: 'Profile page length description',
                    })}
                  >
                    {lengthCm ? (
                      <FormattedMessage
                        id="pageProfileLengthValue"
                        defaultMessage="{value} cm"
                        description="Profile page length value"
                        values={{ value: lengthCm }}
                      />
                    ) : null}
                  </DataItem>
                  <DataItem
                    description={intl.formatMessage({
                      id: 'pageProfileWeightDescription',
                      defaultMessage: 'Weight',
                      description: 'Profile page weight description',
                    })}
                  >
                    {weightKg ? (
                      <FormattedMessage
                        id="pageProfileWeightValue"
                        defaultMessage="{value} kg"
                        description="Profile page weight value"
                        values={{ value: weightKg }}
                      />
                    ) : null}
                  </DataItem>
                  <DataItem
                    description={intl.formatMessage({
                      id: 'pageProfileBMIDescription',
                      defaultMessage: 'BMI',
                      description: 'Profile page BMI description',
                    })}
                  >
                    {weightKg && lengthCm
                      ? calculateBMI(weightKg, lengthCm).toFixed(1)
                      : null}
                  </DataItem>
                </ProfileHeaderDataGrid>
              </ProfileHeaderData>
            </ProfileHeader>
          </HeroSmall>
          <OutletWidth isSurface>
            {!isMobile &&
              <PrinterIcon onClick={downloadPdf}>
                <Svg src={printer} alt="wellr-logo" />
              </PrinterIcon>
            }
            <Outlet />
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
              <svg
                width="104"
                height="40"
                viewBox="0 0 104 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M100.549 19.7932C99.0329 19.7932 97.8707 20.2945 97.1001 21.4468V20.1189H93.9043V32.2578H97.2011V25.6436C97.2011 23.8396 98.2623 22.9126 99.7781 22.9126C100.448 22.9126 101.231 23.0629 101.774 23.3009L102.267 20.0562C101.812 19.8934 101.256 19.7932 100.549 19.7932ZM87.5374 32.2578H90.8344V14.2183H87.5374V32.2578ZM81.1705 32.2578H84.4675V14.2183H81.1705V32.2578ZM72.873 22.6119C74.3004 22.6119 75.2352 23.4762 75.5385 25.0422H70.1192C70.4099 23.5265 71.3066 22.6119 72.873 22.6119ZM72.8982 19.7932C69.235 19.7932 66.7086 22.4116 66.7086 26.2197C66.7086 30.0281 69.3234 32.571 73.0624 32.571C75.1343 32.571 76.8775 31.9949 78.2797 30.7297L76.3468 28.625C75.4501 29.3768 74.313 29.7901 73.2521 29.7901C71.6982 29.7901 70.435 29.0259 70.1066 27.2722H78.7849C78.8229 26.984 78.8481 26.5582 78.8481 26.1698C78.8481 22.3864 76.4226 19.7932 72.8982 19.7932ZM59.9314 28.6375L57.1524 20.1189H53.6783L50.9374 28.6001L48.3226 20.1189H44.8107L48.9539 32.2578H52.6931L55.3837 24.0524L58.0995 32.2578H61.8639L66.0074 20.1189H62.5588L59.9314 28.6375ZM8.56018 37.9848C8.20047 37.9848 7.83785 37.9317 7.4837 37.8223C6.20704 37.4287 5.25232 36.3701 5.00004 35.0681L1.79931 18.5704C1.42116 16.6199 2.70866 14.735 4.67516 14.3597C6.64225 13.9847 8.54262 15.2615 8.92105 17.2114L10.8419 27.114L16.761 21.3336C18.004 20.1195 19.9424 19.9555 21.3751 20.9432L29.691 26.6766L26.5415 5.86343C26.2444 3.89958 27.6089 2.06838 29.5894 1.77348C31.5708 1.47917 33.4162 2.83232 33.7135 4.79617L38.1104 33.8551C38.3253 35.2747 37.6682 36.6851 36.439 37.4429C35.2094 38.2005 33.6436 38.161 32.4547 37.3413L19.706 28.5513L11.105 36.9509C10.4163 37.6232 9.49821 37.9848 8.56018 37.9848Z"
                  fill="#A6B7BF"
                />
              </svg>
            </div>
          </OutletWidth>
        </>
      )}
    </div>
  );
};

export default HealthTabLayout;
