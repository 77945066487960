import { Marker } from '@react-google-maps/api';
import { FC } from 'react';

import goalBadge from 'assets/vectors/map-goal-marker.png';
import goalPinkBadge from 'assets/vectors/map-pink-goal-marker.png';
import goalRedBadge from 'assets/vectors/map-red-goal-marker.svg';

type Props = {
  pink?: boolean;
  red?: boolean;
  position: {
    lat: number;
    lng: number;
  };
};

const SIZE = 36;
const ANCHOR_X = SIZE / 2;
const ANCHOR_Y = ANCHOR_X / 2;

const MapGoalMarker: FC<Props> = ({ red, pink, position }) => {
  return (
    <Marker
      icon={{
        url: pink ? goalPinkBadge : red ? goalRedBadge : goalBadge,
        size: new google.maps.Size(SIZE, SIZE),
        scaledSize: new google.maps.Size(SIZE, SIZE),
        anchor: new google.maps.Point(ANCHOR_X, ANCHOR_Y),
        origin: new google.maps.Point(0, 0),
      }}
      position={position}
    />
  );
};

export default MapGoalMarker;