import { FC, ReactNode } from 'react';

import { Card } from './styles';
import { ThemeColor } from 'styles';

type Props = {
  children: ReactNode;
  padding?: boolean;
  narrow?: boolean;
  color?: ThemeColor;
  locked?: boolean;
};

const StaticCard: FC<Props> = ({ children, padding, narrow = false, color, locked }) => {
  return (
    <Card color={color} padding={padding} narrow={narrow} locked={locked}>
      {children}
    </Card>
  );
};

export default StaticCard;
