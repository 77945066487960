import { FC, Fragment, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from 'components/UI/Button';
import Heading, { Tag } from 'components/UI/Heading';
import ProgressBar from 'components/UI/ProgressBar';
import StaticCard from 'components/UI/Cards/StaticCard';
import WellrCoins from 'components/UI/WellrCoins';
import Icon, { IconType } from 'components/UI/Icon';
import IconButton from 'components/UI/IconButton';
import DropdownMenu from 'components/UI/DropdownMenu';

import { parseDate } from 'utils/date';
import { getQuizResultColor } from 'utils/quiz';
import { Divider, MediaDisplay, ThemeColor } from 'styles';
import { useLocalizedText } from 'hooks/useLocalizedText';
import {
  ContentText,
  DropdownMenuItem,
  HealthCategory,
  QuizAssessmentRef,
  QuizResult,
} from 'models';

import {
  Grid,
  Column,
  Action,
  Header,
  HeaderCol,
  ActionColumn,
  Description,
  ProgressGrid,
  DisabledTitle,
  ProgressItem,
  TestDate,
} from './styles';
import { getCategoryIcon } from 'utils/asset';

type TestStatus = {
  text: string;
  color: ThemeColor;
};

type Props = {
  category: HealthCategory | null;
  text: ContentText | null;
  wellrPoints: number;
  results?: QuizResult[];
  buttonText?: string;
  buttonActiveText?: string;
  onClick?: () => void;
  onActiveClick?: () => void;
};

const TestCard: FC<Props> = ({
  category,
  text,
  wellrPoints,
  onActiveClick,
  onClick,
  buttonText,
  buttonActiveText,
  results = [],
}) => {
  const intl = useIntl();
  const getText = useLocalizedText();

  // In progress
  const inProgress = useMemo(() => {
    if (!results.length) {
      return false;
    }
    return true;
  }, [results]);

  // Is successful
  const isSuccess = useMemo(() => {
    if (!results.length) {
      return false;
    }
    const [latest] = results;
    const level = latest.assessment.assessmentReference?.level;
    if (level === 'approved' || level === 'good') {
      return true;
    }
    return false;
  }, [results]);

  // Get status
  const getStatus = useCallback(
    (ref: QuizAssessmentRef | undefined): TestStatus => {
      return {
        text: ref ? getText(ref.title) : '-',
        color: ref ? getQuizResultColor(ref.level) : 'error',
      };
    },
    [getText]
  );

  // Dropdown menu
  const dropDownMenu: DropdownMenuItem[] = useMemo(() => {
    return [
      {
        id: 1,
        text: intl.formatMessage({
          id: 'menuRedoTest',
          defaultMessage: 'Redo the test',
          description: 'Dropdown menu item for redo test',
        }),
        onClick,
      },
    ];
  }, [onClick, intl]);

  // Content for card
  const content = useMemo(() => {
    if (!inProgress) {
      return <Description>{getText(text)}</Description>;
    }
    return (
      <ProgressGrid>
        {[results[0]].map(({ percentage, created, assessment }, i) => {
          const { text, color } = getStatus(assessment.assessmentReference);
          return (
            <ProgressItem key={`progress-${i}`}>
              <Header
              >
                {category && category.slug.current !== 'female-health' && (
                  <>
                    <Heading
                      tag={Tag.H3}>
                      {text}
                    </Heading>
                    <span>{percentage}%</span>
                  </>
                )}
              </Header>
              {category && category.slug.current !== 'female-health' && <ProgressBar progress={percentage} color={color} />}
              <TestDate>
                <FormattedMessage
                  id="lastTestedDate"
                  defaultMessage="Last tested: {date}"
                  description="Last tested date for health card"
                  values={{
                    date: parseDate(created),
                  }}
                />
              </TestDate>
            </ProgressItem>
          );
        })}
      </ProgressGrid>
    );
  }, [results, text, inProgress, getText, getStatus]);

  // Actions
  const actions = useMemo(() => {
    if (inProgress) {
      const [redoButton] = dropDownMenu;
      return (
        <Fragment>
          <Divider />
          <Action>
            <Button
              color="blue"
              background="transparent"
              onClick={isSuccess ? redoButton.onClick : onActiveClick}
            >
              {isSuccess ? redoButton.text : buttonActiveText}
            </Button>
          </Action>
        </Fragment>
      );
    }
    return (
      <ActionColumn>
        <MediaDisplay breakpoint="s" isLarger>
          <WellrCoins count={wellrPoints} />
        </MediaDisplay>
        <Button background="blue" onClick={onClick}>
          {buttonText}
        </Button>
      </ActionColumn>
    );
  }, [
    onClick,
    onActiveClick,
    dropDownMenu,
    isSuccess,
    inProgress,
    wellrPoints,
    buttonText,
    buttonActiveText,
  ]);

  // Category
  const healthCategory = useMemo(() => {
    if (!category) {
      return null;
    }
    if (inProgress) {
      return <DisabledTitle>{getText(category.title)}</DisabledTitle>;
    }
    return <Heading tag={Tag.H3}>{getText(category.title)}</Heading>;
  }, [category, inProgress, getText]);

  // Top right corner
  const rightTopCorner = useMemo(() => {
    if (isSuccess) {
      return null;
    }
    if (inProgress) {
      return (
        <DropdownMenu menu={dropDownMenu}>
          <IconButton>
            <Icon type={IconType.More} />
          </IconButton>
        </DropdownMenu>
      );
    }
    return (
      <MediaDisplay breakpoint="s">
        <WellrCoins count={wellrPoints} />
      </MediaDisplay>
    );
  }, [dropDownMenu, inProgress, isSuccess, wellrPoints]);

  const categoryIcon = useMemo(
    () => (category?.icon ? getCategoryIcon(category.icon, false) : null),
    [category?.icon]
  );

  return (
    <StaticCard>
      <Grid isActive={inProgress}>
        <Column>
          <Header>
            <HeaderCol>
              {categoryIcon && (
                <img src={categoryIcon.src} width='24' alt={categoryIcon.alt} />
              )}
              {healthCategory}
            </HeaderCol>
            <HeaderCol>{rightTopCorner}</HeaderCol>
          </Header>
          {content}
        </Column>
        {actions}
      </Grid>
    </StaticCard>
  );
};

export default TestCard;
