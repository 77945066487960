import {
  ContentImage,
  ContentSlug,
  ContentText,
  HealthCategory,
} from 'models/content/content';
import { WellrPoint } from 'models/shared/shared';
import {
  QuizAssessment,
  QuizAssessmentLevel,
  QuizDefinition,
} from 'models/quiz/quiz';
import { components } from 'generated/api';

export type CompanyReportStatistics = {
  organizationEntityId: string;
  totalPoints: number;
  totalSteps: number;
  totalMemberships: number;
  averageHealthScore: number;
  usersCompletedQuizPercentage: number;
  lastActivity: string;
  completedHealthQuizes: number;
  totalHealthQuizes: number;
};

export type CompanyReport = {
  organizationEntityStatistics: CompanyReportStatistics;
  pointsThisDay: WellrPoint[];
};

export type CompanyQuery = {
  companyId?: string;
  employeeType?: 'All' | 'Employees' | 'Managers';
  language?: string;
};

export type CompanyQuizAnswerAlternativeStatistics = {
  _key: string;
  percentageOfAnswers?: number | null;
  title?: ContentText;
};

export type CompanyQuizAverageAnswer = {
  _key: string;
  _type?: string | null;
  averagePoints?: number | null;
  maxPoints?: number;
  minPoints?: number;
  maxPointsDescription?: ContentText;
  minPointsDescription?: ContentText;
  title?: ContentText;
  answers?: string[];
  answerAlternativeStatistics?: CompanyQuizAnswerAlternativeStatistics[];
  unhideByAnswerAlternative: null | {
    questionKey: string;
    alternativeKey: string;
  };
};

export type CompanyQuizAverageResults = {
  usersCompleted?: number;
  usersTotal?: number;
  usersCompletedQuizPercentage?: number | null;
  scorePercentageAverage?: number | null;
  pointAverage?: number | null;
  quizSlug?: string;
  averageAssessment?: QuizAssessment;
  averageAnswers?: CompanyQuizAverageAnswer[];
};

export enum RolesInQuizStatistics {
  All = 'All',
  Employees = 'Employees',
  Managers = 'Managers',
}

export enum GendersInQuizStatistics {
  All = 'All',
  Unknown = 'Unknown',
  Male = 'Male',
  Female = 'Female',
  NonBinary = 'NonBinary',
}

export type AgeTypesInQuizStatistics =
  components['schemas']['AgeInQuizStatistics'];

export enum OrganizationEntities {
  UmbrellaCompany = 'UmbrellaCompany',
  Company = 'Company',
  HeadOffice = 'HeadOffice',
  Office = 'Office',
  Department = 'Department',
}

export type CompanyPulseQuizAverageResults = {
  id?: string;
  organizationEntityType?: OrganizationEntities;
  organizationEntityDescription?: string | null;
  role?: RolesInQuizStatistics;
  gender?: GendersInQuizStatistics;
  age?: AgeTypesInQuizStatistics;
  usersCompleted?: number;
  usersTotal?: number;
  usersCompletedQuizPercentage?: number | null;
  scorePercentageAverage?: number | null;
  pointAverage?: number | null;
  averageAssessment: QuizAssessment;
  averageAnswers: CompanyQuizAverageAnswer[];
};

export type CompanyQuizResult = {
  id: string;
  quizDefinition: QuizDefinition;
  averageResults: CompanyQuizAverageResults;
};

export type CompanyQuiz = {
  created: string;
  quizResults: CompanyQuizResult[];
};

export type CompanyPulseQuizListQuery = {
  language?: string;
  companyId?: string;
};

export type CompanyPulseQuizDefinition = {
  id?: string;
  slug?: ContentSlug;
  healthCategory?: HealthCategory;
  wellrPoints?: number;
  noQuestions?: number | null;
  summary?: ContentText;
  title?: ContentText;
};

export type CompanyPulseQuizListItem = {
  id: string;
  description: ContentText;
  startTime: string;
  endTime: string;
  started: boolean;
  ended: boolean;
  pulseQuizDefinition: CompanyPulseQuizDefinition;
};

export type CompanyPulseQuizQuery = {
  id: string;
  language?: string;
  companyId?: string;
};

export type CompanyPulseQuiz = {
  pulseQuizId?: string;
  startTime?: string;
  endTime?: string;
  description: ContentText;
  pulseQuizDefinition?: CompanyPulseQuizDefinition;
  averageResults?: CompanyPulseQuizAverageResults[];
  submittedCount: number;
  userCount: number;
};

export type CompanyHealthQuizQuery = {
  quizSlug: string;
  language?: string;
  fromDate?: string;
  companyId?: string;
};

export type CompanyHealthQuiz = {
  quizDefinition: {
    id?: string;
    slug?: ContentSlug;
    healthCategory?: HealthCategory;
    /** Format: int32 */
    wellrPoints?: number;
    /** Format: int32 */
    noQuestions?: number | null;
    summary?: ContentText;
    title?: ContentText;
  };
  averageResults: null | Array<{
    organizationEntityType?: OrganizationEntities;
    organizationEntityDescription?: string | null;
    role?: RolesInQuizStatistics;
    gender?: GendersInQuizStatistics;
    age?: AgeTypesInQuizStatistics;
    /** Format: int32 */
    usersCompleted?: number;
    /** Format: int32 */
    usersTotal?: number;
    /** Format: double */
    usersCompletedQuizPercentage?: number | null;
    /** Format: double */
    scorePercentageAverage?: number | null;
    /** Format: int32 */
    pointAverage?: number | null;
    resultTitle?: ContentText | null;
    color?: { hex: string };
    averageAssessment?: Array<{
      color?: { hex: string };
      level?: QuizAssessmentLevel;
      title?: ContentText;
      /** Format: double */
      percentage?: number;
    }> | null;
    averageAnswers?: Array<{
      _key: string;
      _type?: string | null;
      /** Format: double */
      averagePoints?: number | null;
      title?: ContentText;
      maxPoints?: number;
      minPoints?: number;
      maxPointsDescription?: ContentText;
      minPointsDescription?: ContentText;
      answerAlternativeStatistics?: Array<{
        _key: string;
        /** Format: double */
        percentageOfAnswers?: number | null;
        title?: ContentText;
      }> | null;
      unhideByAnswerAlternative: null | {
        questionKey: string;
        alternativeKey: string;
      };
    }> | null;
  }>;
};

export type CompanyCommunicationContent = {
  title: ContentText | null;
  contentString: ContentText | null;
  buttonText: ContentText | null;
  buttonLink: ContentText | null;
  image: ContentImage | null;
  displayToUser: boolean;
  displayToCompanyAdmin: boolean;
};

export type CompanyCommunicationData = {
  logoAssetId: string;
  companyCommunicationContent: CompanyCommunicationContent[];
};

export type CompanyCommunication = {
  company: CompanyCommunicationData | null;
  umbrellaCompany: CompanyCommunicationData | null;
};

export type CompanyList = Array<{
  description: string;
  healthQuizMinResultsForStats: number;
  id: string;
  pulseQuizMinResultsForStats: number;
}>;

export type CompanyMembershipValues = {
  companyDescription: string;
  companyId?: string;
};

export type CompanyMembershipInfo = {
  companyId: string;
  totalMemberships: number;
  membershipsWithGdprConsent: number;
  emailsWithGdprConsent: string[];
  emailsWithoutGdprConsent: string[];
};

export type CompanyValue = {
  companyId: string;
};

export type Membership = {
  membershipId: string;
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
};

export type CompanyDetails = {
  id: string;
  description: string;
  memberships: Membership[];
  healthQuizMinResultsForStats: number;
  pulseQuizMinResultsForStats: number;
  umbrellaCompanyId: string | null;
};
