// Config params
export const backendApi = process.env.REACT_APP_BACKEND_API;

export const webSocketUrl = process.env.REACT_APP_WEBSOCKET_URL || '';

export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

export const charmaApiKey = process.env.REACT_APP_CHARMA_API_KEY || '';

export const charmaBaseUrl = process.env.REACT_APP_CHARMA_API || '';
