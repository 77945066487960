import {
  addMonths,
  endOfDay,
  startOfDay,
  startOfMonth,
  subDays,
} from 'date-fns';
import {
  Activity,
  ActivityBody,
  ActivityQuery,
  ActivityToplist,
  ActivityToplistQuery,
  ActivityType,
  ActivityTypeQuery,
  ContentResponse,
  ExternalStepProvider,
} from 'models';
import { dateStringToISOString, getFirstDateOfMonth } from 'utils/date';
import { baseApi } from '../baseApi';
import { endpoints } from './endpointDefinitions';

const activityApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getActivityTypes: builder.query<ActivityType[], ActivityTypeQuery>({
      query: ({ language }) => `/activities?language=${language}`,
      transformResponse: (res: ContentResponse) => res.result,
      providesTags: ['Activity'],
    }),
    getWeeklyActivities: builder.query<Activity[], void>({
      query: () =>
        `/activity/me?from=${dateStringToISOString(
          subDays(startOfDay(new Date()), 6)
        )}&to=${dateStringToISOString(endOfDay(new Date()))}`,
      providesTags: ['Activity'],
    }),
    getMonthlyActivities: builder.query<Activity[], ActivityQuery>({
      query: ({ date }) =>
        `/activity/me?from=${dateStringToISOString(
          getFirstDateOfMonth(new Date(date))
        )}&to=${dateStringToISOString(
          startOfMonth(addMonths(new Date(date), 1))
        )}`,
      providesTags: ['Activity'],
    }),
    registerActivity: builder.mutation<void, ActivityBody>({
      query: (body) => ({
        url: '/activity/me',
        method: 'POST',
        body: {
          ...body,
          date: dateStringToISOString(body.date),
        },
      }),
      invalidatesTags: ['Activity', 'Contest'],
    }),
    deleteActivity: builder.mutation<void, string>({
      query: (activityId) => ({
        url: `/activity/me/${activityId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Activity'],
    }),
    createExternalActivitySession: builder.mutation<
      { url: string; sessionId: string },
      { provider: ExternalStepProvider }
    >({
      query: ({ provider }) => ({
        url: `/external-activity`,
        params: { provider },
        method: 'POST',
      }),
    }),
    disconnectExternalActivityProvider: builder.mutation<
      void,
      { provider: ExternalStepProvider }
    >({
      query: ({ provider }) => ({
        url: `/external-activity`,
        params: { provider },
        method: 'DELETE',
      }),
    }),
    connectExternalActivity: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/external-activity/callback-target/${id}`,
        method: 'POST',
      }),
    }),
    GetTotalStepsMyCompany: builder.query<
      ActivityToplist,
      ActivityToplistQuery
    >({
      query: ({ registeredFrom, registeredTo, page, pageSize }) => ({
        url: endpoints.getActivityState.path,
        method: endpoints.getActivityState.method,
        params: { registeredFrom, registeredTo, page, pageSize },
      }),
    }),
    GetTotalPointsMyCompany: builder.query<
      ActivityToplist,
      ActivityToplistQuery
    >({
      query: ({ registeredFrom, registeredTo, page, pageSize }) => ({
        url: endpoints.getTotalPointsState.path,
        method: endpoints.getTotalPointsState.method,
        params: { registeredFrom, registeredTo, page, pageSize },
      }),
    }),
  }),
});

export const {
  useGetActivityTypesQuery,
  useGetWeeklyActivitiesQuery,
  useGetMonthlyActivitiesQuery,
  useRegisterActivityMutation,
  useDeleteActivityMutation,
  useConnectExternalActivityMutation,
  useCreateExternalActivitySessionMutation,
  useDisconnectExternalActivityProviderMutation,
  useGetTotalStepsMyCompanyQuery,
  useGetTotalPointsMyCompanyQuery,
} = activityApi;
