import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ContestGoal, ContestGoalUnit, ContestGoalUnits, ContestTeamUser, ContestUserOut, PendingActivity, Unit } from 'models';

import StaticCard from 'components/UI/Cards/StaticCard';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';

import TopListGoalItem from 'components/fragments/TopListGoalItem';
import TopListProgressItem from 'components/fragments/TopListProgressItem';

import { TeamList } from './styles';
import Button from 'components/UI/Button';
import { Actions } from '../CompetitionStatus/styles';
import { BorderTop } from '../CompetitionTopList/styles';

type Props = {
  individualResults: boolean;
  list?: ContestTeamUser[];
  goals: ContestGoal[];
  users: ContestUserOut[];
  userId: string;
  pendingActivity?: PendingActivity[];
  unit?: ContestGoalUnits;
};

const ChallengeTopList: FC<Props> = ({
  list,
  goals,
  users,
  userId,
  individualResults,
  pendingActivity,
  unit
}) => {

  const [displayMode, setDisplayMode] = useState<'top5' | 'top10' | 'all'>('top5');

  // Check if list exist
  if (!list?.length) {
    return (
      <EmptyState iconType={IconType.Users} padding>
        <FormattedMessage
          id="competitionTopListEmptyState"
          defaultMessage="No users found"
          description="Empty state for competition top list"
        />
      </EmptyState>
    );
  }

  const [firstGoal] = goals;


  // Sort the list based on the number of completedCheckpoints
  const sortedUsers = [...list].sort((a, b) => {
    const aUser = users.find((user) => user.id === a.id);
    const bUser = users.find((user) => user.id === b.id);

    const aCompletedGoals = aUser ? aUser.completedCheckpoints.length : 0;
    const bCompletedGoals = bUser ? bUser.completedCheckpoints.length : 0;

    return bCompletedGoals - aCompletedGoals;
  });

  let usersToShow;
  let buttonText;

  if (sortedUsers.length <= 10) {
    usersToShow = displayMode === 'top5' ? sortedUsers.slice(0, 5) : sortedUsers;
    buttonText = displayMode === 'top5' ? (
      <FormattedMessage
        id="competitionTopListShowMore"
        defaultMessage="Show More"
        description="Button to show more users"
      />
    ) : (
      <FormattedMessage
        id="competitionTopListShowLess"
        defaultMessage="Show Less"
        description="Button to show less users"
      />
    );
  } else {
    switch (displayMode) {
      case 'top5':
        usersToShow = sortedUsers.slice(0, 5);
        buttonText = (
          <FormattedMessage
            id="competitionTopListShowMore"
            defaultMessage="Show More"
            description="Button to show more users"
          />
        );
        break;
      case 'top10':
        usersToShow = sortedUsers.slice(0, 10);
        buttonText = (
          <FormattedMessage
            id="competitionTopListShowAll"
            defaultMessage="Show All"
            description="Button to show all users"
          />
        );
        break;
      case 'all':
        usersToShow = sortedUsers;
        buttonText = (
          <FormattedMessage
            id="competitionTopListShowLess"
            defaultMessage="Show Less"
            description="Button to show less users"
          />
        );
        break;
      default:
        usersToShow = sortedUsers.slice(0, 5);
        buttonText = (
          <FormattedMessage
            id="competitionTopListShowMore"
            defaultMessage="Show More"
            description="Button to show more users"
          />
        );
    }
  }

  const handleButtonClick = () => {
    if (sortedUsers.length <= 10) {
      setDisplayMode(displayMode === 'top5' ? 'all' : 'top5');
    } else
      if (displayMode === 'top5') {
        setDisplayMode('top10');
      } else if (displayMode === 'top10') {
        setDisplayMode('all');
      } else {
        setDisplayMode('top5');
      }
  };

  return (
    <StaticCard>
      <TeamList>
        {usersToShow.map((item, i) => {
          const user = users.find((user) => user.id === item.id);
          if (!user) {
            return null;
          }
          let totalSteps: number;
          let progress: number | null;
          const { id, alias, profilePictureAssetId } = item;
          const isMe = id === userId;
          const showResults = individualResults || isMe;

          if (showResults) {
            const cut = user.total / firstGoal.amount;
            progress = !isNaN(cut) && isFinite(cut) ? cut * 100 : 0;
            totalSteps = item.total;
          } else {
            progress = null;
            totalSteps = 0;
          }

          const { completedGoals, completedCheckpoints } = user;

          let completedGoalsWithPending = completedGoals;

          // Add pending goal marked as completed, before aggregation
          if (firstGoal.type !== 'A_b' && unit && unit === 'Minute' && user.id === userId) {

            const goal = goals.find((goal) => {
              const goalDate = new Date(goal.start).toDateString();
              const todayDate = new Date().toDateString();
              return goalDate === todayDate;
            });

            const pendingTotalNumberOfUnits = pendingActivity?.reduce((acc, activity) => {
              return acc + activity.numberOfUnits;
            }, 0);

            if (goal && pendingActivity && pendingActivity[0] && goal.amount <= (pendingTotalNumberOfUnits ?? 0)) {
              const todayDate = new Date().toDateString();
              const alreadyCompletedToday = completedGoals.some(
                (completedGoal) => new Date(completedGoal.date).toDateString() === todayDate
              );

              if (!alreadyCompletedToday) {
                let completedGoal = { id: goal.id, date: new Date().toISOString()};
                completedGoalsWithPending = [...completedGoals, completedGoal];
              }
            }
          }

          const key = id;
          const isLast = list.length === i + 1;

          if (firstGoal.type === 'A_b') {
            return (
              <TopListProgressItem
                key={key}
                isLast={isLast}
                email={user.email}
                alias={alias}
                profilePictureAssetId={profilePictureAssetId}
                showResults={showResults}
                progress={progress}
                progressColor={isMe ? 'orangeDark' : 'blue'}
                totalSteps={totalSteps}
              />
            );
          }

          return (
            <TopListGoalItem
              key={key}
              isLast={isLast}
              email={user.email}
              alias={alias}
              profilePictureAssetId={profilePictureAssetId}
              goals={goals}
              completedGoals={completedGoalsWithPending}
              completedCheckpoints={completedCheckpoints}
            />
          );
        })}
      </TeamList>
      {sortedUsers.length > 5 && (
        <>
          {displayMode !== 'top5' && <BorderTop />}
          <Actions>
            <Button color="blue" background="transparent" onClick={handleButtonClick}>
              {buttonText}
            </Button>
          </Actions>
        </>
      )}
    </StaticCard>
  );
};

export default ChallengeTopList