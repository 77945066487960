import styled from 'styled-components';
import { breakpoints } from 'styles';

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WeekdayGrid = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4px;
  padding: 16px 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};

  @media (min-width: ${breakpoints.s}px) {
    gap: 24px;
  }
`;

type DayCircleProps = {
  isActive: boolean;
  awardedSteps: boolean;
  isProgressComplete: boolean;
};

export const DayCircle = styled.div<DayCircleProps>`
  min-width: 34px;
  height: 34px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme, awardedSteps }) =>
    theme.colors[awardedSteps ? 'white' : 'blue']};
  background-color: ${({ theme, awardedSteps, isProgressComplete }) =>
    isProgressComplete ? theme.colors[awardedSteps ? 'yellowDark' : 'white'] : theme.colors[awardedSteps ? 'blue' : 'white']};
  border: 4px solid ${({ theme }) => theme.colors.backgroundLight};
  border-radius: 50%;
  font-weight: bold;
  cursor: pointer;

  ${({ isActive, theme, isProgressComplete }) =>
    isActive &&
    `
    &::before {
      content: "";
      position: absolute;
      top: -8px;
      right: -8px;
      bottom: -8px;
      left: -8px;
      border: 4px solid ${isProgressComplete ? theme.colors.yellowDark : theme.colors.blue};
      border-radius: 40px;
    }
  `}

  @media (min-width: ${breakpoints.s}px) {
    width: 100%;
    border-radius: 40px;
  }
`;

export const Action = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.colors.borderLight};
  padding: 8px;
`;

export const Separator = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colors.borderLight};
`;

export const ContentContainer = styled.div`
padding: 16px;
`;