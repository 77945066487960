import { FC, useMemo } from 'react';
import { ContentImage, Reference, ReferenceType } from 'models';
import { ChallengeType } from 'models/timedChallengeResult/timedChallengeResult';
import Icon, { IconType } from 'components/UI/Icon';
import Heading, { Tag } from 'components/UI/Heading';
import WellrCoins from 'components/UI/WellrCoins';
import Button from 'components/UI/Button';
import ProgressBarIcon from 'components/fragments/ProgressBarIcon';
import { FormattedMessage, useIntl } from 'react-intl';
import { getImage } from 'utils/asset';
import { getRefTypeIcon } from 'utils/link';
import useIsMobile from 'hooks/useIsMobile';
import { useLocalStorage } from 'hooks/useLocalStorage';
import animationData from 'assets/animations/sparks-animation.json';

import {
  Action,
  ButtonContent,
  Card,
  CardContent,
  CardText,
  ImageView,
  CardTitle,
  IconContainer,
  StyledLottie,
  Description,
  ProgressContainer,
  Row,
  ImageContainer,
  CardContainer,
  CardHeader,
  CardTextHeader,
  CardRowContainer,
} from './styles';

type Props = {
  image?: ContentImage | null;
  title: string;
  wellrPoints: number;
  challengeType: ChallengeType;
  description?: string;
  reference?: Reference;
  progress?: number;
  amountToComplete?: number;
  goalCompleted: boolean;
  onClickContent: (type: ReferenceType, slug: string) => () => void;
  onClickComplete: () => void;
  onClickProgress: () => void;
};

const TimedChallengeCard: FC<Props> = ({
  image,
  title,
  wellrPoints,
  challengeType,
  description,
  reference,
  progress,
  amountToComplete,
  goalCompleted,
  onClickContent,
  onClickComplete,
  onClickProgress,
}) => {
  // Hooks
  const intl = useIntl();
  const isMobile = useIsMobile();
  const [unlocked] = useLocalStorage<boolean>('unlocked', false);

  // Memoized values
  const isContentGoal = useMemo(
    () => challengeType === ChallengeType.Content,
    [challengeType]
  );
  const isPushGoal = useMemo(
    () => challengeType === ChallengeType.Push,
    [challengeType]
  );

  const arrowIcon = useMemo(
    () =>
      challengeType === ChallengeType.Content ? (
        <Icon type={IconType.Arrow} color='blue' />
      ) : null,
    [challengeType]
  );

  const iconRefType = useMemo(() => {
    if (reference && challengeType === ChallengeType.Content) {
      return getRefTypeIcon('article');
    }
    if (challengeType === ChallengeType.Activity.toLowerCase()) {
      return getRefTypeIcon('exercise');
    }
    return getRefTypeIcon(reference?._type ?? 'article');
  }, [reference, challengeType]);

  const imageSrc = useMemo(() => image && getImage(image), [image]);
  const shouldDisplayImage = useMemo(() => image, [image]);

  const handleOnClickCompletePushGoal = () => {
    if ((progress ?? 0) < 1) {
      onClickComplete();
      return;
    }
    onClickProgress();
  };

  return (
    <Card inverted={false}>
      <CardRowContainer>
        {shouldDisplayImage && (
          <ImageContainer>
            <ImageView src={imageSrc!.src} alt={imageSrc!.alt} />
          </ImageContainer>
        )}
        <CardContainer>
          <CardContent center={!shouldDisplayImage}>
            <CardHeader>
              <CardTitle>
                {intl.formatMessage({
                  id: 'challengeCardDailyChallengeTitle',
                  defaultMessage: 'Daily Challenge',
                  description: 'Daily Challenge title',
                })}
              </CardTitle>
            </CardHeader>
            <Row>
              <CardText>
                <CardTextHeader>
                  <Heading tag={Tag.H3}>{title}</Heading>
                  {(!image || isMobile) && (
                    <IconContainer>
                      <Icon type={iconRefType} color="blue" />
                    </IconContainer>
                  )}
                </CardTextHeader>
                <Description>{description}</Description>
              </CardText>
            </Row>
          </CardContent>
        </CardContainer>
      </CardRowContainer>
      <CardRowContainer>
        <ProgressContainer>
          {goalCompleted || (progress && progress >= 100) ? (
            <StyledLottie loop={false} animationData={animationData} />
          ) : null}
          <ProgressBarIcon
            progress={progress ?? 0}
            color="goldBright"
            wellrPoints={wellrPoints}
            height={33}
            icon={!unlocked ? IconType.LockClosed : undefined}
            amountToComplete={amountToComplete}
            contentGoal={isContentGoal}
            pushGoal={isPushGoal}
            goalCompleted={goalCompleted}
            onClickComplete={onClickComplete}
            textProgressBar={
              unlocked && !goalCompleted
                ? intl.formatMessage({
                  id: 'pageOverviewProgressTextDone',
                  defaultMessage: 'Tryck här för att slutföra!',
                  description: 'Progress text',
                })
                : undefined
            }
            rounded
          />
        </ProgressContainer>
      </CardRowContainer>

      {isContentGoal && (
        <Action>
          <Button
            color="blue"
            background="transparent"
            onClick={onClickContent(
              reference?._type as ReferenceType,
              reference?.slug.current as string
            )}
          >
            <ButtonContent>
              <FormattedMessage
                id="pageOverviewReadHereButton"
                defaultMessage="Read here"
                description="Read here button text"
              />
              {arrowIcon}
            </ButtonContent>
          </Button>
        </Action>
      )}
      {isPushGoal && !goalCompleted && (
        <Action>
          <Button
            color="blue"
            background="transparent"
            onClick={handleOnClickCompletePushGoal}
          >
            {amountToComplete && amountToComplete > 1 ? (
              <ButtonContent>
                <FormattedMessage
                  id="pageOverviewDailyChallengePushGoal"
                  defaultMessage="Gå vidare till nästa steg!"
                  description="Progress text"
                />
                {arrowIcon}
              </ButtonContent>
            ) : (
              <ButtonContent>
                <FormattedMessage
                  id="pageOverviewProgressTextDone"
                  defaultMessage="Tryck här för att slutföra!"
                  description="Progress text"
                />
                {arrowIcon}
              </ButtonContent>
            )}
          </Button>
        </Action>
      )}
    </Card>
  );
};

export default TimedChallengeCard;
