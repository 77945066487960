import styled from 'styled-components';

type CardProps = {
  padding?: boolean;
  narrow?: boolean;
  locked?: boolean;
};

export const Card = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.colors.backgroundLight};
  padding: ${({ padding }) => (padding ? '16px' : '0')};
  font-size: 16px;
  border-radius: 16px;
  min-height: ${({ narrow }) => (narrow ? 'auto' : '64px')};
  ${({ locked }) => locked && 'opacity: 50%; pointer-events: none;'}
`;
