import * as dateFns from 'date-fns';
import * as Locales from 'date-fns/locale';
import { LanguageCode } from 'models';

export function getDateFnsLocale(languageCode?: LanguageCode) {
  if (languageCode === 'no') {
    return Locales.nn;
  }
  if (languageCode && languageCode !== 'en') {
    return Locales[languageCode];
  }
}

export function formatDate(date: Date = new Date()) {
  return dateFns.format(date, 'yyyy-MM-dd');
}

export function formatDateTime(date: Date = new Date()) {
  return dateFns.format(date, 'yyyy-MM-dd HH:mm');
}

export function formatReadableDateTime(date: Date = new Date()) {
  if (dateFns.isToday(date)) {
    return dateFns.format(date, 'HH:mm');
  }
  if (dateFns.isThisWeek(date)) {
    return dateFns.format(date, 'iii HH:mm');
  }
  return dateFns.format(date, 'd MMM HH:mm');
}

export function formatReadableDateTimeInclYear(date: Date = new Date()) {
  if (dateFns.isToday(date)) {
    return dateFns.format(date, 'HH:mm');
  }
  if (dateFns.isThisWeek(date)) {
    return dateFns.format(date, 'iii HH:mm');
  }
  return dateFns.format(date, 'd MMM yyyy');
}

export function getDateTime(date: Date = new Date()) {
  return dateFns.format(date, "yyyy-MM-dd'T'HH:mm");
}

export function parseDate(date: string) {
  return formatDate(new Date(date));
}

export function getPastDateByDays(days: number, date: Date = new Date()) {
  return formatDate(dateFns.subDays(date, days));
}

export function getFirstDateOfWeek(date = new Date()): Date {
  const day = date.getDay();
  const adjust = day === 0 ? -6 : 1; // Adjust when sunday
  const first = date.getDate() - day + adjust;
  return new Date(date.setDate(first));
}

export function getLastDateOfWeek(date: Date = new Date()) {
  const first = getFirstDateOfWeek(date);
  return getFutureDateByDays(6, first);
}

export function getFirstDateOfMonth(date: Date = new Date()) {
  return dateFns.startOfMonth(date);
}

export function getLastDateOfMonth(date: Date = new Date()) {
  return dateFns.endOfMonth(date);
}

export function getFutureDateByDays(days: number, date: Date = new Date()) {
  return dateFns.addDays(date, days);
}

export function getShortDateDay(date: Date, locale: string = 'en') {
  return new Intl.DateTimeFormat(locale, { weekday: 'narrow' }).format(date);
}

export function calculateAge(dateOfBirth: string): number {
  return dateFns.differenceInYears(new Date(), new Date(dateOfBirth));
}

export function isSameDay(leftDate: Date, rightDate: Date) {
  return dateFns.isSameDay(leftDate, rightDate);
}

export function getStartOfDay(date: string) {
  const parsed = dateFns.parseISO(date);
  return dateFns.startOfDay(parsed);
}

export function isDate(value: string): boolean {
  return dateFns.isValid(new Date(value));
}

export function getTimezoneOffset() {
  return new Date().getTimezoneOffset();
}

export const toUTCString = (localDatetime: string | number | Date) => {
  const date = new Date(localDatetime);
  return date.toISOString();
};

export function isBefore(value: string) {
  return dateFns.isBefore(new Date(), new Date(value));
}

export function isAfter(value: string) {
  return dateFns.isAfter(new Date(), new Date(value));
}

export function getDateDistance(date: string, languageCode?: LanguageCode) {
  if (!date) {
    return date;
  }
  return dateFns.formatDistanceToNow(new Date(date), {
    addSuffix: true,
    locale: getDateFnsLocale(languageCode),
  });
}

export function dateStringToISOString(date: string | Date) {
  return new Date(date).toISOString();
}
