import {
  getEndpointURL,
  WellrEndpointDefinition,
  WellrEndpointPath,
} from 'utils/api';

/** Complete exercise description pair */
const completeExerciseDescriptionPairPath: WellrEndpointPath =
  '/api/progress/exercise-program/{exerciseProgramSlug}/exercise';
const completeExerciseDescriptionPairMethod = 'post';
type CompleteExerciseDescriptionPair = WellrEndpointDefinition<
  typeof completeExerciseDescriptionPairPath,
  typeof completeExerciseDescriptionPairMethod
>;

export type CompleteExerciseDescriptionPairType = {
  result: CompleteExerciseDescriptionPair['responseBody'];
  queryArgs: CompleteExerciseDescriptionPair['request']['params']['path'] &
  Required<CompleteExerciseDescriptionPair['request']['params']['query']>;
};

/** Delete exercise description pair */
const deleteExerciseDescriptionPairPath: WellrEndpointPath =
  '/api/progress/exercise-program/{exerciseProgramSlug}/exercise';
const deleteExerciseDescriptionPairMethod = 'delete';
type DeleteExerciseDescriptionPair = WellrEndpointDefinition<
  typeof completeExerciseDescriptionPairPath,
  typeof completeExerciseDescriptionPairMethod
>;

export type DeleteExerciseDescriptionPairType = {
  result: DeleteExerciseDescriptionPair['responseBody'];
  queryArgs: DeleteExerciseDescriptionPair['request']['params']['path'] &
  Required<DeleteExerciseDescriptionPair['request']['params']['query']>;
};

/** Complete exercise description pair */
const completeExerciseProgramStepPath: WellrEndpointPath =
  '/api/progress/exercise-program/{exerciseProgramSlug}/step';
const completeExerciseProgramStepMethod = 'post';
type CompleteExerciseProgramStep = WellrEndpointDefinition<
  typeof completeExerciseProgramStepPath,
  typeof completeExerciseProgramStepMethod
>;

export type CompleteExerciseProgramStepType = {
  result: CompleteExerciseProgramStep['responseBody'];
  queryArgs: CompleteExerciseProgramStep['request']['params']['path'] &
  Required<CompleteExerciseProgramStep['request']['params']['query']>;
};

/** Delete exercise description pair */
const deleteExerciseProgramStepPath: WellrEndpointPath =
  '/api/progress/exercise-program/{exerciseProgramSlug}/step';
const deleteExerciseProgramStepMethod = 'delete';
type DeleteExerciseProgramStep = WellrEndpointDefinition<
  typeof deleteExerciseProgramStepPath,
  typeof deleteExerciseProgramStepMethod
>;

export type DeleteExerciseProgramStepType = {
  result: DeleteExerciseProgramStep['responseBody'];
  queryArgs: DeleteExerciseProgramStep['request']['params']['path'] &
  Required<DeleteExerciseProgramStep['request']['params']['query']>;
};

/** Get completed description pairs / program steps */
const getCompletedExerciseEntitiesPath: WellrEndpointPath =
  '/api/progress/exercise-program/{exerciseProgramSlug}';
const getCompletedExerciseEntitiesMethod = 'get';
type GetCompletedExerciseEntities = WellrEndpointDefinition<
  typeof getCompletedExerciseEntitiesPath,
  typeof getCompletedExerciseEntitiesMethod
>;

export type GetCompletedExerciseEntitiesType = {
  result: GetCompletedExerciseEntities['responseBody'];
  queryArgs: GetCompletedExerciseEntities['request']['params']['path'];
};

/** Complete diet recipe */
const completeDietRecipePath: WellrEndpointPath =
  '/api/progress/diet/{dietSlug}';
const completeDietRecipeMethod = 'post';
type CompleteDietRecipe = WellrEndpointDefinition<
  typeof completeDietRecipePath,
  typeof completeDietRecipeMethod
>;

export type CompleteDietRecipeType = {
  result: CompleteDietRecipe['responseBody'];
  queryArgs: CompleteDietRecipe['request']['params']['path'] &
  Required<CompleteDietRecipe['request']['params']['query']>;
};

/** Delete diet recipe */
const uncompleteDietRecipePath: WellrEndpointPath =
  '/api/progress/diet/{dietSlug}';
const uncompleteDietRecipeMethod = 'delete';
type UncompleteDietRecipe = WellrEndpointDefinition<
  typeof uncompleteDietRecipePath,
  typeof uncompleteDietRecipeMethod
>;

export type UncompleteDietRecipeType = {
  result: UncompleteDietRecipe['responseBody'];
  queryArgs: UncompleteDietRecipe['request']['params']['path'] &
  Required<UncompleteDietRecipe['request']['params']['query']>;
};

/** Get completed diet recipes */
const getCompletedDietRecipesPath: WellrEndpointPath =
  '/api/progress/diet/{dietSlug}';
const getCompletedDietRecipesMethod = 'get';
type GetCompletedDietcRecipes = WellrEndpointDefinition<
  typeof getCompletedDietRecipesPath,
  typeof getCompletedDietRecipesMethod
>;

export type GetCompletedDietcRecipesType = {
  result: GetCompletedDietcRecipes['responseBody'];
  queryArgs: GetCompletedDietcRecipes['request']['params']['path'];
};

/** Search content */
const searchContentPath: WellrEndpointPath = '/api/content/search';
const searchContentMethod = 'get';
type SearchContent = WellrEndpointDefinition<
  typeof searchContentPath,
  typeof searchContentMethod
>;

export type SearchContentType = {
  result: SearchContent['responseBody'];
  queryArgs: SearchContent['request']['params']['query'];
};

/** Search by slug **/
const searchBySlugPath: WellrEndpointPath = '/api/content/search/{slug}';
const searchBySlugMethod = 'get';
type SearchBySlugContent = WellrEndpointDefinition<
  typeof searchBySlugPath,
  typeof searchBySlugMethod
>;

export type SearchBySlugType = {
  result: SearchBySlugContent['responseBody']
  queryArgs: SearchBySlugContent['request']['params']['path'];
};

export const endpoints = {
  completeExerciseDescriptionPair: {
    path: (exerciseProgramSlug: string) =>
      getEndpointURL(completeExerciseDescriptionPairPath, {
        exerciseProgramSlug,
      }),
    method: completeExerciseDescriptionPairMethod.toUpperCase(),
  },
  deleteExerciseDescriptionPair: {
    path: (exerciseProgramSlug: string) =>
      getEndpointURL(deleteExerciseDescriptionPairPath, {
        exerciseProgramSlug,
      }),
    method: deleteExerciseDescriptionPairMethod.toUpperCase(),
  },
  completeExerciseProgramStep: {
    path: (exerciseProgramSlug: string) =>
      getEndpointURL(completeExerciseProgramStepPath, {
        exerciseProgramSlug,
      }),
    method: completeExerciseProgramStepMethod.toUpperCase(),
  },
  deleteExerciseProgramStep: {
    path: (exerciseProgramSlug: string) =>
      getEndpointURL(deleteExerciseProgramStepPath, {
        exerciseProgramSlug,
      }),
    method: deleteExerciseProgramStepMethod.toUpperCase(),
  },
  getCompletedExerciseEntities: {
    path: (exerciseProgramSlug: string) =>
      getEndpointURL(getCompletedExerciseEntitiesPath, {
        exerciseProgramSlug,
      }),
    method: getCompletedExerciseEntitiesMethod.toUpperCase(),
  },
  completeDietRecipe: {
    path: (dietSlug: string) =>
      getEndpointURL(completeDietRecipePath, { dietSlug }),
    method: completeDietRecipeMethod.toUpperCase(),
  },
  uncompleteDietRecipe: {
    path: (dietSlug: string) =>
      getEndpointURL(uncompleteDietRecipePath, { dietSlug }),
    method: uncompleteDietRecipeMethod.toUpperCase(),
  },
  getCompletedDietRecipes: {
    path: (dietSlug: string) =>
      getEndpointURL(getCompletedDietRecipesPath, { dietSlug }),
    method: getCompletedDietRecipesMethod.toUpperCase(),
  },
  searchContent: {
    path: getEndpointURL(searchContentPath),
    method: searchContentMethod.toUpperCase(),
  },
  searchContentBySlug: {
    path: (slug: string) =>
      getEndpointURL(searchBySlugPath, { slug }),
    method: searchBySlugMethod.toUpperCase(),
  },
} as const;
