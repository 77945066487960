import { FC, useCallback, useEffect } from 'react'
import { ButtonContainer, Container, ContentContainer, ContentTextContainer, HeadingTitle, StyledLottie } from './styles'
import { localizationSelector, useContentSearchBySlugQuery, useCreateQuizMutation, useGetMembershipSettingsQuery, useUpdateMembershipSettingsMutation } from 'store';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { setActiveOnboardingStep } from 'store/onboardingState/onboardingState';
import { MembershipSettings } from 'models';
import { selectUser, setUser } from 'store/userState/userState';
import lottie from 'assets/animations/onboarding-page2-animation.json';
import RichText from '../RichText';
import Button from 'components/UI/Button';
import * as routes from 'router/routes';


const OnboardingStep2: FC = () => {
  const { language } = useAppSelector(localizationSelector);
  const { data } = useContentSearchBySlugQuery({ slug: 'onboarding-step-2' });
  const user = useAppSelector(selectUser);
  const getText = useLocalizedText();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const settings = useGetMembershipSettingsQuery();
  const [updateSettings] = useUpdateMembershipSettingsMutation();
  const [createQiuz] = useCreateQuizMutation();

  useEffect(() => {
    if (!settings.data?.hasCompletedOnboarding) {
      if (user !== null) {
        dispatch(setUser({
          ...user,
          membership: {
            ...user.membership,
            hasCompletedOnboarding: true
          }
        }));
      }
      setHasCompletedOnboarding('hasCompletedOnboarding')(true);
    }
    dispatch(setActiveOnboardingStep(2));
  }, [dispatch, settings.data]);


  const setHasCompletedOnboarding = useCallback(
    (key: keyof MembershipSettings) => (active: boolean) => {
      if (settings.data) {
        updateSettings({ ...settings.data, [key]: active });
      }
    },
    [updateSettings, settings]
  );

  const handleNext = useCallback(async () => {
    const overviewQuiz = 'overview-health';
    await createQiuz({ language: language?.languageCode, slug: overviewQuiz })

    const state = { from: location, quizType: 'overview' };
    navigate(routes.QUIZ + `/overview-health`, { state });
  }, [createQiuz, language, location, navigate]);

  if (!data?.length || !data[0]?.content) {
    return null;
  }
  const { content, title } = data[0];

  return (
    <Container>
      <StyledLottie
        loop={true}
        animationData={lottie}
      />
      <ContentContainer>
        <ContentTextContainer>
          <HeadingTitle>
            {getText(title)}
          </HeadingTitle>
          <RichText>{content}</RichText>
        </ContentTextContainer>

        <ButtonContainer>
          <Button
            size="large"
            color="white"
            background="blue"
            border="backgroundDark"
            rounded
            width='100%'
            onClick={() => { handleNext() }}
          >
            <FormattedMessage
              id={'startEvaluation'}
              defaultMessage="Next step"
              description="Button to next step"
            />
          </Button>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  )
}

export default OnboardingStep2;