import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import { MediaDisplay } from 'styles';
import { QuizQuestion, QuizQuestionActive } from 'models';
import {
  localizationSelector,
  useAnswerPulseQuizQuestionMutation,
  useSubmitPulseQuizMutation,
  useGetPulseQuizQuery,
} from 'store';
import { useQuery } from 'hooks/useQuery';
import { useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { getActiveQuestionById } from 'utils/quiz';
import { addOrRemoveFromArray } from 'utils/array';
import { getCategoryIcon } from 'utils/asset';
import * as routes from 'router/routes';

import IconButton from 'components/UI/IconButton';
import SliderField from 'components/UI/SliderField';
import QuizAlternativeCard from 'components/cards/QuizAlternativeCard';
import BackgroundCover from 'components/UI/BackgroundCover';
import EmptyState from 'components/UI/EmptyState';
import Icon, { IconType } from 'components/UI/Icon';
import Loader from 'components/UI/Loader';

import {
  AlternativeGrid,
  ProgressLine,
  QuizHeader,
  QuizBody,
  QuestionContainer,
  QuestionHeader,
  QuestionText,
  HeaderCenterCol,
  HeaderLeftCol,
  HeaderRightCol,
  NavButtons,
  NavButton,
  NavText,
  Content,
  HealthCategory,
} from './styles';
import QuizResults from '../QuizResults';

const Quiz: FC = () => {
  // Hooks
  const navigate = useNavigate();
  const getText = useLocalizedText();
  const { pulseQuizInstanceId } = useParams();
  const [questionId] = useQuery('questionId') ?? '0';
  const { language } = useAppSelector(localizationSelector);
  const quiz = useGetPulseQuizQuery({
    pulseQuizInstanceId,
    language: language?.languageCode,
  });
  const [answerQuestion, answerResult] = useAnswerPulseQuizQuestionMutation();
  const [submitQuiz, submitResult] = useSubmitPulseQuizMutation();

  const [isQuestionSet, setIsQuestionSet] = useState(false);
  // const [questionSetIndex, setQuestionSetIndex] = useState( questionId ? parseInt(questionId) : 0);

  // Data
  const [answerSubmitted, setAnswerSubmitted] = useState<string[]>([]);
  useEffect(() => {
    setAnswerSubmitted([]);
  }, [questionId]);

  useEffect(() => {
    if (isQuestionSet &&
      (questionId === undefined || questionId === null)) {
      navigate(`${routes.PULSE_QUIZ}/${pulseQuizInstanceId}?questionId=0`);
    }
  }, [questionId, navigate, pulseQuizInstanceId]);

  const { data, isLoading } = useMemo(() => {
    return {
      data: answerResult.data || quiz.data || null,
      isLoading: submitResult.isLoading || quiz.isLoading,
      isError: submitResult.isError || answerResult.isError || quiz.isError,
      error: submitResult.error || answerResult.error || quiz.error,
    };
  }, [submitResult, answerResult, quiz]);

  function addAnswer(id: string) {
    setAnswerSubmitted((prevIds) => {
      if (prevIds.indexOf(id) === -1) {
        return prevIds.concat(id);
      }
      return prevIds;
    });
  }

  // Active data
  const activeData = useMemo<QuizQuestionActive | null>(() => {
    if (!quiz.data) {
      return null;
    }

    if (quiz.data.progress.questions !== undefined) {
      return getActiveQuestionById(quiz.data.progress.questions, questionId, false);
    }

    setIsQuestionSet(true);
    return getActiveQuestionById(quiz.data.progress.questionSets, questionId, true, Number(questionId));

  }, [quiz.data, questionId]);

  const defaultResponse = useMemo(() => {
    if (!activeData) {
      return undefined;
    }

    const question = Array.isArray(activeData.question)
      ? activeData.question[0]
      : activeData.question;

    if (question.type !== 'sliderQuestion') {
      return undefined;
    }

    const { maxPoints } = question;
    return Math.round(maxPoints.value / 2);
  }, [activeData, isQuestionSet, questionId]);

  const defaultResponseQuestion = useCallback((question: QuizQuestion) => {
    if (question.type !== 'sliderQuestion') {
      return undefined;
    }

    const { maxPoints } = question;
    return Math.round(maxPoints.value / 2);
  }, []);


  // Callbacks
  const onSetAnswer = useCallback(async (
    question: QuizQuestion,
    points: number,
    alternativeId?: string,
    onSubmitted?: () => void) => {
    const { id, type, multipleAnswersAllowed } = question;
    if (type === 'alternativeQuestion' && alternativeId) {
      await answerQuestion({
        pulseQuizInstanceId,
        answer: {
          questionId: id,
          alternativeIds: multipleAnswersAllowed
            ? addOrRemoveFromArray(question.answers, alternativeId)
            : [alternativeId],
          points,
        },
        language: language?.languageCode,
      }).unwrap();
    }
    if (type === 'sliderQuestion') {
      await answerQuestion({
        pulseQuizInstanceId,
        answer: {
          questionId: id,
          points,
        },
        language: language?.languageCode,
      }).unwrap();
    }
    addAnswer(id);
  },
    [answerQuestion, pulseQuizInstanceId, language]
  );

  // Set question
  const onSetQuestion = useCallback(
    (id?: string | null) => {
      if (id) {
        navigate(
          `${routes.PULSE_QUIZ}/${pulseQuizInstanceId}?questionId=${id}`
        );
      }
    },
    [navigate, pulseQuizInstanceId]
  );

  // Previous question
  const onSetPrev = useCallback(
    () => onSetQuestion(activeData?.prevId),
    [onSetQuestion, activeData]
  );

  // Next question
  const onSetNext = useCallback(async () => {
    if (defaultResponse !== undefined) {
      const answerCallback = !activeData?.nextId
        ? () => {
          submitQuiz({ pulseQuizInstanceId, language: language?.languageCode, isQuestionSetPulseQuiz: isQuestionSet });
        }
        : undefined;

      if (!activeData || !data) {
        return null;
      }
      const { question } = activeData;

      if (isQuestionSet) {
        const questionSet = activeData?.question as QuizQuestion[];
        for (const question of questionSet) {
          if (answerSubmitted.includes(question.id)) {
            continue;
          }
          await onSetAnswer(
            question,
            defaultResponseQuestion(question) as number,
            undefined,
            answerCallback
          );
        }
      }
      else {
        const regularQuestion = question as QuizQuestion;
        if (!answerSubmitted.includes(regularQuestion.id)) {
          await onSetAnswer(
            activeData?.question as QuizQuestion,
            defaultResponseQuestion(question as QuizQuestion) as number,
            undefined,
            answerCallback
          );
        }
      }

      if (!answerCallback && activeData?.nextId) {
        onSetQuestion(activeData?.nextId);
        return;
      }
    }
    if (!activeData?.nextId) {
      return submitQuiz({ pulseQuizInstanceId, language: language?.languageCode, isQuestionSetPulseQuiz: isQuestionSet });
    }

    if (isQuestionSet) {
      const currentSet = quiz.data?.progress.questionSets[Number(questionId) ?? 0];
      if (!currentSet) return;

      if (Number(questionId) + 1 < (quiz.data?.progress.noQuestions ?? 0)) {
        onSetQuestion(activeData?.nextId);
      }
    }
  }, [defaultResponse, answerSubmitted, activeData, isQuestionSet, data, submitQuiz, pulseQuizInstanceId, language?.languageCode, onSetAnswer, defaultResponseQuestion, onSetQuestion, quiz.data?.progress.questionSets, quiz.data?.progress.noQuestions, questionId]);

  // Close quiz
  const onClose = useCallback(() => navigate(routes.TESTS), [navigate]);

  // Active question
  const renderActiveQuestion = useMemo(() => {
    if (!activeData || !data) {
      return null;
    }

    const { count, question } = activeData;
    const renderQuestion = (question: QuizQuestion) => {
      const { minPoints, maxPoints } = question;
      return (
        <div key={question.id}>
          {!isQuestionSet && (
            <QuestionHeader>
              <FormattedMessage
                id="pageQuizQuestionCount"
                defaultMessage="Question {count}/{total}"
                description="Question count for quiz"
                values={{
                  count,
                  total: data.progress.noQuestions,
                }}
              />
            </QuestionHeader>
          )}
          <QuestionText>{getText(question.text)}</QuestionText>
          {question.type === 'alternativeQuestion' && (
            <AlternativeGrid>
              {question.alternatives.map((alt) => (
                <QuizAlternativeCard
                  key={alt.id}
                  text={getText(alt.text)}
                  isActive={question.answers.includes(alt.id)}
                  hasMultipleAnswers={question.multipleAnswersAllowed}
                  onClick={() => onSetAnswer(question, alt.points, alt.id)}
                />
              ))}
            </AlternativeGrid>
          )}
          {question.type === 'sliderQuestion' && (
            <SliderField
              minValue={minPoints.value}
              maxValue={maxPoints.value}
              minText={getText(minPoints.description)}
              maxText={getText(maxPoints.description)}
              onChange={(points) => onSetAnswer(question, points)}
              defaultValue={defaultResponseQuestion(question)}
            />
          )}
        </div>
      );
    };

    if (isQuestionSet && activeData) {
      return (
        <div>
          <QuestionHeader>
            <FormattedMessage
              id="pageQuizQuestionsCount"
              defaultMessage="Questions {count}/{total}"
              description="Question count for quiz"
              values={{
                count,
                total: quiz.data?.progress.noQuestions,
              }}
            />
          </QuestionHeader>
          {Array.isArray(activeData.question) ? activeData.question.map((question: QuizQuestion,) => renderQuestion(question)) : null}
        </div>
      )
    }

    return (
      <div>
        {renderQuestion(question as QuizQuestion)}
      </div>
    );
  }, [activeData, data, isQuestionSet, getText, defaultResponseQuestion, onSetAnswer, quiz.data?.progress.noQuestions]);

  // Health category
  const healthCategory = useMemo(() => {
    if (!data) {
      return null;
    }
    const { healthCategory } = data.quizDefinition;
    if (!healthCategory) {
      return null;
    }
    const { title, icon } = healthCategory;
    const img = getCategoryIcon(icon, true);
    return (
      <Fragment>
        {img && <img src={img.src} alt={img.alt} color="white" />}
        {getText(title)}
      </Fragment>
    );
  }, [getText, data]);

  // Progress bar
  const progressBar = useMemo(() => {
    if (!data || !activeData) {
      return null;
    }
    const { index } = activeData;
    const noQuestions = quiz.data?.progress?.noQuestions;
    return Array.from(Array(noQuestions).keys()).map((i) => (
      <ProgressLine key={i} isActive={i === index} />
    ));
  }, [activeData, data, quiz.data?.progress?.noQuestions]);

  // Content
  const content = useMemo(() => {
    // Loading
    if (isLoading) {
      return <Loader padding />;
    }

    // No data
    if (!data) {
      return (
        <EmptyState iconType={IconType.Health} padding inverted>
          <FormattedMessage
            id="pageQuizEmptyState"
            defaultMessage="Quiz not found"
            description="Empty state for quiz"
          />
        </EmptyState>
      );
    }

    return (
      <AnimatePresence mode="wait">
        <QuestionContainer
          key={questionId}
          transition={{ duration: 0.2 }}
          initial={{ y: 32, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -32, opacity: 0 }}
        >
          {renderActiveQuestion}
        </QuestionContainer>
      </AnimatePresence>
    );
  }, [data, isLoading, isQuestionSet, questionId, renderActiveQuestion]);

  // Left nav
  const leftNav = useMemo(() => {
    if (!activeData?.prevId) {
      return null;
    }
    return (
      <IconButton onClick={onSetPrev}>
        <NavButton left>
          <Icon type={IconType.Arrow} color="white" />
        </NavButton>
      </IconButton>
    );
  }, [onSetPrev, activeData]);

  // Right nav
  const rightNav = useMemo(() => {
    if (isLoading || !activeData) {
      return null;
    }
    return (
      <IconButton onClick={onSetNext}>
        <NavButton>
          {activeData.nextId ? (
            <Icon type={IconType.Arrow} color="white" />
          ) : (
            <NavText>
              <FormattedMessage
                id="pageQuizFinish"
                defaultMessage="Finish"
                description="Finish button for quiz"
              />
            </NavText>
          )}
        </NavButton>
      </IconButton>
    );
  }, [activeData, isLoading, onSetNext]);

  // Redirect to results
  if (submitResult.isSuccess) {
    return submitResult.data.assessmentResult == null ? (
      <Navigate to={routes.TESTS} />
    ) : (
      <QuizResults pulseQuizResult={submitResult.data.assessmentResult} />
    );
  }

  return (
    <BackgroundCover padding>
      <QuizHeader>
        <HeaderLeftCol>
          <HealthCategory>{healthCategory}</HealthCategory>
          <MediaDisplay breakpoint="m">
            <IconButton onClick={onClose} padding>
              <Icon type={IconType.Close} color="white" />
            </IconButton>
          </MediaDisplay>
        </HeaderLeftCol>
        <HeaderCenterCol>{progressBar}</HeaderCenterCol>
        <HeaderRightCol>
          <MediaDisplay breakpoint="m" isLarger>
            <IconButton onClick={onClose} padding>
              <Icon type={IconType.Close} color="white" />
            </IconButton>
          </MediaDisplay>
        </HeaderRightCol>
      </QuizHeader>
      <QuizBody>
        <Content>{content}</Content>
        <NavButtons>
          <div>{leftNav}</div>
          {rightNav}
        </NavButtons>
      </QuizBody>
    </BackgroundCover>
  );
};

export default Quiz;
