import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';
import { CoverContent, Description } from 'styles';
import { useLanguageFromQuery } from 'hooks/useLanguageFromQuery';
import { useDeleteUserInviteMutation } from 'store';

import * as routes from 'router/routes';

import toast from 'react-hot-toast';
import Heading, { Tag } from 'components/UI/Heading';
import BackgroundCover from 'components/UI/BackgroundCover';
import Loader from 'components/UI/Loader';
import CoverHeader from 'components/UI/CoverHeader';

import RemoveRegisterInviteForm from 'components/forms/RemoveRegisterInviteForm';
import ConfirmActionPopup from 'components/popups/ConfirmActionPopup';
import { RemoveInviteUserValues } from 'models';

const RemoveRegisterInvite: FC = () => {
  // States
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [inviteToDelete, setInviteToDelete] = useState<RemoveInviteUserValues | null>(null);

  // Hooks
  const intl = useIntl();
  const [deleteUserInvite, { isLoading, isSuccess }] = useDeleteUserInviteMutation();
  const { languageLoaded } = useLanguageFromQuery();

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'removeRegisterInviteSuccess',
          defaultMessage: 'Registration removed successfully. If this was a mistake or you want to register again, contact your administrator or email us at contact@wellr.se.',
          description: 'Remove register invite success message"',
        }),
        {
          duration: 15000,
        }
      );
    }
  }, [intl, isSuccess]);

  const handleSubmit = (data: RemoveInviteUserValues) => {
    setInviteToDelete({ secretCode: data.secretCode! });
    setShowConfirmDelete(true);
  };

  const handleConfirmDelete = async () => {
    if (inviteToDelete) {
      await deleteUserInvite(inviteToDelete);
      setShowConfirmDelete(false);
    }
  };

  const content = useMemo(() => {
    if (isLoading) {
      return <Loader padding />;
    }
    return (
      <RemoveRegisterInviteForm
        submitForm={handleSubmit}
      />
    );
  }, [isLoading]);

  // Redirect to login
  if (isSuccess) {
    return <Navigate to={routes.LOGIN} />;
  }

  // Prevent language flickering
  if (!languageLoaded) {
    return <Loader padding />;
  }

  return (
    <BackgroundCover padding>
      <CoverHeader subLevel />
      <CoverContent>
        <Heading>
          <FormattedMessage
            id="removeRegisterInviteTitle"
            defaultMessage="Remove registration"
            description="Remove register invite page title"
          />
        </Heading>
        <Description>
          <FormattedMessage
            id="removeRegisterInviteDescription"
            defaultMessage="By removing yourself from the registration, you will lose access to the platform, and your company will need to re-invite you. Please contact your administrator to be removed from their lists and inform them that you do not wish to participate in using Wellr."
            description="Remove register invite page description"
          />
        </Description>
        {content}
      </CoverContent>
      <ConfirmActionPopup
        isOpen={showConfirmDelete}
        onConfirm={handleConfirmDelete}
        onAbort={() => setShowConfirmDelete(false)}
      >
        <>
          <Heading tag={Tag.H4}>
            <FormattedMessage
              id="confirmRemoveRegisterInviteTitle"
              defaultMessage="Are you sure?"
              description="Title text in the remove register invite confirmation popup"
            />
          </Heading>
          <FormattedMessage
            id="confirmRemoveRegisterInviteBody"
            defaultMessage="Are you sure you want to remove your invitation to Wellr? This will remove your invitation. If this was a mistake, press cancel and contact your administrator or email us at contact@wellr.se"
            description="Body text in the remove register invite confirmation popup"
          />
        </>
      </ConfirmActionPopup>
    </BackgroundCover>
  );
};

export default RemoveRegisterInvite;