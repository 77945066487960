import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ActiveStep {
  step: number;
}

const initialState: ActiveStep = {
  step: 1,
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setActiveOnboardingStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
  },
});

export const { setActiveOnboardingStep } = onboardingSlice.actions;

export const onboardingReducer = onboardingSlice.reducer;

export const selectActiveStep = (state: { onboarding: ActiveStep }) => state.onboarding.step;
