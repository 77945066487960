import { FC } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';

import { useAppSelector } from 'hooks/redux';
import { authSelector, useGetUserQuery } from 'store';

import Layout from 'components/Layout';
import MyHealthTabs from 'components/tabs/MyHealthTabs';
import DietCategoryTabs from 'components/tabs/DietCategoryTabs';
import ExerciseCategoryTabs from 'components/tabs/ExerciseCategoryTabs';

import Login from 'pages/Login';
import Signup from 'pages/Signup';
import NotFound from 'pages/NotFound';
import ResetPassword from 'pages/ResetPassword';
import ConfirmResetPassword from 'pages/ResetPassword/ConfirmResetPassword';
import RegisterInvite from 'pages/RegisterInvite';
import ConfirmRegisterInvite from 'pages/RegisterInvite/ConfirmRegisterInvite';
import Overview from 'pages/Overview';
import MyHealth from 'pages/MyHealth';
import Tests from 'pages/MyHealth/Tests';
import PulseQuiz from 'pages/MyHealth/PulseQuiz';
import Quiz from 'pages/MyHealth/Quiz';
import QuizResults from 'pages/MyHealth/QuizResults';
import TestHistory from 'pages/MyHealth/Tests/TestHistory';
import Measures from 'pages/MyHealth/Measures';
import MeasureProgram from 'pages/MyHealth/Measures/MeasureProgram';
import MeasureProgramStep from 'pages/MyHealth/Measures/MeasureProgram/MeasureProgramStep';
import Inspiration from 'pages/Inspiration';
import InspirationCategory from 'pages/Inspiration/InspirationCategory';
import InspirationArticle from 'pages/Inspiration/InspirationArticle';
import Exercises from 'pages/Exercise/Exercises';
import ExercisesCategory from 'pages/Exercise/ExercisesCategory';
import ExerciseGuide from 'pages/Exercise/ExerciseGuide';
import ExercisePrograms from 'pages/Exercise/ExercisePrograms';
import ExerciseProgramsCategory from 'pages/Exercise/ExerciseProgramsCategory';
import ExerciseProgramGuide from 'pages/Exercise/ExerciseProgramGuide';
import Compete from 'pages/Compete';
import Invitation from 'pages/Compete/Invitation';
import Competition from 'pages/Compete/Competition';
import CompetitionTeams from 'pages/Compete/Competition/CompetitionAllTeams';
import CompetitionOverview from 'pages/Compete/Competition/CompetitionOverview';
import Challenge from 'pages/Compete/Challenge';
import AvailableChallenges from 'pages/Compete/AvailableChallenges';
import AvailableChallengeOverview from 'pages/Compete/AvailableChallenges/AvailableChallengeOverview';
import Diet from 'pages/Diet';
import DietCategoryRecipes from 'pages/Diet/DietCategoryRecipes';
import DietCategoryPlan from 'pages/Diet/DietCategoryPlan';
import DietRecipeTabs from 'components/tabs/DietRecipeTabs';
import RecipeGuide from 'pages/Diet/Recipe/RecipeGuide';
import Recipe from 'pages/Diet/Recipe';
import Favorites from 'pages/Favorites';
import Profile from 'pages/Profile';
import Settings from 'pages/Settings';
import ProfileSettings from 'pages/Settings/ProfileSettings';
import MFASettings from 'pages/Settings/MFASettings';
import NotificationSettings from 'pages/Settings/NotificationSettings';
import Notifications from 'pages/Notifications'

import AdminHealthReport from 'pages/AdminHealthReport';
import AdminUsers from 'pages/AdminUsers';
import AdminUserDetails from 'pages/AdminUsers/AdminUserDetails';
import AdminMaintenance from 'pages/AdminMaintenance';
import AdminCompanies from 'pages/AdminCompanies/AdminCompanies';

import * as routes from './routes';
import AdminCompanyDetails from 'pages/AdminCompanies/AdminCompanyDetails';
import AdminHealthQuizResults from 'pages/AdminHealthReport/AdminHealthQuizResults';
import RegisterCompleted from 'pages/RegisterCompleted';
import AppStoreRedirect from 'pages/AppStoreRedirect/AppStoreRedirect';
import AdminOverview from 'pages/AdminOverview';
import AdminCompetitions from 'pages/AdminCompetitions';
import AdminCompetitionStart from 'pages/AdminCompetitions/AdminCompetitionStart';
import AdminAvailableCompetitions from 'pages/AdminCompetitions/AdminAvailableCompetitions';
import AdminCompetitionView from 'pages/AdminCompetitions/AdminCompetitionView';
import AdminCompletedCompetitions from 'pages/AdminCompetitions/AdminCompletedCompetitions';
import AdminDepartments from 'pages/AdminOrganisation';
import AdminDepartmentView from 'pages/AdminOrganisation/AdminDepartmentView';
import AdminDepartmentCreate from 'pages/AdminOrganisation/AdminDepartmentCreate';
import ExternalActivity from 'pages/ExternalActivity';
import AdminDepartmentUser from 'pages/AdminOrganisation/AdminDepartmentUser';
import WearableDeviceConnection from 'pages/Settings/WearableDeviceConnection';
import AdminUserCreate from 'pages/AdminOrganisation/AdminUserCreate';
import AdminOfficeCreate from 'pages/AdminOrganisation/AdminOfficeCreate';
import AdminHeadofficeCreate from 'pages/AdminOrganisation/AdminHeadofficeCreate';
import AdminCompetitionStatistics from 'pages/AdminCompetitions/AdminCompetitionView/AdminCompetitionStatistics';
import IconsPlayground from 'pages/Dev/IconsPlayground';
import Dev from 'pages/Dev/Dev';
import AdminCompanyCreate from 'pages/AdminCompanies/AdminCompanyCreate';
import Search from 'pages/Search';
import RegisterInviteSuccess from 'pages/RegisterInvite/RegisterInviteSuccess';
import AdminUmbrellaCompanies from 'pages/AdminUmbrellaCompanies';
import AdminUmbrellaCompanyDetails from 'pages/AdminUmbrellaCompanies/AdminUmbrellaCompanyDetails';
import AdminPulseTests from 'pages/AdminPulseTests';
import AdminPulseTestResults from 'pages/AdminPulseTests/AdminPulseTestResults';
import AdminPulseTestCreate from 'pages/AdminPulseTests/AdminPulseTestCreate';
import AdminUsageStats from 'pages/AdminUsageStats';
import useClaims from 'hooks/useClaims';
import MyHealthReport from 'pages/MyHealth/MyHealthReport';
import Challenges from 'pages/Compete/Challenges';
import ChallengesCategory from 'pages/Compete/ChallengesCategory';
import NotificationSchedulesSettings from 'pages/Settings/NotificationSchedulesSettings/NotificationSchedulesSettings';
import RemoveRegisterInvite from 'pages/RegisterInvite/RemoveRegisterInvite';
import Welcome from 'pages/Welcome';
import OnboardingStep3 from 'components/fragments/Onboarding/OnboardingStep3';
import OnboardingStep2 from 'components/fragments/Onboarding/OnboardingStep2';
import OnboardingStep1 from 'components/fragments/Onboarding/OnboardingStep1';
import OnboardingStep4 from 'components/fragments/Onboarding/OnboardingStep4';
import { selectUser } from 'store/userState/userState';
import MeasureProgramSubStep from 'pages/MyHealth/Measures/MeasureProgram/MeasureProgramSubStep';

// Authenticated
const Auth: FC = () => {
  const location = useLocation();
  const { isAuthenticated } = useAppSelector(authSelector);
  const user = useAppSelector(selectUser);

  // Redirect to login
  if (!isAuthenticated) {
    return <Navigate to={routes.LOGIN} state={{ from: location }} />;
  }

  if (user?.membership.hasCompletedOnboarding === false) {
    return <Navigate to={routes.WELCOME} />;
  }

  return <Outlet />;
};

// Admin
const Admin: FC = () => {
  const claims = useClaims();
  const location = useLocation();
  const { isAuthenticated } = useAppSelector(authSelector);
  const { data } = useGetUserQuery(undefined, { skip: !isAuthenticated });
  let accessRightType = claims?.accessrighttype;

  // Redirect to login
  if (data && accessRightType === undefined && !data.companyAdmin && !data.wellrAdmin) {
    return <Navigate to={routes.ROOT} state={{ from: location }} />;
  }

  return <Outlet />;
};

// Redirect root
const Root: FC = () => {
  const { isAuthenticated } = useAppSelector(authSelector);

  if (!isAuthenticated) {
    return <Navigate to={routes.LOGIN} />;
  }

  return <Navigate replace to={routes.OVERVIEW} />;
};

// Router
const Router: FC = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="signup" element={<Signup />} />

      <Route path="reset-password">
        <Route index element={<ResetPassword />} />
        <Route path="confirm" element={<ConfirmResetPassword />} />
      </Route>

      <Route path="register-invite">
        <Route index element={<RegisterInvite />} />
        <Route path="success" element={<RegisterInviteSuccess />} />
        <Route path="confirm" element={<ConfirmRegisterInvite />} />
        <Route path="remove" element={<RemoveRegisterInvite />} />
      </Route>

      <Route path="welcome" element={<Welcome />}>
        <Route index element={<Navigate to="step1" />} />
        <Route path="step1" element={<OnboardingStep1 />} />
        <Route path="step2" element={<OnboardingStep2 />} />
        <Route path="step3" element={<OnboardingStep3 />} />
        <Route path="step4" element={<OnboardingStep4 />} />
      </Route>

      <Route path="app-redirect" element={<AppStoreRedirect />} />

      <Route path="register-complete" element={<RegisterCompleted />} />

      <Route
        path="external-activity-connection"
        element={<ExternalActivity />}
      />

      <Route element={<Auth />}>
        <Route element={<Layout />}>
          <Route path="my-health">
            <Route element={<MyHealthTabs />}>
              <Route index element={<MyHealth />} />
              <Route path="tests" element={<Tests />} />
              <Route path="measures" element={<Measures />} />
              <Route path="report" element={<MyHealthReport />} />
            </Route>

            <Route path="test-history" element={<TestHistory />} />

            <Route path="measures/:slug">
              <Route index element={<MeasureProgram />} />
              <Route path="substep/:stepId" element={<MeasureProgramSubStep />} />
              <Route path="step/:stepId" element={<MeasureProgramStep />} />
              <Route path="step/:stepId/:referenceId" element={<MeasureProgramStep />} />
            </Route>
          </Route>

          <Route path="inspiration">
            <Route index element={<Inspiration />} />
            <Route path=":slug" element={<InspirationCategory />} />
            <Route path="article/:slug" element={<InspirationArticle />} />
          </Route>

          <Route path="compete">
            <Route index element={<Compete />} />
            <Route path="competitions/:contestId" element={<Competition />}>
              <Route index element={<CompetitionOverview />} />
              <Route path="all-teams" element={<CompetitionTeams />} />
              <Route path="quiz/:slug" element={<Quiz />} />
            </Route>
            <Route path="challenges/:contestId" element={<Challenge />} />
            <Route path="invitations/:contestId" element={<Invitation />} />

            <Route path="available-challenges">
              <Route index element={<Challenges />} />
              <Route path="category/:slug" element={<ChallengesCategory />} />
              <Route path=":slug" element={<AvailableChallengeOverview />} />
            </Route>

          </Route>

          <Route path="diet">
            <Route index element={<Diet />} />
            <Route path="category/:slug" element={<DietCategoryTabs />}>
              <Route index element={<DietCategoryRecipes />} />
              <Route path="plan" element={<DietCategoryPlan />} />
            </Route>
            <Route path="recipe/:slug" element={<DietRecipeTabs />}>
              <Route index element={<Recipe />} />
              <Route path="guide" element={<RecipeGuide />} />
            </Route>
          </Route>

          <Route path="exercise">
            <Route element={<ExerciseCategoryTabs />}>
              <Route index element={<ExercisePrograms />} />
              <Route path="exercises" element={<Exercises />} />
            </Route>

            <Route path="exercises">
              <Route path="category/:slug" element={<ExercisesCategory />} />
              <Route path=":slug" element={<ExerciseGuide />} />
            </Route>

            <Route path="programs">
              <Route
                path="category/:slug"
                element={<ExerciseProgramsCategory />}
              />
              <Route path=":slug" element={<ExerciseProgramGuide />} />
            </Route>
          </Route>

          <Route path="overview" element={<Overview />} />
          <Route path="favorites" element={<Favorites />} />
          <Route path="search" element={<Search />} />
          <Route path="profile" element={<Profile />} />

          <Route path="notifications" >
            <Route index element={<Notifications />} />
            <Route path=":slug" element={<Notifications />} />
          </Route>

          <Route path="settings">
            <Route index element={<Settings />} />
            <Route path="edit-profile" element={<ProfileSettings />} />
            <Route path="mfa" element={<MFASettings />} />
            <Route
              path="wearable-device-connection"
              element={<WearableDeviceConnection />}
            />
            <Route path="notification" element={<NotificationSettings />} />
            <Route path="reminders" element={<NotificationSchedulesSettings />} />
          </Route>

          <Route path="admin" element={<Admin />}>
            <Route path="overview" element={<AdminOverview />} />
            <Route path="health-report">
              <Route index element={<AdminHealthReport />} />
              <Route path=":id" element={<AdminHealthQuizResults />} />
            </Route>
            <Route path="pulse-tests">
              <Route index element={<AdminPulseTests />} />
              <Route path="create" element={<AdminPulseTestCreate />} />
              <Route path=":id" element={<AdminPulseTestResults />} />
            </Route>
            <Route path="competitions">
              <Route index element={<AdminCompetitions />} />
              <Route path="available">
                <Route index element={<AdminAvailableCompetitions />} />
                <Route path=":slug" element={<AdminCompetitionStart />} />
              </Route>
              <Route
                path="completed"
                element={<AdminCompletedCompetitions />}
              />
              <Route path=":slug">
                <Route index element={<AdminCompetitionView />} />
                <Route
                  path="statistics"
                  element={<AdminCompetitionStatistics />}
                />
              </Route>
            </Route>
            <Route path="company-users">
              <Route index element={<AdminUsers companyAdminView />} />
              <Route path=":userId" element={<AdminDepartmentUser />} />
              <Route path="create" element={<AdminUserCreate />} />
            </Route>
            <Route path="users">
              <Route index element={<AdminUsers />} />
              <Route path=":email" element={<AdminUserDetails />} />
            </Route>
            <Route path="user-statistics">
              <Route index element={<AdminUsageStats />} />
            </Route>
            <Route path="maintenance">
              <Route index element={<AdminMaintenance />} />
            </Route>
            <Route path="departments">
              <Route index element={<AdminDepartments />} />
              <Route path="create" element={<AdminDepartmentCreate />} />
              <Route
                path="create-headoffice"
                element={<AdminHeadofficeCreate />}
              />
              <Route path="create-office" element={<AdminOfficeCreate />} />
              <Route path=":departmentId" element={<AdminDepartmentView />} />
              <Route path="user/:userId" element={<AdminDepartmentUser />} />
            </Route>
            <Route path="companies">
              <Route index element={<AdminCompanies />} />
              <Route path="create" element={<AdminCompanyCreate />} />
              <Route path=":companyId" element={<AdminCompanyDetails />} />
            </Route>
            <Route path="umbrella-companies">
              <Route index element={<AdminUmbrellaCompanies />} />
              <Route
                path=":umbrellaCompanyId"
                element={<AdminUmbrellaCompanyDetails />}
              />
            </Route>
          </Route>
        </Route>

        <Route path="my-health">
          <Route
            path="pulse-quiz/:pulseQuizInstanceId"
            element={<PulseQuiz />}
          />
          <Route path="quiz/:slug">
            <Route index element={<Quiz />} />
            <Route path="results" element={<QuizResults />} />
          </Route>
        </Route>
      </Route>

      {process.env.NODE_ENV === 'development' && (
        <Route path="dev">
          <Route index element={<Dev />} />
          <Route path="icons" element={<IconsPlayground />} />
        </Route>
      )}

      <Route path="/" element={<Root />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
