import React, { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import * as routes from 'router/routes';
import CategoryCard from 'components/UI/Cards/CategoryCard';
import Loader from 'components/UI/Loader';

import {
  localizationSelector,
  setLayoutHeader,
  useGetChallengeCategoryListQuery,
} from 'store';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getImage } from 'utils/asset';

import { ButtonContainer, CardList, IconContainer } from './styles';
import { ContentWidth } from 'styles';
import Icon, { IconType } from 'components/UI/Icon';
import EmptyState from 'components/UI/EmptyState';
import Heading, { Tag } from 'components/UI/Heading';
import Button from 'components/UI/Button';
import ChallengeCardList from 'components/fragments/ChallengeCardList';
import { motion } from 'framer-motion';

const Challenges: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const getText = useLocalizedText();
  const { language } = useAppSelector(localizationSelector);

  // Hooks
  const { data, isLoading } = useGetChallengeCategoryListQuery({
    language: language?.languageCode,
  });

  // States
  const [showAll, setShowAll] = React.useState(false);

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageCompeteTitle',
          defaultMessage: 'Compete',
          description: 'Compete page title',
        }),
        icon: IconType.Back,
        link: routes.COMPETE,
      })
    );
  }, [dispatch, intl]);


  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data?.length) {
    return (
      <EmptyState iconType={IconType.Competition} padding>
        <FormattedMessage
          id="pageAvailableChallengesEmptyState"
          defaultMessage="No available challenges found"
          description="Empty state for available challenges"
        />
      </EmptyState>
    );
  }

  // No data
  if (!data || !data.length) {
    return null;
  }

  return (
    <ContentWidth isSurface>
      <Heading tag={Tag.H2}>
        <FormattedMessage
          id="tabChallenges"
          defaultMessage="Available challenges"
          description="Section title for available challenges"
        />
      </Heading>
      <CardList>
        {data.map(({ slug, title, image, numberOfChallenges }, i) => {
          return (
            <CategoryCard
              key={`challenge-program-${i}`}
              title={getText(title)}
              byline={intl.formatMessage(
                {
                  id: 'pageChallengeProgramNoByline',
                  defaultMessage:
                    '{count, plural, =0 {no programs} one {# program} other {# programs}}',
                  description: 'No programs byline for challenges',
                },
                {
                  count: numberOfChallenges,
                }
              )}
              link={`${routes.COMPETE_AVAILABLE_CHALLENGES_CATEGORY}/${slug.current}`}
              image={getImage(image)}
            />
          );
        })}
      </CardList>
      <ButtonContainer>
        <Button
          color="black"
          size="small"
          background="backgroundLight"
          onClick={() => {
            setShowAll(prev => !prev);
          }}
        >
          <ButtonContainer>
            {showAll ? (
              <FormattedMessage
                id="competitionTopListShowLess"
                defaultMessage="Show Less"
                description="Button to show less users"
              />
            ) : (
              <FormattedMessage
                id="competitionTopListShowAll"
                defaultMessage="Show all"
                description="Show all button"
              />
            )}
            <motion.div
              initial={{ rotate: 0 }}
              animate={{ rotate: showAll ? 90 : 0 }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
            >
              <IconContainer>
                <Icon type={IconType.Arrow} />
              </IconContainer>
            </motion.div>
          </ButtonContainer>
        </Button>
      </ButtonContainer>
      {showAll && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -100 }}
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          <ChallengeCardList languageCode={language?.languageCode} />
        </motion.div>
      )}
    </ContentWidth>
  );
};

export default Challenges;
