import { FC, useCallback, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { regExp } from 'utils/string';
import { ActionContainer } from 'styles';
import { RemoveInviteUserValues } from 'models';
import * as routes from 'router/routes';

import Button from 'components/UI/Button/Button';
import TextField from 'components/UI/TextField/TextField';
import { useQuery } from 'hooks/useQuery';
import { Navigate } from 'react-router-dom';

type Props = {
  submitForm: (data: RemoveInviteUserValues) => void;
};

const RemoveRegisterInviteForm: FC<Props> = ({ submitForm }) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const [cancel, setCancel] = useState<boolean>(false);
  const [registrationCode] = useQuery('registrationCode');

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) =>
      submitForm({ secretCode: registrationCode as string }),
    [registrationCode, submitForm]
  );

  if (cancel) {
    return <Navigate to={routes.LOGIN} />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label={intl.formatMessage({
          id: 'inputEmailLabel',
          defaultMessage: 'Email',
          description: 'Label for email input',
        })}
        placeholder={intl.formatMessage({
          id: 'inputEmailPlaceholder',
          defaultMessage: 'Enter email',
          description: 'Placeholder for email input',
        })}
        error={errors.email}
        register={register('email', {
          required: intl.formatMessage({
            id: 'inputErrorEmailRequired',
            defaultMessage: 'Email is required',
            description: 'Input error for email required',
          }),
          pattern: {
            value: regExp.email,
            message: intl.formatMessage({
              id: 'inputErrorEmailInvalid',
              defaultMessage: 'Please enter a valid email',
              description: 'Input error for email invalid',
            }),
          },
        })}
      />
      <ActionContainer>
        <Button type="submit" color="black" background="yellow">
          <FormattedMessage
            id="confirmButton"
            defaultMessage="Confirm"
            description="Confirm button text"
          />
        </Button>
        <Button
          border='white'
          onClick={() => setCancel(true)}
          background="transparent"
          color="white"
        >
          <FormattedMessage
            id="cancelButton"
            defaultMessage="Cancel"
            description="Cancel button text"
          />
        </Button>
      </ActionContainer>
    </form>
  );
};

export default RemoveRegisterInviteForm;