import { OverlayView } from '@react-google-maps/api';
import { ContestGoal } from 'models';
import { FC, useMemo } from 'react';

import subGoalMarker from 'assets/vectors/achievement-badge.svg';
import subPinkGoalMarker from 'assets/vectors/pink-achievement-badge.svg';
import subRedGoalMarker from 'assets/vectors/map-red-subgoal-marker.svg';

type Props = {
  goals: ContestGoal[];
  pink?: boolean;
  red?: boolean;
};

const MapSubGoalMarkers: FC<Props> = ({ goals, pink, red }) => {
  const subGoalMarkers = useMemo(() => {
    const markers: Array<{ lat: number; lng: number }> = [];
    goals.forEach((goal) => {
      goal.subGoals.forEach((subGoal) => {
        if (subGoal.markOnMap) {
          markers.push({
            lat: subGoal.lat,
            lng: subGoal.lng,
          });
        }
      });
    });
    return markers;
  }, [goals]);

  if (!subGoalMarkers.length) {
    return null;
  }

  return (
    <>
      {subGoalMarkers.map((subGoal) => (
        <OverlayView
          key={subGoal.lat + subGoal.lng}
          position={{
            lat: subGoal.lat,
            lng: subGoal.lng,
          }}
          mapPaneName={OverlayView.OVERLAY_LAYER}
          getPixelPositionOffset={(width, height) => ({ x: -(width / 2), y: -(height / 2) })}
        >
          <img
            src={pink ? subPinkGoalMarker : red ? subRedGoalMarker : subGoalMarker}
            alt="subgoal marker"
            style={{ width: '32px', height: '32px' }}
          />
        </OverlayView>
      ))}
    </>
  );
};

export default MapSubGoalMarkers;
