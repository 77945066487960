import { Measure, MeasureItem, MeasureQuery, MeasureStep } from 'models';
import { baseApi } from '../baseApi';
import { endpoints, MarkMeasureStepType, MarkMeasureSubStepType } from './endpointDefinitions';

const measureApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getActiveMeasures: builder.query<MeasureItem[], MeasureQuery>({
      query: ({ language }) => `/measure/me/active?language=${language}`,
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: 'Measure', id } as const)),
            { type: 'Measure', id: 'ACTIVE_LIST' },
          ]
          : [{ type: 'Measure', id: 'ACTIVE_LIST' }],
    }),
    getProposedMeasures: builder.query<MeasureItem[], MeasureQuery>({
      query: ({ language }) => `/measure/me/proposed?language=${language}`,
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: 'Measure', id } as const)),
            { type: 'Measure', id: 'PROPOSED_LIST' },
          ]
          : [{ type: 'Measure', id: 'PROPOSED_LIST' }],
    }),
    getCompletedMeasures: builder.query<MeasureItem[], MeasureQuery>({
      query: ({ language }) => `/measure/me/completed?language=${language}`,
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: 'Measure', id } as const)),
            { type: 'Measure', id: 'COMPLETED_LIST' },
          ]
          : [{ type: 'Measure', id: 'COMPLETED_LIST' }],
    }),
    getMeasure: builder.query<Measure, MeasureQuery>({
      query: ({ language, slug }) => `/measure/me/${slug}?language=${language}`,
      providesTags: (_res, _err, query) => [
        { type: 'Measure', id: query.slug },
      ],
    }),
    createMeasure: builder.mutation<Measure, MeasureQuery>({
      query: ({ slug }) => ({
        url: `/measure/me/${slug}`,
        method: 'POST',
      }),
      invalidatesTags: [
        { type: 'Measure', id: 'ACTIVE_LIST' },
        { type: 'Measure', id: 'PROPOSED_LIST' },
      ],
    }),
    deleteActiveMeasure: builder.mutation<void, MeasureQuery>({
      query: ({ slug }) => ({
        url: `/measure/me/${slug}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'Measure', id: 'ACTIVE_LIST' },
        { type: 'Notifications' },
      ],
    }),
    deleteProposedMeasure: builder.mutation<void, MeasureQuery>({
      query: ({ slug }) => ({
        url: `/measure/me/proposed/${slug}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'Measure', id: 'PROPOSED_LIST' },
        { type: 'Notifications' },
      ],
    }),
    getMeasureStep: builder.query<MeasureStep, MeasureQuery>({
      query: ({ language, slug, stepId }) =>
        `/measure/me/step/${slug}/${stepId}?language=${language}`,
      providesTags: (_res, _err, query) => [
        { type: 'MeasureStep', id: query.stepId },
      ],
    }),
    markMeasureStep: builder.mutation<
      MarkMeasureStepType['result'],
      MarkMeasureStepType['queryArgs']
    >({
      query: ({ language, measureSlug, measureStepId, completed }) => ({
        url: endpoints.markMeasureStep.path(measureSlug, measureStepId),
        params: { language, completed },
        method: endpoints.markMeasureStep.method,
      }),
      invalidatesTags: (_res, _err, query) => [
        { type: 'Measure', id: 'ACTIVE_LIST' },
        { type: 'Measure', id: 'COMPLETED_LIST' },
        { type: 'Measure', id: query.measureSlug },
        { type: 'MeasureStep', id: query.measureStepId },
        { type: 'Notifications' },
      ],
    }),
    markMeasureSubStep: builder.mutation<
      MarkMeasureSubStepType['result'],
      MarkMeasureSubStepType['queryArgs']
    >({
      query: ({ language, measureSlug, measureStepId, referenceId,completed }) => ({
        url: endpoints.markMeasureSubStep.path(measureSlug, measureStepId, referenceId),
        params: { language, completed },
        method: endpoints.markMeasureSubStep.method,
      }),
      invalidatesTags: (_res, _err, query) => [
        { type: 'Measure', id: 'ACTIVE_LIST' },
        { type: 'Measure', id: 'COMPLETED_LIST' },
        { type: 'Measure', id: query.measureSlug },
        { type: 'MeasureStep', id: query.measureStepId },
        { type: 'Notifications' },
      ],
    }),
  }),
});

export const {
  useCreateMeasureMutation,
  useDeleteActiveMeasureMutation,
  useDeleteProposedMeasureMutation,
  useGetActiveMeasuresQuery,
  useGetProposedMeasuresQuery,
  useGetCompletedMeasuresQuery,
  useGetMeasureQuery,
  useGetMeasureStepQuery,
  useMarkMeasureStepMutation,
  useMarkMeasureSubStepMutation,
} = measureApi;
