import { charmaApiKey, charmaBaseUrl } from 'config';
import charmaCreate from "@charma/js/dist/export/create";


export const showCharmaGift = async () => {
  try {
    const res = await charmaCreate(charmaApiKey, { baseUrl: charmaBaseUrl });
    // Configuring and displaying the widget with preset billing and product but leting user select delivery.
    // Billing address is defined on API key through Charma Portal.
    // Delivery address will be provied by user in the widget
    res.show({
      // Remove to let user select product
      // articleNumber: "C-39755916",
    }, {
      priceStyle: "hidden",
      language: "sv"
    });
  } catch (error) {
    console.error('Error showing Charma gift:', error);
  }
};
