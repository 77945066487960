import { FC, useCallback, useEffect, useMemo } from 'react'
import { ButtonContainer, CategoryCircle, Container, ContentContainer, ContentSectionContainer, ContentTextContainer, CustomRichTextContainer, HeadingTitle, StyledLottie } from './styles'
import Icon, { IconType } from 'components/UI/Icon'
import { localizationSelector, useContentSearchBySlugQuery } from 'store';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { PortableText } from '@portabletext/react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { setActiveOnboardingStep } from 'store/onboardingState/onboardingState';
import lottie from 'assets/animations/onboarding-page1-animation.json';
import Button from 'components/UI/Button';


const OnboardingStep1: FC = () => {
  const { data } = useContentSearchBySlugQuery({ slug: 'onboarding-step-1' });
  const { language } = useAppSelector(localizationSelector);
  const getText = useLocalizedText();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setActiveOnboardingStep(1));
  }, [dispatch]);

  const handleNext = useCallback(() => {
    navigate(`/welcome/step2`);
  }, [navigate]);


  const contentSection = useMemo(() => {
    if (!data?.length || !data[0]?.content) {
      return null;
    }

    const ICON_LIST = [
      IconType.Health,
      IconType.Habits,
      IconType.Heart,
    ];

    const languageCode = language?.languageCode ?? 'sv';
    const content = data[0].content[languageCode];

    if (!content) {
      return null;
    }

    return content.map((item: any, idx: number) => (
      <ContentSectionContainer key={`content-${item._key || idx}`}>
        <CategoryCircle>
          <Icon type={ICON_LIST[idx]} />
        </CategoryCircle>
        <PortableText
          value={item}
        />
      </ContentSectionContainer>
    ));
  }, [data, language?.languageCode]);

  if (!data)
    return null;
  const { title } = data[0];


  return (
    <Container>
      <StyledLottie
        loop={true}
        animationData={lottie}
      />
      <ContentContainer>
        <ContentTextContainer>
          <HeadingTitle>
            {getText(title)}
          </HeadingTitle>
          <CustomRichTextContainer>
            {contentSection}
          </CustomRichTextContainer>
        </ContentTextContainer>
        <ButtonContainer>
          <Button
            size="large"
            color="white"
            background="blue"
            border="backgroundDark"
            rounded
            width='100%'
            onClick={() => { handleNext() }}
          >

            <FormattedMessage
              id={'toNextStep'}
              defaultMessage="Next step"
              description="Button to next step"
            />
          </Button>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  )
}

export default OnboardingStep1;